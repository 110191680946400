import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class CoreRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * Get a list of users
   * @param payload: Object<any>
   */
  users(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/users`;
    return this.http.get(url, queryParams);
  }

  createUser(body?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/users`;
    return this.http.post(url, body);
  }

  updateUser(id, body?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/users/${id}`;
    return this.http.put(url, body);
  }

  /**
   * Get a list of referralTransactions
   * @param payload: Object<any>
   */
  referralTransactions(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/referral-transactions`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of referral Users
   * @param payload: Object<any>
   */
  referralUsers(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/referral/users`;
    return this.http.get(url, queryParams);
  }

  updateReferralTransactions(id, body?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/referral-transactions/${id}`;
    return this.http.put(url, body);
  }

  referralCodes(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/referral/code-count`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of countries
   * @param payload: Object<any>
   */
  countries(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/countries/list/admin`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of provinces
   * @param payload: Object<any>
   */
  provinces(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/provinces`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of Authors
   * @param payload: Object<any>
   */
  authors(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/authors`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of Collections
   * @param payload: Object<any>
   */
  collections(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/collections`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of currencies
   * @param payload: Object<any>
   */
  currencies(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/currencies`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of shipment methods
   * @param payload: Object<any>
   */
  shipMethods(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/shipping-methods`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of languages
   * @param payload: Object<any>
   */
  languages(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/languages`;
    return this.http.get(url, queryParams);
  }

  /**
 * Get a list of feedback
 * @param payload: Object<any>
 */
  feedback(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/feedbacks`;
    return this.http.get(url, queryParams);
  }

  /**
   * Get a list of feedback
   * @param payload: Object<any>
   */
  feedbackItem(id?) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/feedbacks/${id}`;
    return this.http.get(url);
  }

  /**
   * Get a list of feedback
   * @param payload: Object<any>
   */
  postFeedback(payload = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/feedbacks`;
    return this.http.post(url, payload);
  }

  /**
   * Get a list of feedback
   * @param payload: Object<any>
   */
  updateFeedback(id?, payload = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/feedbacks/${id}`;
    return this.http.put(url, payload);
  }

  /**
   * CMS
   */
  cmsEntry(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/${id}`;
    return this.http.get(url, data);
  }

  cmsEntries(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms`;
    return this.http.get(url, data);
  }

  createCmsEntry(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms`;
    return this.http.post(url, data);
  }

  updateCmsEntry(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/${id}`;
    return this.http.put(url, data);
  }

  duplicateCmsEntry(id) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/${id}/duplicate`;
    return this.http.post(url, {});
  }

  deleteCmsEntry(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/${id}`;
    return this.http.delete(url, data);
  }

  uploadCmsEntry(id) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/${id}/upload`;
    return this.http.post(url, {});
  }

  cmsElements(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/elements`;
    return this.http.get(url, data);
  }

  createCmsElement(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/elements`;
    return this.http.post(url, data);
  }

  updateCmsElement(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/elements/${id}`;
    return this.http.put(url, data);
  }

  uploadCmsElement(id) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/elements/${id}/upload`;
    return this.http.post(url, {});
  }

  duplicateCmsElement(id) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/elements/${id}/duplicate`;
    return this.http.post(url, {});
  }

  deleteCmsElement(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/elements/${id}`;
    return this.http.delete(url, data);
  }

  cmsExtraData(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/extra`;
    return this.http.get(url, data);
  }

  createCmsExtra(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/extra`;
    return this.http.post(url, data);
  }

  updateCmsExtra(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/extra/${id}`;
    return this.http.put(url, data);
  }

  duplicateCmsExtra(id) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/extra/${id}/duplicate`;
    return this.http.post(url, {});
  }

  deleteCmsExtra(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/cms/extra/${id}`;
    return this.http.delete(url, data);
  }

  listProvinces(queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/provinces/admin`;
    return this.http.get(url, queryParams);
  }

  listMunicipalities(queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/municipalities/admin`;
    return this.http.get(url, queryParams);
  }

  getCountry(id, queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/countries/list/admin/${id}`;
    return this.http.get(url, queryParams);
  }

  getProvince(id, queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/provinces/admin/${id}`;
    return this.http.get(url, queryParams);
  }

  getMunicipality(id, queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/municipalities/admin/${id}`;
    return this.http.get(url, queryParams);
  }

  updateCountry(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/countries/${id}`;
    return this.http.put(url, data);
  }

  updateProvince(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/provinces/${id}`;
    return this.http.put(url, data);
  }

  updateMunicipality(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/municipalities/${id}`;
    return this.http.put(url, data);
  }

  settings(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/settings`;
    return this.http.get(url, data);
  }

  createSettings(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/settings`;
    return this.http.post(url, data);
  }

  updateSettings(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/settings/${id}`;
    return this.http.put(url, data);
  }

  partners(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/partners`;
    return this.http.get(url, data);
  }

  partnersKeywords(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/partners/keywords`;
    return this.http.get(url, data);
  }

  createPartners(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/partners`;
    return this.http.post(url, data);
  }

  affiliatePartner(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/partners/new-partner`;
    return this.http.post(url, data);
  }

  createPartnerUser(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/partners/${id}/create-user`;
    return this.http.post(url, data);
  }

  updatePartners(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/partners/${id}`;
    return this.http.put(url, data);
  }

  news(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/panel-newsletter`;
    return this.http.get(url, data);
  }

  createNews(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/panel-newsletter`;
    return this.http.post(url, data);
  }

  analytics(data) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/analytics`;
    return this.http.get(url, data);
  }
}
