import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-html-viewer-modal',
  templateUrl: './html-viewer.component.html',
  styleUrls: ['../../general-modal.component.scss', '../../../../../modules/newsletter/newsletter.component.scss', './html-viewer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HtmlViewerModal extends AbstractModal {
  getName() {
    return 'HTML';
  }

  bootstrapForm(data: any = {}) {
    console.log(data);
    this.page = this._sanitizer.bypassSecurityTrustHtml(this.properties.element.information);
  }
  page: SafeHtml;

  constructor(
    private _sanitizer: DomSanitizer,
  ) {
    super();
  }
}
