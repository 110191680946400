import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HelpersService } from '@shared/services';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class PermissionsGuard implements CanActivate {
  private permissionsUser = [];
  private currentUser;
  private hasPermissions = false;

  private permmissionRoute = {
    users: ['ListUsers', '*'],
    news: ['ListPanel-Newsletter', '*'],
    packs: ['ListPacks', '*'],
    orders: ['ListOrders', '*'],
    'orders/partners': ['ListPartner-Orders', '*'],
    ecom: ['ListProducts', '*'],
    stocks: ['ListProducts', '*'],
    reports: ['ListOrders', '*'],
    cms: ['ListProducts', '*'],
    incidents: ['ListIncidents', '*'],
    tickets: ['ListIncidents', '*'],
    notifications: ['ListOrders', '*'],
    settings: ['ListOrders', '*'],
    affiliates: ['ListPartners', '*'],
    countries: ['ListProvince', '*'],
    bulk: ['CreatePrice', '*'],
    'live-preview': ['ListProducts', '*'],
  };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private helpers: HelpersService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem('userToken');
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    if (token) {
      const decode = jwt_decode(token);
      this.permissionsUser = decode.scopes;
    }

    
    let hasPermission = false;
    const currentRoute = route.routeConfig.path;


    if (this.permmissionRoute[currentRoute]) {
      for (const checkPermission of this.permmissionRoute[currentRoute]) {
        this.permissionsUser.forEach(element => {
          if (element.toUpperCase() === checkPermission.toUpperCase()) {
            this.hasPermissions = true;
          }
        });
      }
      if (this.hasPermissions) {
        hasPermission = true;
      } else {
        hasPermission = false;
        this.helpers.errorMessage({ message: 'No tienes permiso para acceder a esta ruta.'});
        this.router.navigate(['/']);
      }
    }

    return hasPermission;
  }
}
