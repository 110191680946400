import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import {
  TextareaModalComponent,
  DatepickerModalComponent,
  CountriesModalComponent,
  CurrenciesModalComponent,
  StatusesModalComponent,
  DomainAffiliatesModalComponent,
  StatusesIncidentsModalComponent,
  StatusesAffiliatesModalComponent,
  AdSenseAffiliatesModalComponent,
  SourcesAffiliatesModalComponent,
  StatusesPacksModalComponent,
  PayMethodsModalComponent,
  BooksModalComponent,
  LangsModalComponent,
  UsersModalComponent,
  SolutionModalComponent,
  ReasonTypeModalComponent,
  ReasonModalComponent,
  VersionsModalComponent,
  PrinterModalComponent,
  CouriersModalComponent,
  TypeSelectionComponent,
  ShippingMethodComponent,
  ContentTypeComponent,
  PartnerSourceComponent,
} from './modals';
import { HasUpsellsComponent } from './modals/has_upsells/has_upsells.component';
import { ProductTypeComponent } from './modals/product_type/product_type.component';

@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss'],
  entryComponents: [
    TextareaModalComponent,
    DatepickerModalComponent,
    CountriesModalComponent,
    CurrenciesModalComponent,
    StatusesModalComponent,
    StatusesIncidentsModalComponent,
    PayMethodsModalComponent,
    BooksModalComponent,
    LangsModalComponent,
    UsersModalComponent,
    SolutionModalComponent,
    ReasonTypeModalComponent,
    ReasonModalComponent,
    VersionsModalComponent,
    PrinterModalComponent,
    TypeSelectionComponent,
    CouriersModalComponent,
    ShippingMethodComponent,
    HasUpsellsComponent,
    PartnerSourceComponent,
    ProductTypeComponent
  ]
})
export class AdvanceSearchComponent implements OnInit {

  @Input() searchData: any = {};
  @Output() filters: BehaviorSubject<any[]> = new BehaviorSubject([]);
  activeFilters = [];

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    if (this.searchData)
      this.searchData.activeFilters = [];
  }

  ngOnDestroy() {
    this.searchData = {};
    this.searchData.activeFilters = [];
  }
  /**
   * Handle click actions depending on action type
   */
  handleBtnClick({ key, actionType, properties }) {
    switch (actionType) {
      case 'submit':
        // TODO submit action
        break;
      case 'modal':
        this.openModal(key, properties);
        break;
      case 'toggle':
        this.triggerToggle(key, properties);
        break;
      default:
        // TODO default clic action
        break;
    }
  }

  /**
   * Open modal for given model with given properties
   * @param model model name as unique identifier
   * @param properties modal properties
   */
  private openModal(key, properties) {
    let modalComponent: any = {};
    const type = properties.type || 'textarea';
    const modalOptions = {};

    switch (type) {
      case 'textarea':
        modalComponent = TextareaModalComponent;
        break;
      case 'datepicker':
        modalComponent = DatepickerModalComponent;
        break;
      case 'country':
        modalComponent = CountriesModalComponent;
        break;
      case 'currency':
        modalComponent = CurrenciesModalComponent;
        break;
      case 'status':
        modalComponent = StatusesModalComponent;
        break;
      case 'status_incident':
        modalComponent = StatusesIncidentsModalComponent;
        break;
      case 'status_affiliates':
        modalComponent = StatusesAffiliatesModalComponent;
        break;
      case 'status_domain':
        modalComponent = DomainAffiliatesModalComponent;
        break;
      case 'adsense_affiliates':
        modalComponent = AdSenseAffiliatesModalComponent;
        break;
      case 'source_affiliates':
        modalComponent = SourcesAffiliatesModalComponent;
        break;
      case 'pack_status':
        modalComponent = StatusesPacksModalComponent;
        break;
      case 'reason':
        modalOptions['size'] = 'lg';
        modalComponent = ReasonModalComponent;
        break;
      case 'versions':
        modalOptions['size'] = 'md';
        modalComponent = VersionsModalComponent;
        break;
      case 'reason_type':
        modalOptions['size'] = 'lg';
        modalComponent = ReasonTypeModalComponent;
        break;
      case 'user':
        modalComponent = UsersModalComponent;
        break;
      case 'solution':
        modalOptions['size'] = 'lg';
        modalComponent = SolutionModalComponent;
        break;
      case 'payment_method':
        modalOptions['size'] = 'lg';
        modalComponent = PayMethodsModalComponent;
        break;
      case 'printer':
        modalOptions['size'] = 'lg';
        modalComponent = PrinterModalComponent;
        break;
      case 'delivery_courier':
        modalOptions['size'] = 'lg';
        modalComponent = CouriersModalComponent;
        break;
      case 'booktype_selection':
        modalComponent = TypeSelectionComponent;
        break;
      case 'langs':
        modalComponent = LangsModalComponent;
        break;
      case 'books':
        modalOptions['size'] = 'xl';
        modalOptions['scrollable'] = true;
        modalComponent = BooksModalComponent;
        break;
      case 'shipping_method':
        modalComponent = ShippingMethodComponent;
        break;
      case 'has_upsells':
        modalComponent = HasUpsellsComponent;
        break;
      case 'partner_source':
        modalComponent = PartnerSourceComponent;
        break;
      case 'content_type':
        modalComponent = ContentTypeComponent;
        break;
      case 'product_type':
        modalComponent = ProductTypeComponent;
        break;
      default:
        break;
    }

    const filterIndex = this.searchData.filters.findIndex(el => el.key === key);
    const index = this.searchData.activeFilters.findIndex(el => el.key === key);
    const val = this.searchData.activeFilters[index] ? this.searchData.activeFilters[index]['value'] : {};

    const modalRef: any = this.modalService.open(modalComponent, {
      // size: 'lg',
      ...modalOptions,
      centered: true,
      backdrop: 'static'
    });

    modalRef.componentInstance.properties = { ...properties, ...val };

    modalRef.result
      .then(res => {
        const { value } = res;

        if (!value && index === -1)
          return;

        if (index === -1) {
          this.searchData.activeFilters.push({
            key,
            model: this.searchData.model,
            value: res
          });
          this.searchData.filters[filterIndex].active = true;
        } else {
          if (!value) {
            this.searchData.activeFilters.splice(index, 1);
            this.searchData.filters[filterIndex].active = false;
          } else {
            this.searchData.activeFilters[index] = {
              key,
              model: this.searchData.model,
              value: res
            };
            this.searchData.filters[filterIndex].active = true;
          }
        }

        this.filters.next(this.searchData.activeFilters);
        // this.filters.next(this.activeFilters);
      })
      .catch(e => { });
  }

  /**
   * Activate or deactivate toggle buttons
   * @param model model name as unique identifier
   * @param properties modal properties
   */
  triggerToggle(model, properties) {
    const index = this.searchData.activeFilters.findIndex(el => el.key === model);
    const filterIndex = this.searchData.filters.findIndex(el => el.key === model);

    if (index === -1) {
      this.searchData.activeFilters.push({
        model,
        value: true
      });

      if (filterIndex !== -1)
        this.searchData.filters[filterIndex]['classOptions'] = [
          ...(this.searchData.filters[filterIndex]['classOptions'] || []),
          ...properties['activeClass']
        ];
    } else {
      this.searchData.activeFilters.splice(index, 1);
      if (filterIndex !== -1) {
        this.searchData.filters[filterIndex]['classOptions'] = this.searchData.filters[filterIndex]['classOptions'].map(_class => {
          if (properties['activeClass'].indexOf(_class) === -1)
            return _class;

          return null;
        })
          .filter(e => e);
        // this.searchData[filterIndex]['classOptions'] = [...this.searchData[filterIndex]['classOptions'], ...properties['activeClass']];
      }
    }

    this.filters.next(this.searchData.activeFilters);
  }

}
