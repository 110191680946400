import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'stock-resource-modal',
  templateUrl: './stock-resource.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class StockResourceModal extends AbstractModal {

  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      name: [ { value: data.name || '', disabled: false }, Validators.required],
      units_per_box: [ { value: data.units_per_box || 1, disabled: false }, Validators.required],
      description: [ { value: data.description || '', disabled: false  }, Validators.required],
      active: [ { value: data.active, disabled: false  }, Validators.required],
    });
  }

  getName() {
    return 'Recurso'
  }
}
