// Declare all modals that will going to be used as shared filters

import { CMSElementModal } from "./cms-element/cms-element.component";
import { CMSEntryModal } from "./cms-entry/cms-entry.component";
import { CMSExtraModal } from "./cms-extra/cms-extra.component";


// this declaration could be found in the shared app module
export function CMSModals() {
  return [
    CMSEntryModal,
    CMSExtraModal,
    CMSElementModal
  ];
}
