import { Component, OnInit, Input } from '@angular/core';
import { ApiRequestsService } from '@api/api.gateway';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { HelpersService } from '@shared/services';
import { pick } from 'lodash';

@Component({
  selector: 'app-products-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class ProductPricesComponent implements OnInit {

  headers: any[] = [
    {
      title: 'Moneda'
    },
    {
      title: 'Precio'
    },
    // {
    //   title: 'Impuesto'
    // },
    {
      title: 'Estatus'
    },
    // {
    //   title: 'Total'
    // },
    {
      title: ''
    }
  ];

  @Input() data: any[] = [];
  @Input() type: string;
  private prices: any[] = [];
  pricesTable: FormGroup;
  countries: any[] = [];
  languages: any[] = [];
  currencies: any[] = [];

  statuses: any[] = [
    {
      label: 'active',
      value: 'active'
    },
    {
      label: 'inactive',
      value: 'inactive'
    }
  ];

  paging = {
    limit: 10,
    page: 1,
    total_items: 0,
    total_pages: 1,
  };

  private subscriptions: any = {};
  private tempEdition: any = {};
  private numberPattern = new RegExp('^[1-9]\d{0,2}(\.\d{3})*(,\d+)?$');
  private currentFilters: any = {};

  get getFormControls() {
    const control = this.pricesTable.get('prices') as FormArray;
    return control;
  }

  constructor(
    private api: ApiRequestsService,
    private fb: FormBuilder,
    private helpers: HelpersService
  ) { }

  ngOnInit() {
    this.bootStrapForm();
    this.getCountriesList();
    this.getCurrenciesList();
    this.getPrices();
  }

  ngOnDestroy() {
    this.cancel();
  }

  /**
   * Edit row information
   * @param group form reference
   * @param index row index
   */
  editRow(group: FormGroup, index) {
    // mark row as editable
    group.get('isEditable').setValue(true);
    // Set a temporal reference for the row value
    this.tempEdition[index] = group.value;
    // Listen row changes
    this.subscriptions[index + '_rate'] = group.get('tax_rate').valueChanges.subscribe(e => this.handleFormUpdate(e, group));
    this.subscriptions[index + '_price'] = group.get('price').valueChanges.subscribe(e => this.handleFormUpdate(e, group));
  }

  /**
   * Cancel row edition
   * @param group form reference
   * @param index row index
   */
  cancelRow(group: FormGroup, index) {
    // get temporal row keys
    const keys = Object.keys(this.tempEdition[index]);
    const child = this.tempEdition[index];
    // loop thought all keys to set previous values
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      group.get(key).setValue(child[key]);
    }

    // mark from as no editable
    group.get('isEditable').setValue(false);
  }

  getAvailableCurrencies(row, index) {
    // this.subscriptions[index + '_currency'] =
    const currencies = this.currencies;
    const available = [];

    let currentCurrency = null;
    if (this.prices[index]) {
      currentCurrency = this.prices[index]['currency'];
    }

    for (let index = 0; index < this.currencies.length; index++) {
      const element = this.currencies[index];
      const exists = this.prices.find(price => price['currency'] === element['code']);
      const current = element['code'] === currentCurrency;

      if (!exists || current) {
        available.push(element);
      }
    }
    return available;

  }

  /**
   * Save row action
   * @param group form reference
   * @param index row index
   */
  async doneRow(group: FormGroup, index) {
    const edition = !group.get('newRow').value;
    // TODO check if valid
    // this.helpers.setFormError(group)

    const { isEditable, newRow, ...values } = group.value;

    const currencyData = this.currencies.find(e => e['code'] === values['currency']) || {};
    let _data = {
      ...values,
      ...{
        currency_symbol: currencyData['symbol'],
        currency: currencyData['code'],
        currency_id: currencyData['id'],
        // model_id: this.data['model_id'],
        // language: this.data['language'],
        // model_type: this.data['model']
      }
    };

    if (!edition) {
      _data = {
        ..._data,
        model_id: this.data['id'],
        language: this.data['language'],
        model_type: this.type
      };

      delete _data['country'];
      delete _data['id'];
    }

    const res = await this.updateSaveRow(_data, edition, index);

    if (res) {
      group.get('isEditable').setValue(false);
      group.get('newRow').setValue(false);
    }

  }

  /**
   * Add form row
   */
  addRow() {
    const control = this.pricesTable.get('prices') as FormArray;
    control.push(this.initFormItem({}, true, true));
    const index = control.controls.length;
    const group = control.controls[index - 1];

    // Set a temporal reference for the row value
    this.tempEdition[index] = group.value;
    // Listen row changes
    this.subscriptions[index + '_rate'] = group.get('tax_rate').valueChanges.subscribe(e => this.handleFormUpdate(e, group));
    this.subscriptions[index + '_price'] = group.get('price').valueChanges.subscribe(e => this.handleFormUpdate(e, group));
  }

  /**
   * Delete form row by index
   * @param index row index
   */
  deleteRow(index: number) {
    const control = this.pricesTable.get('prices') as FormArray;
    control.removeAt(index);
    this.childUnsubscribe(index);
  }

  /**
   * Cancel all edition
   */
  cancel() {
    if (!this.pricesTable)
      return;

    const newRows = [];
    const control = this.pricesTable.get('prices') as FormArray;
    control.controls.forEach((element, i) => {
      element.get('isEditable').setValue(false);

      if (element.get('newRow').value)
        newRows.push(i);

      this.childUnsubscribe(i);
    });

    newRows.forEach(i => this.deleteRow(i));
  }

  onPageChange(data) {
    this.paging = { ...this.paging, ...data };
    const options = {
      ...data,
      ...this.currentFilters
    };
    this.getPrices(options);
  }

  /**
   * Initialize form
   */
  private bootStrapForm() {
    this.pricesTable = this.fb.group({
      prices: this.fb.array([])
    });

    this.prices.forEach((item) => {
      const control = this.pricesTable.get('prices') as FormArray;
      control.push(this.initFormItem(item));
    });
  }

  /**
   * Set row item
   * @param data row info
   * @param isEditable mark as editable or not
   * @param newRow flag to indicate if is a new or not row
   */
  private initFormItem(data: any = {}, isEditable = false, newRow = false) {
    return this.fb.group({
      id: [data.id || ''],
      currency: [
        { value: data.currency || '', disabled: false },
        [Validators.required, Validators.minLength(2)]
      ],
      currency_symbol: [
        { value: data.currency_symbol || '', disabled: false },
        [Validators.required, Validators.minLength(2)]
      ],
      language: [
        { value: data.language || '', disabled: false },
        [Validators.required, Validators.minLength(2)]
      ],
      price: [
        { value: data.price || 0, disabled: false },
        [Validators.required, Validators.minLength(1)]
      ],
      tax_due: [
        { value: data.tax_due || 0, disabled: true },
        // [Validators.pattern(this.numberPattern)]
      ],
      tax_rate: [
        { value: data.tax_rate || 0, disabled: false },
        // [Validators.pattern(this.numberPattern)]
      ],
      status: [
        { value: data.status || 'inactive', disabled: false },
        // [Validators.pattern(this.numberPattern)]
      ],
      country: [
        { value: data.country || '', disabled: false },
        // [Validators.pattern(this.numberPattern)]
      ],
      isEditable: [isEditable],
      newRow: [newRow]
    });
  }

  /**
   * Get list of prices
   */
  private async getPrices(options: any = {}) {
    try {
      const payload = {
        model_id: this.data['id'],
        model_type: this.type,
        limit: 500,
        ...options
      };

      const prices = await this.api.products.prices(payload).toPromise();

      this.prices = prices['data'] || [];
      this.paging = prices['paging'];

      this.bootStrapForm();
    } catch (error) {

    }
  }

  /**
   * Get a list of countries
   */
  private getCountriesList() {
    const queryParams = {
      // fields: 'code,name,alpha2_code,label',
      sort: 'name',
      limit: 200
    };
    this.api.core.countries(queryParams).toPromise()
      .then(res => {
        this.countries = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  /**
   * Get a list of currencies
   */
  private getCurrenciesList() {
    const queryParams = {
      // fields: 'code,name,symbol,key,id',
      sort: 'name',
      limit: 200
    };
    this.api.core.currencies(queryParams).toPromise()
      .then(res => {
        this.currencies = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  /**
   * Listen when form changes and update price
   * @param val
   * @param group form reference
   */
  private handleFormUpdate(val, group) {
    const price = group.get('price').value || 0;
    const taxRate = group.get('tax_rate').value || 0;
    group.get('tax_due').setValue(this.calculatePriceTotal(Number(price), Number(taxRate)));
  }

  /**
   * Calculate total price
   * @param price
   * @param taxRate
   */
  private calculatePriceTotal(price, taxRate) {
    let taxDue: any = ((price / 100) * taxRate) + price;
    taxDue = Number(taxDue.toFixed(2));
    return taxDue || 0;
  }

  /**
   * Unsubscribe from all checkers
   * @param index
   */
  private childUnsubscribe(index) {
    if (this.subscriptions[index + '_rate'])
      this.subscriptions[index + '_rate'].unsubscribe();
    if (this.subscriptions[index + '_price'])
      this.subscriptions[index + '_price'].unsubscribe();

    if (this.tempEdition[index])
      delete this.tempEdition[index];
  }

  private async updateSaveRow(values, edition, index) {
    const update = {};
    const priceId = values['id'] || null;
    const create = {};

    if (priceId && edition) {
      delete values['id'];
    }

    // console.log('>>>>>>>>>>>>>>.', values, edition)
    // // TODO call endpoints
    const action = edition ? this.api.prices.updateProductPrice(priceId, values) : this.api.prices.createProductPrice(values);

    try {
      const res = await action.toPromise();
      const message = edition ? 'Precio editado correctamente' : 'Precio creado correctamente';
      this.helpers.successMessage(message, 'Precio');
      this.childUnsubscribe(index);
      await this.getPrices();
      return true;
    } catch (error) {
      this.helpers.errorMessage(error);
      this.childUnsubscribe(index);
      return false;
    }
  }
}
