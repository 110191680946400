import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@shared/services';
import { isEmpty } from 'lodash';
import { UserService } from '@shared/services/user.service';

@Injectable()
export class AnonymousGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.token && !isEmpty(this.auth.user))
      if (this.auth.hasPermission('ListOrders')) {
        this.router.navigate(['orders']);
      }
    return true;
  }

  /**
   * Trigger not authorized flow
   */
  notAuthorized() {
    this.router.navigate(['/auth/login']);
    return false;
  }
}
