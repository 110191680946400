import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { HelpersService, ImageService } from '@shared/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestsService } from '@api/api.gateway';
import { PRODUCT_TYPES, VARIANT_TYPES } from '@shared/global.types';

@Component({
  selector: 'create-product-variant-modal',
  templateUrl: './create-product-variant-modal.component.html',
  styleUrls: ['./create-product-variant-modal.component.scss']
})
export class CreateProductVariantModal extends AbstractComponent {
  productTypes = PRODUCT_TYPES;

  displayImage = { url: ''};
  properties: any = {
    data: {},
    languages: [],
    languagesDyslexia: [],
    type: 'book',
  };
  productForm: FormGroup;
  languages: any[] = [];
  variants = [
    { name: 'Original', code: VARIANT_TYPES.ORIGINAL },
    { name: 'Dislexia', code: VARIANT_TYPES.DYSLEXIA }
  ];
  files: FileList;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private api: ApiRequestsService,
    private helpers: HelpersService,
    private imageService: ImageService
  ) {
    super();
  }

  ngOnInit() {
    this.bootstrapForm(this.properties.data || {});
    this.getLanguages();
  }

  ngAfterViewInit() {
    this.subscriptions.push( this.productForm.controls['title'].valueChanges.subscribe(value => {
      const slug = this.helpers.getSlug(value);
      this.productForm.patchValue({
        slug,
        key: slug,
      });
      this.productForm.get('slug').markAsDirty();
      this.productForm.get('key').markAsDirty();
    }) );
    this.subscriptions.push( this.productForm.controls['variant'].valueChanges.subscribe(value => {
      this.getLanguages(value);
    }) );
  }

  /**
   * Handle form submission
   * @param formLogin form data
   */
  async onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      this.helpers.displayFormErrors(this.productForm);
      return;
    }
    const payload = value;
    this.helpers.setformAsValid(this.productForm);
    // Picture uploaded after submit as language is needed
    payload.cover = await this.imageService.uploadPicture(this.properties.data.slug, this.files, payload.slug, payload.language, payload.variant);
    if (this.properties.onSubmit) {
      try {
        await this.properties.onSubmit({ payload });
      } catch (error) {
        this.helpers.errorMessage(error);
        return;
      }
    }
    this.activeModal.close({ payload });
  }

  close() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  /**
 * listen when file input change
 * @param event input file event
 */
   async loadPicture(event: any) {
    this.files = event.target.files;
    this.displayImage = await this.imageService.getMediaAsBase64(this.files[0]);
  }


  private bootstrapForm(data: any = {}) {
    data.cover = data.cover || {};

    const cover =  this.formBuilder.group({
      original: [
        { value: data.cover.original, disabled: false },
      ],
      thumbnail: [
        { value: data.cover.thumbnail, disabled: false },
      ],
      medium: [
        { value: data.cover.medium, disabled: false },
      ]
    });

    const metas =  this.formBuilder.group({
      keywords: [
        { value: '', disabled: false },
      ],
      title: [
        { value: '', disabled: false },
      ],
      description: [
        { value: '', disabled: false },
      ]
    });

    this.productForm = this.formBuilder.group({
      cover_alt: [
        { value: data.cover_alt || '', disabled: false },
      ],
      title: [
        { value: data.title || '', disabled: false },
        Validators.required,
      ],
      description: [
        { value: data.description || '', disabled: false },
        Validators.required,
      ],
      variant: [
        { value: data.variant || VARIANT_TYPES.ORIGINAL, disabled: false },
        Validators.required,
      ],
      key: [
        { value: data.key || '', disabled: false },
        Validators.required,
      ],
      slug: [
        { value: data.slug || '', disabled: false },
        Validators.required,
      ],
      sort_order: [
        { value: data.sort_order || 0, disabled: false },
      ],
      language: [
        { value: data.language, disabled: false },
        Validators.required,
      ],
      extra_info: [
        { value: data.extra_info, disabled: false },
      ],
      product_url: [
        { value: data.product_url, disabled: false },
      ],
      status: [
        { value: data.status || 'inactive', disabled: false },
      ],
      cover,
      metas,
    });
  }

  private getLanguages(variant = VARIANT_TYPES.ORIGINAL ) {
    const query = {
      limit: 100,
    };

    let languages;

    switch (variant) {
      case VARIANT_TYPES.DYSLEXIA:
        languages = this.properties.languagesDyslexia;
        break;
      default:
        languages = this.properties.languages;
    }

    if (languages.length > 0) {
      query['notCode'] = languages.toString();
    }

    this.api.core.languages(query).toPromise()
      .then(res => {
        this.languages = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

}
