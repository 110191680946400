import { AuthGuard } from './auth.guard';
import { AnonymousGuard } from './anonymous.guard';
import { PermissionsGuard } from './permissions.guard';

// Declare all guards that will going to be used in the app
// this declaration could be foind in the main app module
export function AppGuards() {
  return [
    AuthGuard,
    AnonymousGuard,
    PermissionsGuard
  ];
}
