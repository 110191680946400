import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class PricesRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  prices(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/prices`;
    return this.http.get(url, queryParams);
  }

  updateProductPrice(id, payload = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.prices}/v1/prices/${id}`;
    return this.http.put(url, payload);
  }

  createProductPrice(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.prices}/v1/prices`;
    return this.http.post(url, payload);
  }

}
