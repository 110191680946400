import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { FormArray, FormGroup } from '@angular/forms';
import { ImageService } from '@shared/services';
import { AppInjector } from 'src/app/app.component';
import { STATUSES } from '@shared/global.types';
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';

@Component({
  template: ``,
  selector: 'app-base-form'
})
export class BaseForm extends AbstractComponent {
  image = { url: '' };
  files: FileList;

  form: FormGroup;

  translate = AppInjector.get(TranslateService);
  imageService = AppInjector.get(ImageService);

  statuses = STATUSES.map(val => {
    return {
      name: this.translate.instant(val),
      code: val,
    };
  });

  /**
 * listen when file input change
 * @param event input file event
 */
  loadPicture = async (event: any) => {
    this.files = event.target.files;
    this.image = await this.imageService.getMediaAsBase64(this.files[0]);
  }

  /**
   * Add form row
   */
  addRow(formBuilder, formName) {
    const control = this.form.get(formName) as FormArray;
    control.push(this.initFormExtra(formBuilder));
  }

  /**
   * Delete form row by index
   * @param index row index
   */
  deleteRow(formName, index: number) {
    const control = this.form.get(formName) as FormArray;
    control.removeAt(index);
  }

  initFormExtra(formBuilder, key = '', value = '') {
    return formBuilder.group({key: [key], value: [value] });
  }

  parseToArray(formBuilder, attributes) {
    const formArray = [];
    if ( attributes ) {
      Object.keys(attributes).forEach( key => {
        formArray.push(this.initFormExtra(formBuilder, key, attributes[key]));
      });
    }
    return formArray;
  }

  getFormControl(control) {
    return this.form.get(control) as FormArray;
  }

  parseArray(attributes: any[]) {
    return attributes.reduce((accumulator, { key, value }) => {
      try {
        accumulator[key] = eval(value);
      } catch(err) {
        accumulator[key] = value;
      }
      return accumulator;
    }, {});
  }
}
