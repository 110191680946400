import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { CoreRequests } from '@api/core/core.requests';

@Component({
  selector: 'advance-search-currencies-modal',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.scss']
})
export class CurrenciesModalComponent extends AbstractComponent {

  properties: any = {};

  currencies: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private core: CoreRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listCurrencies();
  }

  confirm() {
    const activeCurrencies = this.currencies.map(country => country.active ? country.code : false).filter(e => e);
    this.activeModal.close({ value: activeCurrencies.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.currencies.forEach(currency => currency.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(currency) {
    const index = this.currencies.findIndex(el => el['code'] === currency['code']);
    if (index === -1)
      return;

    this.currencies[index]['active'] = !this.currencies[index]['active'];
  }

  private async listCurrencies() {
    try {
      const queryParams = {
        fields: 'code,name,symbol,key',
        sort: 'name',
        limit: 200
      };
      const currencies = await this.core.currencies(queryParams).toPromise();
      this.currencies = currencies['data'] || [];

      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(key => {
      const index = this.currencies.findIndex(i => i.code.toLowerCase() === key.toLowerCase());
      if (index !== -1)
        this.currencies[index]['active'] = true;
    });

  }
}
