import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';
import { CoreRequests } from '@api/core/core.requests';

@Component({
  selector: 'app-category-variant-modal',
  templateUrl: './category-variant.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class CategoryVariantModal extends AbstractModal {
  languages = [];
  showImage = {};

  constructor(
    private formBuilder: FormBuilder,
    private core: CoreRequests,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.subscriptions.push( this.form.controls['name'].valueChanges.subscribe(value => {
      const slug = this.helpers.getSlug(value);
      this.form.patchValue({ slug });
      this.form.get('slug').markAsDirty();
    }));
    this.getLanguages();
  }

  toggleImage(i) {
    this.showImage[i] = !this.showImage[i];
  }

  /**
 * listen when file input change
 * @param event input file event
 */
   loadAttributePicture(attribute: FormGroup) {
    return async (event: any) => {
      const randomName = `attribute-image-${new Date().valueOf()}`;
      const url =  await this.imageService.uploadImage(event.target.files[0], this.properties.category.slug, randomName, 'categories');
      attribute.patchValue({ value: url });
    };
  }

  bootstrapForm(data: any = {}) {
    data.cover = data.cover || {};
    this.image.url = data.cover.thumbnail;
    const attributes = this.formBuilder.array(this.parseToArray(this.formBuilder, data.attributes));
    const metas = this.formBuilder.array(this.parseToArray(this.formBuilder, data.metas));

    const cover =  this.formBuilder.group({
      original: [
        { value: data.cover.original, disabled: false },
      ],
      thumbnail: [
        { value: data.cover.thumbnail, disabled: false },
      ],
      medium: [
        { value: data.cover.medium, disabled: false },
      ]
    });

    this.form = this.formBuilder.group({
      title: [{ value: data.title || '', disabled: false }, Validators.required, ],
      subtitle: [{ value: data.subtitle || '', disabled: false }, ],
      label: [{ value: data.label || '', disabled: false }, ],
      name: [{ value: data.name || '', disabled: false }, Validators.required, ],
      description: [{ value: data.description || '', disabled: false }, ],
      language: [{ value: data.language, disabled: false }, Validators.required, ],
      slug: [{ value: data.slug || '', disabled: false }, ],
      key: [{ value: data.key || '', disabled: false }, Validators.required, ],
      extra_info: [{ value: data.extra_info || '', disabled: false }, ],
      extra_info_color: [{ value: data.extra_info_color || '', disabled: false }, ],
      cover_alt: [{ value: data.cover_alt || '', disabled: false }, ],
      status: [
        { value: data.status || 'inactive', disabled: false },
      ],
      attributes,
      metas,
      cover,
    });
  }

  async onSubmitPayload(payload) {
    payload.attributes = this.parseArray(payload.attributes);
    payload.metas = this.parseArray(payload.metas);

    if (this.files) payload.cover = await this.imageService.uploadPicture(
      payload.key || this.properties.data.key, this.files
    );
    return payload;
  }

  getName() {
    return 'variante';
  }

  private getLanguages() {
    const query = {
      limit: 100,
    };
    if (this.properties.update) {
      this.properties.languages = this.properties.languages.filter((lang) => lang !== this.properties.data.language);
    }

    if ((this.properties.languages || []).length > 0) {
      query['notCode'] = this.properties.languages.toString();
    }

    this.core.languages(query).toPromise()
      .then(res => {
        this.languages = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }
}
