import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractComponent } from '../abstract/abstract.component';

@Component({
  selector: 'app-table-count',
  templateUrl: './table-count.component.html',
})
export class TableCountComponent extends AbstractComponent {
  @Input() options = [10, 20, 30, 50, 70, 100];
  @Input() selected = 50;
  @Output() selectedChange = new EventEmitter();
  @Output() itemChanged = new EventEmitter();

  change(newValue) {
    this.itemChanged.emit(newValue);
    this.selectedChange.emit(newValue);
  }
}
