import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { withLatestFrom, filter, map, tap } from 'rxjs/operators';
import { isObject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AsideService {

  panelOpen: Subject<any> = new Subject<any>();
  panelClose: Subject<any> = new Subject<any>();
  optionsUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(this.defaultOptions());

  searchOptions: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  searchDisplay: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hideReport: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchValue: Subject<any> = new Subject<any>();
  displaySearchProduct: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  sorting: any = {};

  constructor(
    private activeRoute: ActivatedRoute
  ) { }

  searchData() {
    return this.searchValue
      .pipe(
        withLatestFrom(this.searchOptions),
        filter(([actions, elements]) => actions),
        map(([actions, elements]) => {
          if (!actions.length)
          return false;

          const payload = this.createSearchPayload(actions);
          return { ...payload };
        }),
        tap((val) => {
          if ( val ) {
            this.closeSidePanel();
          }
        })
      );
  }

  /**
   * Notify to side panel that should be opened
   */
  openSidePanel() {
    this.panelOpen.next();
  }

  /**
   * Notify to side panel that should be closed
   */
  closeSidePanel() {
    this.panelClose.next();
  }

  /**
   * Set layout options like
   * theme, side bar side, etc
   * @param key item to be update
   * @param val value of item to be updated
   */
  setLayoutOptions({ key, val }) {
    const _options = this.defaultOptions();
    _options[key] = val;

    const _stringify = JSON.stringify(_options || '{}');
    localStorage.setItem('micuento-panel-theme', _stringify);
    return this.optionsUpdated.next(_options);
  }

  setSorting(data) {
    this.sorting = data;
  }

  /**
   * Get default options
   */
  private defaultOptions() {
    const _default = {
      theme: 'light', // two possible values: light, dark
      dir: 'ltr', // two possible values: ltr, rtl
      layout: 'vertical', // fixed value. shouldn't be changed.
      sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
      sidebarpos: 'fixed', // two possible values: fixed, absolute
      headerpos: 'fixed', // two possible values: fixed, absolute
      boxed: 'full', // two possible values: full, boxed
      navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
      sidebarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
      logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
    };
    const _selectedTheme = JSON.parse(localStorage.getItem('micuento-panel-theme') || '{}') || {};
    return { ..._default, ..._selectedTheme };
  }

  private createSearchPayload(items) {
    const payload = {};
    items.forEach((item) => {

      if (item.value['value'] && isObject(item.value['value'])) {
        // payload[item.key] =
        const keys = Object.keys(item.value['value']);
        keys.forEach(key => {
          payload[key] = item.value['value'][key];
        });
      } else if (item.value['value'])
        payload[item.key] = item.value['value'];
      else
        payload[item.key] = item.value || '';

    });

    return payload;
  }
}
