import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { OrdersRequests } from '@api/orders/orders.requests';

@Component({
  selector: 'app-search-domain-incidents-modal',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss']
})

export class DomainAffiliatesModalComponent extends AbstractComponent {

  properties: any = {};

  domains: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private orders: OrdersRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listDomains();
  }

  confirm() {
    const activeStatuses = this.domains.map(method => method.active ? method.value : false).filter(e => e);
    this.activeModal.close({ value: activeStatuses.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.domains.forEach(method => method.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(method) {
    const index = this.domains.findIndex(el => el['value'] === method['value']);
    if (index === -1)
      return;

    this.domains[index]['active'] = !this.domains[index]['active'];
  }

  private async listDomains() {
    try {
      this.domains = [
        {
          label: 'España',
          value: 'google.es',
          description: 'Búsquedas en google.es'
        },
        {
          label: 'Chile',
          value: 'google.cl',
          description: 'Búsquedas en google.cl'
        },
        {
          label: 'Colombia',
          value: 'google.com.co',
          description: 'Búsquedas en google.com.co'
        },
        {
          label: 'México',
          value: 'google.com.mx',
          description: 'Búsquedas en google.com.mx'
        },
        {
          label: 'Francia',
          value: 'google.fr',
          description: 'Búsquedas en google.fr'
        },
        {
          label: 'USA',
          value: 'google.com',
          description: 'Búsquedas en google.com'
        },
        {
          label: 'Italia',
          value: 'google.it',
          description: 'Búsquedas en google.it'
        },
      ];
      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(value => {
      const index = this.domains.findIndex(i => i.value.toLowerCase() === value.toLowerCase());
      if (index !== -1)
        this.domains[index]['active'] = true;
    });

  }
}
