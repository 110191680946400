import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { FormElementComponent } from './form-element.component';

@Component({
  selector: 'form-input-grouped',
  templateUrl: './form-element-grouped.component.html',
  viewProviders: [
      {
        provide: ControlContainer,
        useExisting: FormGroupDirective
      }
  ]
})
/**
 * Represents the nested form group elements
 */
export class FormElementGroupedComponent extends FormElementComponent {
  @Input() group: string;

  isInvalid() {
    const control = this.form.get(`${this.group}.${this.name}`);
    if (control) return !control.valid && control.dirty;
  }
}
