import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { JsonViewerModal } from '../modals';

@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss']
})
export class JsonViewerComponent extends AbstractComponent {
  @Input() data: any;
  @Input() label: any;

  constructor(
    private modalService: NgbModal
  ) {
    super();
  }

  viewJsonModal() {
    const modalRef = this.modalService.open(JsonViewerModal, { size: 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.properties = { data: this.data, label: this.label };
  }
}
