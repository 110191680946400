import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AsideService } from '@shared/services/aside.service';
import { AuthService } from '@shared/services';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  languages = [{
    name: 'Español',
    key: 'es'
  }, {
    name: 'English',
    key: 'en'
  }, {
    name: 'Français',
    key: 'fr'
  }, {
    name: 'Italiano',
    key: 'it'
  }];

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  user: any = {};

  constructor(
    private router: Router,
    private aside: AsideService,
    private authService: AuthService,
    private translate: TranslateService
  ) { }

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  get currentLanguage() {
    return localStorage.getItem('micuento-panel-lang') || 'es';
  }

  changeLang(lang) {
    this.translate.use(lang);
    localStorage.setItem('micuento-panel-lang', lang);
  }

  ngOnInit() {
    this.user = this.authService.user;
  }

  openCustomAside() {
    this.aside.openSidePanel();
  }

  logout() {
    localStorage.clear();
    this.authService.user = {};
    this.authService.partner = {};
    this.authService.token = '';
    this.router.navigate(['/auth/login']);
  }
}
