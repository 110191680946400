
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestsService } from '@api/api.gateway';
import { CMS_ELEMENT_TYPES, CMS_ELEMENT_ATTRS, CMS_ELEMENT_STYLES } from '@shared/global.types';
import { AbstractModal } from '../../abstract-modal.component';
@Component({
  selector: 'cms-element-modal',
  templateUrl: './cms-element.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class CMSElementModal extends AbstractModal {
  languages = [];
  desktopImage = { url: '' };
  mobileImage = { url: '' };
  desktopFile;
  mobileFile;
  currentData: any = {};
  form;

  types = [
    { name: this.translate.instant(`cms.${CMS_ELEMENT_TYPES.TEXT}`), code: CMS_ELEMENT_TYPES.TEXT },
    { name: this.translate.instant(`cms.${CMS_ELEMENT_TYPES.IMAGE}`), code: CMS_ELEMENT_TYPES.IMAGE },
    { name: this.translate.instant(`cms.${CMS_ELEMENT_TYPES.LINK}`), code: CMS_ELEMENT_TYPES.LINK },
  ];

  attributes = CMS_ELEMENT_ATTRS.map(val => {
    return {
      name: this.translate.instant(`cms.${val}`),
      code: val,
    };
  });

  styles = CMS_ELEMENT_STYLES.map(val => {
    return {
      name: this.translate.instant(`cms.${val}`),
      code: val,
    };
  });

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiRequestsService,
  ) {
    super();
  }

  get isImage() {
    return this.form.get('type').value === CMS_ELEMENT_TYPES.IMAGE;
  }

  get isLink() {
    return this.form.get('type').value === CMS_ELEMENT_TYPES.LINK;
  }

  get getContentFormControls() {
    return this.form.get('attributes') as FormArray;
  }

  get getStylesFormControls() {
    return this.form.get('styles') as FormArray;
  }

  get getSubContentStylesFormControls() {
    return this.form.get('subContentStyles') as FormArray;
  }

  get getSubContentAttrsFormControls() {
    return this.form.get('subContentAttrs') as FormArray;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getLanguages();
    this.onChanges();
  }

  bootstrapForm(data: any = {}) {
    const attributes = { ...data.attributes };
    this.currentData = data;
    if (attributes.url) {
      this.desktopImage.url = attributes.url.desktop;
      this.mobileImage.url = attributes.url.mobile;
      delete attributes.url;
    }
    const enableSubContent = data.sub_content && data.sub_content.enable !== null &&
      data.sub_content.enable !== undefined ? data.sub_content.enable : true;


    const content = this.formBuilder.array(this.parseToArray(this.formBuilder, attributes));
    for (const cont of content.controls) {
      if (!CMS_ELEMENT_ATTRS.includes(cont.get('key').value)) {
        cont['editable'] = true;
      }
    }
    const styles = this.formBuilder.array(this.parseToArray(this.formBuilder, data.styles));
    const subContentStyles = this.formBuilder.array(this.parseToArray(this.formBuilder, data.sub_content ? data.sub_content.styles : {}));
    const subContentAttrs = this.formBuilder.array(this.parseToArray(this.formBuilder, data.sub_content ? data.sub_content.attributes : {}));
    for (const cont of subContentAttrs.controls) {
      if (!CMS_ELEMENT_ATTRS.includes(cont.get('key').value)) {
        cont['editable'] = true;
      }
    }
    this.form = this.formBuilder.group({
      language: [ { value: data.language || 'default', disabled: false }, ],
      sort: [ { value: data.sort || 1, disabled: false }, Validators.required],
      label: [ { value: data.label || '', disabled: false }, ],
      start_date: [ { value: data.start_date, disabled: false }, ],
      end_date: [ { value: data.end_date, disabled: false }, ],
      type: [ { value: data.type, disabled: false }, Validators.required], // type
      status: [ { value: data.status || 'inactive', disabled: false }, Validators.required],
      attributes: content, // Extra Content in JSON
      styles,
      subContentStyles,
      subContentAttrs,
      enableSubContent: [ { value: enableSubContent, disabled: false }],
    });
  }

  getName() {
    return 'Elemento CMS';
  }

  onChanges() {
    this.form.get('type').valueChanges
    .subscribe(type => {

        if (type === CMS_ELEMENT_TYPES.IMAGE) {
          this.form.get('text').disable();
          this.form.get('link').disable();
          this.form.get('link').reset();
        } else if (type === CMS_ELEMENT_TYPES.TEXT) {
          this.form.get('link').reset();
          this.form.get('text').reset();
          this.form.get('link').disable();
        } else if (type === CMS_ELEMENT_TYPES.LINK) {
          this.form.get('link').reset();
          this.form.get('link').enable();
        }
    });
  }

  loadDesktopPicture = async (event: any) => {
    this.desktopFile = event.target.files;
    this.desktopImage = await this.imageService.getMediaAsBase64(this.desktopFile[0]);
  }

  loadMobilePicture = async (event: any) => {
    this.mobileFile = event.target.files;
    this.mobileImage = await this.imageService.getMediaAsBase64(this.mobileFile[0]);
  }

  async onSubmitPayload(payload) {
    const time = new Date().getTime();
    const language = this.form.get('language').value;

    payload.attributes = {
      ...this.currentData.attributes || {},
      ...this.parseJson(this.getContentFormControls.value)
    };

    payload.styles = this.parseJson(this.getStylesFormControls.value);

    if (this.currentData.sub_content) {

      payload.sub_content = this.currentData.sub_content;

      if (payload.enableSubContent !== null && payload.enableSubContent !== undefined)
        payload.sub_content.enable = payload.enableSubContent;
      if (this.getSubContentStylesFormControls.value)
        payload.sub_content.styles = this.parseJson(this.getSubContentStylesFormControls.value);
      if (this.getSubContentAttrsFormControls.value)
        payload.sub_content.attributes = this.parseJson(this.getSubContentAttrsFormControls.value);
    } else {
      payload.sub_content = {
        enable: payload.enableSubContent,
        styles: this.parseJson(this.getSubContentStylesFormControls.value) || {},
        attributes: this.parseJson(this.getSubContentAttrsFormControls.value) || {},
      };
    }

    delete payload.enableSubContent;
    delete payload.subContentStyles;
    delete payload.subContentAttrs;

    if (this.desktopFile || this.desktopImage.url) {
      payload.desktop_url = this.desktopFile ? await this.imageService.uploadCMSImage(this.desktopFile, this.properties.section, `desktop_${time}`, language) : this.desktopImage.url;
    }

    if (this.mobileFile || this.mobileImage.url) {
      payload.mobile_url = this.mobileFile ? await this.imageService.uploadCMSImage(this.mobileFile, this.properties.section, `mobile_${time}`, language) : this.mobileImage.url;
    }

    return {
      ...this.currentData,
      ...payload,
    };
  }

  private getLanguages() {
    const query = { limit: 100 };

    this.api.core.languages(query).toPromise()
      .then(res => {
        this.languages = res['data'] || [];
        this.languages.unshift({ code: 'default', name: 'Default'});
      })
      .catch(e => this.helpers.errorMessage(e));
  }


  private parseJson = (attributes: any[]) => {
    return attributes.reduce((accumulator, { key, value }) => {
      accumulator[key] = value;
      return accumulator;
    }, {});
  }
}
