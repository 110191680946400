import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { OrdersRequests } from '@api/orders/orders.requests';
import { HelpersService } from '@shared/services';

@Component({
  selector: 'app-create-pack-modal',
  templateUrl: './create-pack-modal.component.html',
  styleUrls: ['./create-pack-modal.component.scss']
})
export class CreatePackModal extends AbstractComponent {

  order: any = {};
  properties: any = {};
  country: String = '';
  printer: String = '';
  deliveryCourier: String = '';

  constructor(
    public activeModal: NgbActiveModal,
    private helpers: HelpersService,
    private api: OrdersRequests,
  ) {
    super();
  }

  ngOnInit() {
    const { order, printer, deliveryCourier } = this.properties;
    this.order = order;
    this.country = order.country;
    this.printer = printer;
    this.deliveryCourier = deliveryCourier;
  }

  close() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }


  async createPack() {

    const data = {
      orders: [parseInt(this.order.id)],
      delivery_courier: this.deliveryCourier,
      printing_type: 'standard',
      shipping_type: 'standard',
      printer: this.printer,
      country: this.country,
      type: 'single'
    };

    try {
      const pack: any = await this.api.createPack(data).toPromise();
      this.helpers.successMessage(`Pack creado exitosamente.`);
      this.activeModal.close(false);
    } catch (error) {
      this.activeModal.close(false);
      this.helpers.errorMessage({ message: 'Hubo un error creando el pack'});
    }
  }

}
