import { Component, ContentChild, Input, Output, TemplateRef, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { HelpersService, ImageService } from '@shared/services';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModal extends AbstractComponent {
  @ViewChild('modalRef', { static: false }) modalRef;
  @Input() name;
  @Input() properties: any = {
    data: {},
    update: false,
    type: '',
  };
  @Input() form: FormGroup = this.fb.group({});
  @Input() showFooter = true;
  @Input() showTitle = true;
  @Input() dismissable = true;
  @Output() submitEvent = new EventEmitter();
  submitting = false;

  @ContentChild('formData', { static: false }) template: TemplateRef<any>;

  constructor(
    public activeModal: NgbActiveModal,
    public imageService: ImageService,
    public helpers: HelpersService,
    public fb: FormBuilder,
  ) {
    super();
  }

  /**
   * Handle form submission
   * @param formLogin form data
   */
  async onSubmit({ value, valid }: { value: any, valid: boolean }) {
    this.submitting = true;
    setTimeout(() => this.submitting = false, 3000);
    if (!valid) {
      this.helpers.displayFormErrors(this.form);
      return;
    }
    let payload = value;
    this.helpers.setformAsValid(this.form);
    if (this.properties.update) payload = this.getDirtyValues(this.form);
    if (this.submitEvent) {
      this.submitEvent.emit({ close: this.activeModal.close, payload });
    }
  }

  close() {
    this.activeModal.close(false);
  }

  dismiss() {
    if (this.dismissable) this.activeModal.dismiss();
  }

  /**
   *
   * @param cg Base form hroup
   * @returns a dict with the modified values
   */
  private getDirtyValues(cg) {
    const dirtyValues = {};  // initialize empty object
    Object.keys(cg.controls).forEach((c) => {
       const currentControl = cg.get(c);
       if (Array.isArray(currentControl.value))  {
          dirtyValues[c] = currentControl.value;
       } else if (currentControl.dirty) {
          if (currentControl.controls) {
            dirtyValues[c] = this.getDirtyValues(currentControl);  // recursion for nested controlGroups
          } else {
            dirtyValues[c] = currentControl.value;
          }
       }

      });
    return dirtyValues;
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.modalRef.nativeElement.contains(event.target)) {
      this.dismiss();
    }
  }

  get title() {
    return `${this.properties.update ? 'Editar' : 'Crear' } ${this.name}`;
  }

}
