// Declare all modals that will going to be used as shared filters

import { CategoryVariantModal } from './category-variant/category-variant.component';
import { CategoryModal } from './category/category.component';



// this declaration could be found in the shared app module
export function CategoryModals() {
  return [
    CategoryModal,
    CategoryVariantModal
  ];
}
