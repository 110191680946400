import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';

@Component({
  selector: 'advance-search-textarea-modal',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaModalComponent extends AbstractComponent {

  @ViewChild('input', { static: true }) input: ElementRef;
  properties: any = {};

  values: any = '';

  constructor(
    public activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit() {
    this.values = this.properties.value || '';
  }

  ngAfterViewInit() {
    this.initInputObserver();
  }

  confirm() {
    let val;
    if ( this.properties.field !== 'email' ) {
      val = this.values.split(/[\n,]/g)
              .map(e => e.trim())
              .filter(e => e)
              .join(',');
    } else {
      val = this.values.split(/[\n ,]/g)
              .map(e => e.trim())
              .filter(e => e)
              .join(',');
    }
    this.activeModal.close({ value: val });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.values = '';
    this.input.nativeElement.value = '';
  }

  setNull() {
    this.values = 'NULL';
  }

  setNotNull() {
    this.values = '!NULL';
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  private initInputObserver() {
    const observer = fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(200)
      )
      .subscribe((element: any) => {
        const val = element.target.value;
        this.values = val;
      });
    this.subscriptions.push(observer);
  }

}
