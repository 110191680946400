import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { NgbTimeStringAdapter } from './timepicker.adapter';
import { ApiRequestsService } from '@api/api.gateway';

@Component({
  selector: 'form-input',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],
  providers: [{provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter}],
  viewProviders: [
      {
        provide: ControlContainer,
        useExisting: FormGroupDirective
      }
  ]
})
export class FormElementComponent extends AbstractComponent {
  config = environment;
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() accessor: string;
  @Input() values: string;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() join = undefined;
  @Input() attr = {};
  @Input() options: any[] = [];
  @Input() hasModel = false;
  @Output() hasModelEvent = new EventEmitter<any>();
  @Output() provinces = new EventEmitter<any>();
  dateTimeForm;
  msForm;
  timeRangeForm;
  otherDates = false;
  msOptions = [
    { value: 1, name: 'ms' },
    { value: 1000, name: 's' },
    { value: 60 * 1000, name: 'm' },
    { value: 60 * 60 * 1000, name: 'h' },
  ];

  constructor(
    private calendar: NgbCalendar,
    private fb: FormBuilder,
    public formatter: NgbDateParserFormatter
  ) {
    super();
    this.dateTimeForm = this.fb.group({
      date: ['', ],
      time: ['', ],
    });
    this.timeRangeForm = this.fb.group({
      start: ['', ],
      end: ['', ],
    });
    this.msForm = this.fb.group({
      number: [0, ],
      measure: ['ms', ],
    });
  }

  get control() {
    return this.form.controls[this.name];
  }

  get value() {
    return this.form.get(this.name).value;
  }

  ngOnInit() {
    if (this.type === 'datepicker' || this.type === 'datetime') {
      const date = this.form.get(this.name).value;
      try {
        if (date) {
          const jsDate = new Date(date);
          const time = {
            hour: jsDate.getUTCHours(),
            minute: jsDate.getUTCMinutes(),
            second: jsDate.getUTCSeconds(),
          };
          this.form.patchValue({ [this.name]: date });
          this.dateTimeForm.patchValue({ date: date.split('T')[0] });
          this.dateTimeForm.patchValue({ time });
        } else {
          this.dateTimeForm.patchValue({ date: undefined });
        }
      } catch (e) {
        console.log(e);
        console.log('Error parsing date');
      }
      this.dateTimeForm.get('time').valueChanges.subscribe(val => this.setTime(val));
    }
    if (this.type === 'timerange') {
      const data = this.form.get(this.name).value;
      this.timeRangeForm.patchValue({ start: data[0] });
      this.timeRangeForm.patchValue({ end: data[1] });
      this.timeRangeForm.get('start').valueChanges.subscribe(val => this.patchTimeRange(val, 0));
      this.timeRangeForm.get('end').valueChanges.subscribe(val => this.patchTimeRange(val, 1));
    }
    if (this.type === 'milliseconds') {
      const data = this.form.get(this.name).value;
      let currentMeasure = 'ms';
      let currentAmount = data;
      for (const option of this.msOptions) {
        const newAmount = data / option.value;
        if (newAmount < 1) break;
        currentMeasure = option.name;
        currentAmount = newAmount;
      }
      this.msForm.patchValue({ number: currentAmount });
      this.msForm.patchValue({ measure: currentMeasure });
      this.msForm.get('number').valueChanges.subscribe(val => this.patchMS());
      this.msForm.get('measure').valueChanges.subscribe(val => this.patchMS());
    }
    if (this.hasModel) {
      this.control.valueChanges.subscribe(data => {
        setTimeout(() => this.hasModelEvent.emit(), 100);
      });
    }
  }

  toggleOtherDates() {
    this.otherDates = !this.otherDates;
  }

  setTime(time) {
    const date = this.dateTimeForm.get('date').value;
    if (date) {
      const split = date.split('-');
      this.patchDate({ year: split[0], month: split[1], day: split[2]}, time);
    }
  }

  onDateSelection(date: NgbDate) {
    const time = this.dateTimeForm.get('time').value || { hour: 0, minute: 0, second: 0 };
    this.patchDate(date, time);
  }

  patchDate(date, time) {
    const finalDate = new Date(Date.UTC(date.year, date.month - 1, date.day, time.hour, time.minute, time.second));
    const formattedDate = this.formatter.format(date);
    this.dateTimeForm.patchValue({ date: formattedDate });
    this.form.patchValue({ [this.name]: finalDate.toISOString() });
    this.form.get(this.name).markAsDirty();
  }

  patchTimeRange(val, index) {
    const data = this.form.get(this.name).value;
    data[index] = val;
    this.form.patchValue({ [this.name]: data });
    this.form.get(this.name).markAsDirty();
  }

  patchMS() {
    const number = this.msForm.get('number').value;
    const measure = this.msForm.get('measure').value;
    const measureValue = this.msOptions.find((option) => option.name === measure);
    const newAmount = number * measureValue.value;
    this.form.patchValue({ [this.name]: newAmount });
    this.form.get(this.name).markAsDirty();
  }

  removeDate() {
    this.dateTimeForm.patchValue({ date: '', time: '' });
    this.form.patchValue({ [this.name]: null });
    this.form.get(this.name).markAsDirty();
  }

  setProvinces(provinces) {
    this.provinces.emit(provinces);
  }

  isInvalid() {
    const control = this.form.get(this.name);
    if (control) return !control.valid && control.dirty;
  }

}
