import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-extras-modal',
  templateUrl: './extras.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class ExtrasModal extends AbstractModal {
  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  bootstrapForm(data: any = {}) {
    data.cover = data.cover || {};
    const cover =  this.formBuilder.group({
      original: [
        { value: data.cover.original, disabled: false },
      ],
      thumbnail: [
        { value: data.cover.thumbnail, disabled: false },
      ],
      medium: [
        { value: data.cover.medium, disabled: false },
      ]
    });
    const attributes = this.formBuilder.array(this.parseToArray(this.formBuilder, data.attributes));
    this.form = this.formBuilder.group({
      cover,
      attributes,
      name: [ { value: data.name || '', disabled: false }, Validators.required],
      group: [ { value: data.group || '', disabled: false }, ],
      description: [ { value: data.description || '', disabled: false }, ],
      slug: [ { value: data.slug || '', disabled: false }, Validators.required],
      sort_order: [ { value: data.sort_order || 0, disabled: false }, ],
      status: [ { value: data.status || 'inactive', disabled: false }, Validators.required],
    });
    this.image.url = data.cover.thumbnail;
  }

  async onSubmitPayload(payload) {
    payload.attributes = this.parseArray(payload.attributes);
    const randomName = `extra-${new Date().valueOf()}`;
    if (this.files) {
      const url = await this.imageService.uploadImage(
        this.files[0], payload.slug || this.properties.data.slug, randomName, 'extras'
      );
      payload.cover =  {
        thumbnail: url,
        medium: url,
        original: url,
      };
    }
    return payload;
  }

  getName() {
    return 'Extra';
  }
}
