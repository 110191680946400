declare function require(moduleName: string): any;
const { version } = require('../../package.json');

export const config = () => {
  return {
    version,
    debug: false,
    langs: ['es', 'en'],
    defaults: {
      lang: 'es',
      grantType: 'password',
      clientId: 'defaultClientId'
    },
    services: {
      auth: 'oauth',
      users: 'users',
      core: 'api',
      orders: 'orders',
      products: 'api',
      payments: 'payments',
      prices: 'api',
      categories: 'categories',
      questionnaires: 'customizations',
      pdf: 'pdfs',
      files: 'files',
      promotions: 'promotions'
    }
  };
};
