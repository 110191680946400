import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectModalComponent } from '../selection/selection.component';
import { CoreRequests } from '@api/core/core.requests';

@Component({
  selector: 'advance-search-partners-modal',
  templateUrl: './partner-source.component.html',
  styleUrls: ['../selection/selection.component.scss']
})
export class PartnerSourceComponent extends SelectModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private api: CoreRequests
  ) {
    super(activeModal);
  }

  /**
   * Sets the endpoint to check for the data
   * @param params Query params from the select component
   */
  async getData(params: any) {
    const data = await this.api.partners(params).toPromise();
    for (const elem of data.data) {
      elem.slug = elem.uid;
    }
    return data;
  }

  async selectAll() {
    for (const elem of this.elements) {
      elem.active = !elem.active;
    }
  }
}
