export const AFFILIATE_STATUSES =  [
  {
    name: 'Pendiente',
    code: 'pending'
  },
  {
    name: 'Listo para enviar',
    code: 'ready'
  },
  {
    name: 'Revisión Solicitada',
    code: 'requested_review'
  },
  {
    name: 'Aceptado',
    code: 'Accepted'
  },
  {
    name: 'Rechazado',
    code: 'rejected'
  },
  {
    name: 'Bloqueado',
    code: 'blocked'
  },
  {
    name: 'Enviado',
    code: 'send'
  },
]

export const AFFILIATE_REJECTED_REASONS = [
 { name: 'Patrocinio', code: 'Patrocinio' },
 { name: 'No interesa', code: 'No interesa' },
 { name: 'Respuesta automática', code: 'Respuesta automática' },
 { name: 'Otro target', code: 'Otro target' },
];