
const downloadFile = (data, name) => {
  const blob = new Blob([data]);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name}.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const toKeyValue = (array) => array.map(name => ({ name, id: name }));

const toSearchOptions = (dict) => {
  const options = [];
  for (const key in dict) {
    if (dict[key]) options.push({ key, value: {value: dict[key] }});
  }
  return options;
};

const groupFields = form => {

  const formFieldMixed = form.reduce((val, field) => {

    // this step create model for mixed questions
    if (field.group && !val[field.group]) {
      val[field.group] = [{ type: 'group', model: field.group, questions: [] }];
    }
    if (field.question_type === 'array' && !val[field.model]) {
      val[field.model] = [{
        type: 'array',
        label: field.label,
        model: field.model,
        questions: []
      }];
    } else if (!field.group && !val[field.model] && field.question_type !== 'array') {
      val[field.model] = [{
        type: field.question_type, label: field.label, questions: []
      }];
      if (field.show && field.question_type === 'mixed') {
        val[field.model][0].show = field.show;
      }
    }

    if (field.group) {
      const position = field.position - 1;

      if (field.question_type === 'mixed') {
        if (!val[field.group][0].questions[position]) {
          val[field.group][0].questions.push({
            type: field.question_type, label: field.label, questions: [field]
          });
        } else {
          val[field.group][0].questions[position].questions.push(field);
        }
      } else {
        val[field.group][0].questions.push(field);
      }
    } else {
      val[field.model][0].questions.push(field);
    }

    return val;
  }, {});

  return formFieldMixed;
};

const formatFormData = (form) => {

  form.sort((a, b) => a.question_sort_order - b.question_sort_order ||
                      a.option_sort_order - b.option_sort_order);

  if (form.length === 0) {
    return { fields: [], models: {}, validations: [] };
  }

  const models = {};
  const fields = [];
  const validations = [];
  const validationSchema = {};

  const arrFieldDefaultValue = (field, fieldModel) => {
    if (field.option_type === 'select') {
      const selected = field.options.find((val) => val.selected ) || field.options[0];
      if (typeof selected.value === 'object') {
        models[field.group][0][fieldModel] = JSON.stringify(selected.value);
      } else {
        models[field.group][0][fieldModel] = selected.value || selected;
      }
    } else {
      models[field.group][0][fieldModel] = '';
    }
  };

  // Create model corresponding to formik validation schema
  form.forEach(( field ) => {
    if (!field.repeat && !field.group && field.question_type !== 'mixed') {
      models[field.model] = field.default_to || '';

      validationSchema[field.model] = {
        model: field.model,
        type: field.validation_type,
        validations: field.validations,
      };
    }

    if (field.question_type === 'array') {

      if (field.option_type !== 'button') {
        models[field.mapping] = [''];
        validationSchema[field.mapping] = {
          type: 'array',
          validation: {
            model: field.mapping,
            group: field.model,
            type: field.validation_type,
            validations: field.validations
          }
        };
      } else {
        models[field.mapping] = 1;
        validationSchema[field.mapping] = {
          model: field.mapping,
          group: field.model,
          type: field.validation_type,
          validations: field.validations
        };
      }
    }

    if ((field.repeat || field.group) && field.question_type !== 'array') {
      const fieldModel = field.question_type === 'mixed' ? field.mapping.replace('.', '_') : field.model;

      if (!models.hasOwnProperty(field.group)) {
        models[field.group] = [{}];
        // This will set defaul value for arrays fields according to type
        arrFieldDefaultValue(field, fieldModel);
        validationSchema[field.group] = [{}];
        validationSchema[field.group][0][fieldModel] = {
          group: field.group,
          model: fieldModel,
          type: field.validation_type,
          validations: field.validations,
        };
      } else {
        // This will set defaul value for arrays fields according to type
        arrFieldDefaultValue(field, fieldModel);
        validationSchema[field.group][0][fieldModel] = {
          group: field.group,
          model: fieldModel,
          type: field.validation_type,
          validations: field.validations,
        };
      }
    }

    if (field.question_type === 'mixed' && !field.repeat) {
      if (field.option_type === 'select') {
        const selected = field.options.find((val) => val.selected ) || field.options[0];
        if (typeof selected.value === 'object') {
          models[field.mapping] = JSON.stringify(selected.value);
        } else {
          models[field.mapping] = selected.value || selected;
        }
      } else {
        models[field.mapping] = '';
      }

      validationSchema[field.mapping] = {
        model: field.mapping,
        type: field.validation_type,
        validations: field.validations,
      };
    }

  });

  // Group mixed and repeated questions
  const formFieldMixed = groupFields(form);
  // Removed keys from array
  for (const key in formFieldMixed) {
    if (formFieldMixed[key]) fields.push(formFieldMixed[key][0]);
  }

  for (const key in validationSchema) {
    if (formFieldMixed[key]) validations.push(validationSchema[key]);
  }

  // This will be used to stored default value for each field
  // We set default value on switch fields
  const defaultValues = { ...models };
  console.log(fields);

  return { fields, models, defaultValues, validations };
};

export {
  formatFormData,
  downloadFile,
  toKeyValue,
  toSearchOptions
};
