import { Component, OnInit } from '@angular/core';

@Component({
  template: ``,
  selector: 'app-abstract'
})
export class AbstractComponent implements OnInit {

  subscriptions: any[] = []

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
