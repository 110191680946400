import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { OrdersRequests } from '@api/orders/orders.requests';

@Component({
  selector: 'app-search-statuses-incidents-modal',
  templateUrl: './statuses.component.html',
  styleUrls: ['./statuses.component.scss']
})

export class StatusesIncidentsModalComponent extends AbstractComponent {

  properties: any = {};

  payMethods: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private orders: OrdersRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listStatuses();
  }

  confirm() {
    const activeStatuses = this.payMethods.map(method => method.active ? method.value : false).filter(e => e);
    this.activeModal.close({ value: activeStatuses.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.payMethods.forEach(method => method.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(method) {
    const index = this.payMethods.findIndex(el => el['value'] === method['value']);
    if (index === -1)
      return;

    this.payMethods[index]['active'] = !this.payMethods[index]['active'];
  }

  private async listStatuses() {
    try {
      this.payMethods = [
        {
          label: 'Abierta',
          value: 'open',
          description: 'Incidencias sin solución.'
        },
        {
          label: 'Semicerrada',
          value: 'semi-closed',
          description: 'Avisado al mensajero o cliente pero no tiene solución.'
        },
        {
          label: 'Solucionada',
          value: 'closed',
          description: 'Solucionada pero no avisado al mensajero o cliente.'
        },
        {
          label: 'Cerrada',
          value: 'completed',
          description: 'Incidencias con solución y avisados al mensajero y client.'
        },
      ];
      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(value => {
      const index = this.payMethods.findIndex(i => i.value.toLowerCase() === value.toLowerCase());
      if (index !== -1)
        this.payMethods[index]['active'] = true;
    });

  }
}
