import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { CoreRequests } from '@api/core/core.requests';

@Component({
  selector: 'advance-search-versions-modal',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsModalComponent extends AbstractComponent {

  properties: any = {};

  versions: any[] = [
    {
      value: 'original',
      label: 'Normal',
      active: false,
    },
    {
      value: 'dyslexia',
      label: 'Dyslexia',
      active: false,
    }
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private core: CoreRequests
  ) {
    super();
  }

  ngOnInit() {
  }

  confirm() {
    const activeversions = this.versions.map(version => version.active ? version.value : false).filter(e => e);
    this.activeModal.close({ value: activeversions.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.versions.forEach(lang => lang.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(lang) {
    const index = this.versions.findIndex(el => el['value'] === lang['value']);
    if (index === -1)
      return;

    this.versions[index]['active'] = !this.versions[index]['active'];
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(code => {
      const index = this.versions.findIndex(i => i.code.toLowerCase() === code.toLowerCase());
      if (index !== -1)
        this.versions[index]['active'] = true;
    });

  }
}
