import { Component, Input } from '@angular/core';
import { ScraperRequests } from '@api/scraper/scraper.requests';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-batch-create-modal',
  templateUrl: './batch-create.component.html',
  styleUrls: ['../../general-modal.component.scss', './batch-create.component.scss']
})
export class BatchCreateModal extends AbstractModal {
  total = 1;
  processed = 0;
  keywords = [];
  errorResponses = [];

  getName() { return this.properties.name; }

  get progress() {
    return `${this.processed / this.total * 100}%`;
  }

  ngOnInit(): void {
    if (this.properties.data.keyword) {
      this.keywords = this.properties.data.keyword.split(',');
      this.total = this.keywords.length;
    }
    this.start();
  }

  finish() {
    location.reload();
  }

  private async start() {
    if (this.keywords.length > 0) {
      for (const keyword of this.keywords) {
        try {
          await this.properties.search({ ...this.properties.data, keyword: keyword.trim() }).toPromise();
        } catch (err) {
          this.errorResponses.unshift(err);
        }
        this.processed += 1;
      }
    } else {
      // Single send
      const res = await this.properties.search(this.properties.data);
      this.errorResponses = res.data.failed;
      this.processed += 1;
    }
  }

  bootstrapForm(data: any = {}) {}
}
