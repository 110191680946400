import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { CoreRequests } from '@api/core/core.requests';

const CANARY = 'Canarias';

@Component({
  selector: 'advance-search-countries-modal',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesModalComponent extends AbstractComponent {

  properties: any = {};

  countries: any[] = [];
  otherCountries: any[] = [];

  canary: any = {
    code: 'CANARY',
    name: CANARY,
    active: false
  };
  others: any = {
    showList: false,
    filteredCountries: [],
  };

  constructor(
    public activeModal: NgbActiveModal,
    private core: CoreRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listCountries();
  }

  confirm() {
    let activeCountries = this.countries.map(country => country.active ? country.code : false).filter(e => e);
    if (this.canary.active) {
      activeCountries.push('CANARY');
    }

    const others = this.otherCountries.filter(country => country.active).map(country => country.code);

    activeCountries = [...activeCountries, ...others];
    this.activeModal.close({ value: activeCountries.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.others.items = '';
    this.countries.forEach(country => country.active = false);
    this.canary.active = false;
    this.others.showList = false;
    this.otherCountries.forEach(country => country.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(country) {
    if (country['code'] === 'CANARY') {
      this.canary.active = !this.canary.active;
      return;
    }

    const index = this.countries.findIndex(el => el['code'] === country['code']);
    if (index === -1)
      return;

    this.countries[index]['active'] = !this.countries[index]['active'];
  }

  addEU() {
    const countries = [
      'BGR', 'HRV', 'CYP', 'DNK', 'EST', 'FIN', 'GRC', 'HUN', 'IRL',
      'LVA', 'LUX', 'MLT', 'NLD', 'POL', 'PRT', 'ROU', 'SVK', 'SVN', 'SWE'
    ];
    for (const country of countries) {
      const index = this.otherCountries.findIndex(el => el['code'] === country);
      if (index === -1) continue;
      this.otherCountries[index]['active'] = true;
    }
  }

  filterCountries() {
    this.openCountrySelect();
    this.others.filteredCountries = this.otherCountries.filter(el => {
      const search = this.others.search.toLocaleLowerCase();
      return el.name.toLowerCase().includes(search) || el.code.toLowerCase().includes(search) || this.others.search === 0;
    });  // Full list if no input
  }

  toggleCountry(country: any) {
    const index = this.otherCountries.findIndex(i => i.code === country.code);
    if (index !== -1) {
      this.otherCountries[index].active = !this.otherCountries[index].active;
    }
    this.closeCountrySelect();
  }

  private async listCountries() {
    try {
      const queryParams = {
        fields: 'code,name,alpha2_code',
        sort: 'name',
        limit: 300
      };
      const countries = await this.core.countries(queryParams).toPromise();
      this.countries = countries['data'] || [];
      const countriesPacks = ['ESP', 'MEX', 'FRA', 'ITA', 'ARG', 'DEU'];
      this.otherCountries = this.countries.filter(el => !countriesPacks.includes(el.code));
      this.others.filteredCountries = this.otherCountries;
      this.countries = this.countries.filter(el => countriesPacks.includes(el.code));
      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  openCountrySelect() {
    this.others.showList = true;
  }

  closeCountrySelect() {
    this.others.showList = false;
  }


  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    if (selections.indexOf('CANARY') !== -1)
      this.canary.active = true;

    const others = [];
    selections.forEach(code => {
      const index = this.countries.findIndex(i => i.code.toLowerCase() === code.toLowerCase());
      if (index !== -1) {
        this.countries[index]['active'] = true;
      } else {
        const othersIndex = this.otherCountries.findIndex(i => i.code.toLowerCase() === code.toLowerCase());
        if (code !== 'CANARY') {
          this.otherCountries[othersIndex].active = true;
        }
      }
    });

  }
}
