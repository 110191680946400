import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { FilesRequests } from '@api/files/files.requests';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private files: FilesRequests,
  ) { }

  /**
 * uploads the file to S3
 */
   async uploadPicture(slug: string, files: FileList, fileName?: string, variant_lang?: string, variant_type?: string) {
    if (!files) {
      return;
    }
    const variant = variant_type === 'dyslexia' ? 'dyslexia/' : '';
    const url = await this.uploadFile(files[0], slug, fileName || '', variant_lang, variant);
    return {
      thumbnail: url,
      medium: url,
      original: url,
    };
  }

  /**
 * Get images or videos files as base64 string
 * @param file
 */
  getMediaAsBase64(file: File): Promise<any> {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (e: any) => {
        resolve({ url: e.target.result, data: file });
      };

      reader.onerror = () => {
        return reject(this);
      };

      if (file.type.indexOf('image') !== -1 || file.type.indexOf('video') !== -1) {
        reader.readAsDataURL(file);
      } else
        reject(file);
    });
  }

  private async uploadFile(file: File, slug: string, fileName: string, variant_lang?: string, variant_type?: string) {

    const extension = file.name.split('.').pop();

    const payload = {
      file,
      fileName: `${fileName || slug}.${extension}`,
      container: `${slug}/${variant_type}cover-web${ variant_lang ? `/${variant_lang}` : ''}`
    };

    return this.upload(payload);
  }

  async uploadImage(file: File, slug: string, fileName: string, prefix: string, extra = '') {

    const extension = file.name.split('.').pop();

    const payload = {
      file,
      fileName: `${fileName || slug}.${extension}`,
      container: `${prefix}/${slug}${extra}`
    };

    return this.upload(payload);
  }

  async uploadCMSImage(files: File[], slug: string, fileName: string, variant_lang?: string) {
    const file = files[0];
    const extension = file.name.split('.').pop();

    const payload = {
      file,
      fileName: `${fileName}.${extension}`,
      container: `micuento-web-static/${slug}/${variant_lang}`
    };
    return this.upload(payload);
  }

  private async upload(payload: any) {
    const fileRes: any = await this.files.uploadFile(payload);
    const base = fileRes.data.fileData.bucket.replace('/', '');
    return `${base}/${fileRes.data.fileName}`;
  }
}
