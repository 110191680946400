import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-edit-base-prices-modal',
  templateUrl: './edit-base-prices.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class EditBasePricesModal extends AbstractModal {

  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({});
  }

  getName() {
    return 'Precios';
  }
}
