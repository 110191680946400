import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-product-group-modal',
  templateUrl: './product-group.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class ProductGroupModal extends AbstractModal {
  extras = [];

  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  bootstrapForm(data: any = {}) {
    data.cover = data.cover || {};
    const cover =  this.formBuilder.group({
      original: [
        { value: data.cover.original, disabled: false },
      ],
      thumbnail: [
        { value: data.cover.thumbnail, disabled: false },
      ],
      medium: [
        { value: data.cover.medium, disabled: false },
      ]
    });
    this.form = this.formBuilder.group({
      cover,
      title: [ { value: data.title || '', disabled: false }, Validators.required],
      description: [ { value: data.description || '', disabled: false }, ],
      slug: [ { value: data.slug || '', disabled: false }, Validators.required],
      sort_order: [ { value: data.sort_order, disabled: false }, Validators.required],
      status: [ { value: data.status || 'inactive', disabled: false }, Validators.required],
    });
    this.image.url = data.cover.thumbnail;
  }

  async onSubmitPayload(payload) {
    if (this.properties.type) {
      const randomName = `product-group-${new Date().valueOf()}`;
      if (this.files) {
        const url = await this.imageService.uploadImage(
          this.files[0], payload.slug || this.properties.data.slug, randomName, 'groups'
        );
        payload.cover =  {
          thumbnail: url,
          medium: url,
          original: url,
        };
      }
    } else{
      delete payload.cover;
      delete payload.description;
    }
    return payload;
  }

  getName() {
      return 'Grupo';
  }
}
