import { JoinOrderModal } from './join-order/join-order.component';
import { PackCreateModal } from './pack-create/pack-create.component';
import { RefundModal } from './refund/refund.component';


// this declaration could be found in the shared app module
export function OrderModals() {
  return [
    RefundModal,
    PackCreateModal,
    JoinOrderModal
  ];
}
