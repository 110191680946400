import { Injectable, Inject } from '@angular/core';
import { UserRequests } from '@api/user/user.requests';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private auth: AuthService,
    private api: UserRequests
  ) { }

  /**
   * Get logged user info
   */
  async me() {
    try {
      const user = await this.api.me().toPromise();
      this.auth.user = user;
      return this.auth.user;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
