import { Component, Input } from '@angular/core';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/app.component';

@Component({
  selector: 'address-lines',
  templateUrl: './address-lines.component.html'
})
export class AddressLines extends AbstractComponent {
    @Input() address: String;
    @Input() label: String;
    @Input() default_label: String;
    @Input() extra: any = {};
    @Input() secondLine: Boolean = false;
    mainCondition = false;
    mainText = '';
    translate = AppInjector.get(TranslateService);
    

  /**
   * Update the component when the inputs change
   */
    ngOnChanges() {
      if (!this.extra) {
        this.extra = {};
      }
      this.mainCondition = this.getCondition();
      this.mainText = this.getText();
    }

    private getCondition() {
      const firstLineCondition = this.extra.municipality || this.extra.street || this.extra.street_number;
      const secondLineCondition = this.extra.residence || this.extra.num_ext || this.extra.num_int;
      return this.secondLine ? secondLineCondition : firstLineCondition;
    }

    private getText() {
      const texts = this.secondLine ?
          [this.extra.residence, this.extra.num_ext, this.extra.num_int]
        : [this.extra.municipality, this.extra.street, this.extra.street_number];
      return texts.filter(Boolean).join(' ');
    }

    public getLabel(key, default_key) {
      const text = this.translate.instant(key);
      return text !== key ? text : this.translate.instant(default_key);
    }

  }
