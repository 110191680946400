import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
  selector: '[source]'
})
export class ImgS3Directive {
  @Input() source: string;

  constructor(private element: ElementRef) {
  }

  ngOnChanges(changes) {
    if (changes.source && changes.source.currentValue)
      this.onLoad();
  }

  onLoad() {

    if (this.source.indexOf('data:image') !== -1) {
      this.element.nativeElement.src = this.source;
      return;
    }

    this.element.nativeElement.src = `${environment.s3Bucket}/${this.source}`;
  }

}
