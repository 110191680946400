import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '@shared/services';
import { AsideService } from '@shared/services/aside.service';
import { AbstractComponent } from '../abstract/abstract.component';
import { isEmpty } from 'lodash';
import { ConfirmAlert } from '../confirm-alert/confirm-alert.component';

@Component({
  selector: 'app-mc-table',
  templateUrl: './mc-table.component.html',
  styleUrls: ['./mc-table.component.scss'],
})
export class McTableComponent extends AbstractComponent implements OnInit {
  items = [];
  paging = {
    page: 1,
    limit: 50,
    total_pages: 1
  };
  @Input() title: string;
  @Input() showStatus = true;
  @Input() getAll = true;
  @Input() sortable = false;
  @Input() name: string;
  @Input() pagination = true;
  @Input() headers: string[];
  @Input() fields: any[];
  @Input() modalOptions = {
    modal: undefined,
    styles: undefined,
    properties: undefined,
  };
  @Input() actions = {
    select: undefined,
    list: undefined,
    create: undefined,
    update: undefined,
    delete: undefined,
    custom: [],
  };
  @Output() list = new EventEmitter<any[]>();

  @Input() setList;
  @Input() itemsSelected: any = 50;

  private currentFilters: any = {};
  private currentSort: any = {};

  constructor(
    private asideService: AsideService,
    private helpers: HelpersService,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.asideService.searchData().subscribe(e => this.handleAsideAction(e))
    );
    this.getItems();
  }

  onPageChange(data) {
    this.paging = { ...this.paging, ...data };
    const options = {
      ...data,
    };
    this.getItems(options);
  }

  onSort(data) {
    this.currentSort = data;
    this.getItems();
  }

  async getItems(queryParams = {}) {
    try {
      queryParams = {
        ...queryParams,
        ...this.currentFilters,
        sort: '-created_at',
        ...this.currentSort,
      };
      if (this.getAll) queryParams['all'] = true;
      if (this.pagination) {
        queryParams = {
          page: this.paging.page,
          limit: this.itemsSelected || this.paging.limit,
          ...queryParams,
        };
      }

      const data = await this.actions.list(queryParams).toPromise();
      this.items = data['data'] || data || [];
      this.setItemFields(this.items);
      this.paging = data['paging'];
      this.list.emit(this.items);
      this.setList = undefined;
    } catch (error) {
      // TODO handle this errors
    }
  }

  async setItems(items) {
    this.items = items;
    this.list.emit(this.items);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.setList) this.setItemFields(this.setList);
  }

  setItemFields(items: any[]) {
    for (const item of items) {
      const fields = [];
      for (const field of this.fields) {
        let type = 'text';
        let data = field(item);
        if (Array.isArray(data)) {
          type = data[1];
          data = data[0];
        }
        fields.push({ data, type });
      }
      item.fields = fields;
    }
  }

  diplayConfirmActionAlert(label, callback) {
    const modalRef: any = this.modalService.open(ConfirmAlert, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
    modalRef.componentInstance.properties = {
      actionLabel: label,
    };

    modalRef.result.then((res) => {
      callback();
    })
      .catch(e => {
        console.log(e);
      });
  }

  deleteItem(item) {
    const label = '¿Estás seguro que deseas eliminar este elemento?';
    this.diplayConfirmActionAlert(label, async () => {
      await this.actions.delete(item.id).toPromise();
      this.helpers.successMessage('Operación exitosa', 'Éxito');
      this.getItems();
    });
  }

  customAction(i, item) {
    const modalRef = this.modalService.open(this.actions.custom[i].modal, this.actions.custom[i].styles);
    modalRef.componentInstance.properties = {
      ...this.actions.custom[i].properties,
      data: item,
      onSubmit: async (res) => {
        try {
          const response = res || {};
          await this.actions.custom[i].submit(item, response.payload).toPromise();
          this.helpers.successMessage('Operación exitosa', 'Éxito');
          this.getItems();
        } catch (err) {
          console.log(err);
          this.helpers.errorMessage(err);
        }
      }
    };
  }

  /**
   * @param data data if updating
   */
  openModal(data: any = {}) {
    const update = !!data.id;
    const modalRef = this.modalService.open(this.modalOptions.modal, this.modalOptions.styles);
    modalRef.componentInstance.properties = {
      data,
      update,
      ... this.modalOptions.properties,
      onSubmit: async (res) => {
        if (!isEmpty(res.payload)) {
          return this.createOrUpdateItem(res.payload, data.id);
        }
      }
    };
  }

  select(event, item) {
    if (this.actions.select) {
      try {
        if (event.target.parentElement.classList.contains('edit') || event.target.classList.contains('edit')) {
          return;
        } else {
          this.actions.select(item);
        }
        
      } catch (err) {
        this.actions.select(item);
      }
    }
  }

  private async createOrUpdateItem(payload, id?) {
    // payload.box_id = this.stockItem.id;
    if (id) {
      await this.actions.update(id, payload).toPromise();
    } else {
      await this.actions.create(payload).toPromise();
    }
    this.helpers.successMessage('Operación exitosa', 'Éxito');
    this.getItems();
  }

  /**
   * Search bar filters handler
   * @param action Search bar filters
   */
  private handleAsideAction(action) {
    // Avoids executing when action is false
    if (action) {
      this.currentFilters = action;
      const options = {
        ...{ page: 1 },
        ...action
      };
      this.getItems(options);
    }
  }
}
