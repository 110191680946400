import { CallbackPipe } from './callback.pipe';
import { FlagsPipe } from './flags.pipe';
import { PrettyJsonPipe } from './prettyjson.pipe';

// Declare all pipes that will going to be used as shared pipes
// this declaration could be foind in the shared app module
export function SharedPipes() {
  return [
    FlagsPipe,
    CallbackPipe,
    PrettyJsonPipe
  ];
}
