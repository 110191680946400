import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// common
import { RouterModule } from '@angular/router';
import { FormModules } from './shared-init-modules';

// services
import { SharedServices } from './services';

// components
import { SharedComponents } from './components';

// pipes
import { SharedPipes } from './pipes';

// Directives
import { SharedDirectives } from './directives';

// external
import { ExternalModules, ExternalForRootModules } from './external-modules';
import { BootstrapModules } from './bs-modules';
import { CalendarModule } from 'angular-calendar';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { StoriesComponent } from './components/stories/stories.component';
import { StoriesStatusComponent } from './components/stories/stories-status/stories-status.component';
import { AdvanceSearchModals } from './components/advance-search/modals';
import { ConfirmAlert } from './components/confirm-alert/confirm-alert.component';
import { HtmlViewerModal, JsonViewerModal } from './components/modals';
// set for root modules
export function forRootBootstrap() {
  return ExternalForRootModules()
    .map(_module => {
      return _module.forRoot();
    });
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormModules(),
    BootstrapModules(),
    ExternalModules(),
    ClipboardModule,
    CalendarModule.forRoot()
  ],
  exports: [
    TranslateModule,
    BootstrapModules(),
    SharedComponents(),
    SharedPipes(),
    SharedDirectives(),
    ExternalModules(),
    FormModules(),

    ClipboardModule,
    CalendarModule
  ],
  providers: [
    SharedServices()
  ],
  declarations: [
    SharedComponents(),
    SharedPipes(),
    SharedDirectives(),
    StoriesComponent
  ],
  entryComponents: [
    AdvanceSearchModals(),
    ConfirmAlert,
    JsonViewerModal,
    HtmlViewerModal,
  ]
})
export class SharedModule { }
