import { Directive, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { HelpersService } from '@shared/services';
import { AppInjector } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {

  @Input("copy-clipboard")
  public payload: string;

  @Input("context")
  public context: string;
 
  @Output("copied")
  public copied: EventEmitter<string> = new EventEmitter<string>();

  helpers = AppInjector.get(HelpersService);
  translate = AppInjector.get(TranslateService);

  @HostListener("click", ["$event"])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.payload)
      return;
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(this.payload.toString());
      this.copied.emit(this.payload);
      this.helpers.successMessage(this.translate.instant('general.copied'));
    }
  }
}