import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class PDFsRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * GET a matadata book
   * @param queryParams
   */
  getMetadata(bookName, queryParams?) {
    // const url = `http://localhost:3002/api/v1/books/metadata/${bookName}`;
    const url = `${this.getBase()}/v1/books/metadata/${bookName}`;
    return this.http.get(url, queryParams);
  }

  /**
   * PUT a metadata book
   * @param queryParams
   */
  updateMetadata(payload?) {
    // const url = `http://localhost:3002/api/v1/books/bulk`;
    const url = `${this.getBase()}/v1/books/bulk`;
    return this.http.put(url, payload);
  }

  /**
   * PUT a metadata book images
   * @param queryParams
   */
  updateMetadataImages(payload?) {
    // const url = `http://localhost:3002/api/v1/books/bulk/images`;
    const url = `${this.getBase()}/v1/books/bulk/images`;
    return this.http.put(url, payload);
  }

  triggerGenerator(payload) {
    // const url = `http://dev-pdfs-generator.micuento.com/pdfs/v1/generator/trigger/item`;
    const url = `${this.getBase()}/v1/generator/trigger/item`;
    return this.http.post(url, payload);
  }

  livePreview(payload, page = 1, limit = 0) {
    // const url = `http://dev-pdfs-generator.micuento.com/pdfs/v1/generator/trigger/item`;
    const url = `${this.getBase()}/v1/generator/live?page=${page}&limit=${limit}`;
    return this.http.post(url, payload);
  }

  userPreview(payload, page = 1, limit = 1) {
    // const url = `http://dev-pdfs-generator.micuento.com/pdfs/v1/generator/trigger/item`;
    const url = `${this.getBase()}/v1/generator/v2/preview?page=${page}&limit=${limit}`;
    return this.http.post(url, payload);
  }

  optimizeImages(book: string) {
    // const url = `http://localhost:3002/api/v1/generator/trigger/item`;
    const url = `${this.getBase()}/v1/generator/preview-templates`;
    return this.http.post(url, { book });
  }

  optimizeCoverImages(book: string) {
    // const url = `http://localhost:3002/api/v1/generator/trigger/item`;
    const url = `${this.getBase()}/v1/generator/cover-templates`;
    return this.http.post(url, { book });
  }

  generateBill(id, payload = {}) {
    // const url = `http://localhost:3002/api/v1/invoices/${id}/generate`;
    const url = `${this.getBase()}/v1/invoices/${id}/generate`;
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201)
            resolve(xhr.response);
          else
            reject(JSON.parse(xhr.response));
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.send(JSON.stringify(payload));
    });
  }

  downloadLivePDF(payload, page = 1, limit = 0) {
    const url = `${this.getBase()}/v1/generator/live/download?page=${page}&limit=${limit}`;
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201)
            resolve(xhr.response);
          else
            reject(JSON.parse(xhr.response));
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.send(JSON.stringify(payload));
    });
  }

  getConfig(queryParams?) {
    const url = `${this.getBase()}/v1/printer_config`;
    return this.http.get(url, queryParams);
  }

  createConfig(payload?) {
    const url = `${this.getBase()}/v1/printer_config`;
    return this.http.post(url, payload);
  }

  updateConfig(id, payload?) {
    const url = `${this.getBase()}/v1/printer_config/${id}`;
    return this.http.put(url, payload);
  }

  private getBase() {
    return `${this.config.basePdfUrl || this.config.baseUrl}/${this.config.basePdfUrlPrefix || this.config.services.pdf}`;
  }
}
