import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { HelpersService } from '@shared/services';
import { FormBuilderComponent } from '../form-element/builder/form-builder.component';

@Component({
  selector: 'confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.scss']
})
export class ConfirmAlert extends AbstractComponent {
  @ViewChild(FormBuilderComponent, { static: true }) form: FormBuilderComponent;
  properties: any = {};

  constructor(
    public activeModal: NgbActiveModal,
    private helpers: HelpersService
  ) {
    super();
  }

  ngOnInit() {
  }

  async confirm() {
    let payload = false;
    if (this.form) {
      if (this.form.form && this.form.form.valid) {
        payload = this.form.form.value;
      } else {
        return
      }
    }
    if (this.properties.onSubmit) {
      await this.properties.onSubmit();
    }
    this.activeModal.close(payload);
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
