import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-status-buttons',
  templateUrl: './status-buttons.component.html',
  styleUrls: ['./status-buttons.component.scss'],
  viewProviders: [
      {
        provide: ControlContainer,
        useExisting: FormGroupDirective
      }
  ]
})
export class StatusButtonsComponent {
  @Input() name = 'active';
  @Input() boolean = false;
  @Input() nullable = false;
  @Input() label;
}
