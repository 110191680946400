import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { ProductsRequests } from '@api/products/products.requests';
import { uniqBy, groupBy, flatten } from 'lodash';

@Component({
  selector: 'advance-search-books-modal',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksModalComponent extends AbstractComponent {

  properties: any = {};

  books: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private products: ProductsRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listbooks();
  }

  confirm() {
    let activeBooks = this.books.filter(book => book.active);
    if (this.properties.searchType) {
      activeBooks = flatten(activeBooks.map(book => book.items));
    }
    activeBooks = activeBooks.map(book => book.slug);
    this.activeModal.close({ value: activeBooks.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.books.forEach(book => book.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(book) {
    const index = this.books.findIndex(el => el['slug'] === book['slug']);
    if (index === -1)
      return;

    this.books[index]['active'] = !this.books[index]['active'];
  }

  private async listbooks() {
    try {
      const queryParams = {
        fields: 'title,cover,slug',
        limit: 200
      };

      let books;
      if (this.properties.searchType) {
        books = await this.products.products({
          ...queryParams,
          variant_type: this.properties.searchType,
          all: true,
          price: false,
        }).toPromise();
        books['data'] = this.groupItems(uniqBy(books['data'], 'slug'));
      } else {
        books = await this.products.stories({
          ...queryParams,
          type: 'book',
        }).toPromise();
      }
      this.books = books['data'] || [];

      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private groupItems(items) {
    console.log(items);
    const groupedItems = groupBy(items, 'model_slug');
    const listItems = [];
    for (const key in groupedItems) {
      if (key) {
        listItems.push({
          items: groupedItems[key],
          cover: groupedItems[key][0]['model_cover'],
          id: groupedItems[key][0]['model_id'],
          sku: groupedItems[key][0]['model_sku'],
          slug: groupedItems[key][0]['model_slug'],
          title: groupedItems[key][0]['model_title'],
        });
      }
    }
    console.log(listItems);
    return listItems;
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(slug => {
      const index = this.books.findIndex(i => i.slug.toLowerCase() === slug.toLowerCase());
      if (index !== -1)
        this.books[index]['active'] = true;
    });

  }
}
