import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { STOCK_TRANSACTION_TYPES } from '@shared/global.types';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'stock-transaction-modal',
  templateUrl: './stock-transaction.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class StockTransactionModal extends AbstractModal {
  types = [
    { name: 'Restock', code: STOCK_TRANSACTION_TYPES.RESTOCK },
    { name: 'Pack', code: STOCK_TRANSACTION_TYPES.PACK }
  ];

  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  get isPack() {
    if (this.form) return this.form.controls['type'].value === STOCK_TRANSACTION_TYPES.PACK;
  }

  get isRestock() {
    if (this.form) return this.form.controls['type'].value === STOCK_TRANSACTION_TYPES.RESTOCK;
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      type: [ { value: data.type, disabled: this.properties.update }, Validators.required],
      amount: [ { value: data.amount || 0, disabled: false }, Validators.required],
      description: [ { value: data.description || '', disabled: false  },],
      active: [ { value: data.active, disabled: false  }, Validators.required],
      date_arrival: [ { value: data.date_arrival || '', disabled: this.isPack  },],
      pack_id: [ { value: data.pack_id, disabled: this.isRestock },],
      stock_item_resource_id: [ { value: data.stock_item_resource_id, disabled: this.isRestock || this.properties.update},],
    });
  }

  getName() {
    return 'Transacción'
  }
}
