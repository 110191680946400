import { HelpersService } from './helpers.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { AsideService } from './aside.service';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

// Declare all services that will going to be used as global
// this declaration could be found in the main app module
export function GlobalServices() {
  return [
    AuthService,
    UserService,
    AsideService,
  ];
}

// Declare all services that will going to be used as shared service
// this declaration could be foind in the shared app module
export function SharedServices() {
  return [
    HelpersService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ];
}
