import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CoreRequests } from '@api/core/core.requests';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-simple-form-modal',
  templateUrl: './simple-form.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class SimpleFormModal extends AbstractModal {
  data;
  languages = [];
  constructor(
    private formBuilder: FormBuilder,
    private core: CoreRequests,
  ) {
    super();
  }

  bootstrapForm(data: any = {}) {
    this.getLanguages();
    const formPayload = {};
    this.data = data;
    for (const elem of this.properties.formData) {
      formPayload[elem.name] = [{ value: data[elem.value] || elem.value || data[elem.name], disabled: false }, elem.required ? Validators.required : null];
    }
    if (this.properties.coverName) {
      data.cover = data.cover || {};
      const cover =  this.formBuilder.group({
        original: [
          { value: data.cover.original, disabled: false },
        ],
        thumbnail: [
          { value: data.cover.thumbnail, disabled: false },
        ],
        medium: [
          { value: data.cover.medium, disabled: false },
        ]
      });
      formPayload['cover'] = [ cover ];
      formPayload['status'] = [ { value: data.status || 'inactive', disabled: false }, Validators.required];
    }
    this.form = this.formBuilder.group(formPayload);
  }

  async onSubmitPayload(payload) {
    const coverName = this.properties.coverName;
    if (coverName) {
      const randomName = `${coverName}-${new Date().valueOf()}`;
      if (this.files) {
        const url = await this.imageService.uploadImage(
          this.files[0], payload.slug || this.properties.data.slug, randomName, `${coverName}s`
        );
        payload.cover =  {
          thumbnail: url,
          medium: url,
          original: url,
        };
      }
    }
    return payload;
  }

  /**
   * listen when file input change
   * @param event input file event
   */
  loadFile = async (name, event: any) => {
    this.form.patchValue({ [name]: event.target.files[0] });
  }

  getName() {
    return this.properties.name || '';
  }

  private getLanguages() {
    if (this.properties.languages) {
      const query = {
        limit: 100,
      };
      query['notCode'] = this.properties.languages.toString();
      this.core.languages(query).toPromise()
        .then(res => {
          this.languages = res['data'] || [];
        })
        .catch(e => this.helpers.errorMessage(e));
    }
  }
}
