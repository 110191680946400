import { Component } from '@angular/core';
import { ProductsRequests } from '@api/products/products.requests';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-add-box-modal',
  templateUrl: './add-box.component.html',
  styleUrls: ['../../general-modal.component.scss', './add-box.component.scss']
})
export class AddGiftBoxModal extends AbstractModal {
  boxes = [];
  config = environment;

  constructor(
    private api: ProductsRequests,
    public activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getBoxes();
  }

  bootstrapForm(data: any = {}) {}

  async getBoxes() {
    const currency = this.properties.currency;
    const book = this.properties.data;
    const queryParams = {
      currency,
      language: book.language,
    };
    const response = await this.api.upsells(queryParams).toPromise();
    this.boxes = response.data || [];
  }

  select(box) {
    this.activeModal.close(box);
  }

  getName() {
    return 'Caja regalo';
  }
}
