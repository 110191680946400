import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'advance-search-datepicker-modal',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerModalComponent extends AbstractComponent {

  properties: any = {};
  value = new Date().toDateString();

  // Date picker props
  hoveredDate: NgbDate | null = null;
  fromDate: any | null;
  toDate: any | null;


  constructor(
    public activeModal: NgbActiveModal,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    super();
  }

  ngOnInit() {
    console.log('datepicker', this.properties);
    this.fromDate = this.properties.startDate ? this.formatter.parse(this.properties.startDate)
      : this.calendar.getToday();
    this.toDate = this.properties.endDate ? this.formatter.parse(this.properties.endDate)
      : this.calendar.getNext(this.calendar.getToday(), 'd', 10);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  confirm() {
    const fromDate = this.formatter.format(this.fromDate);
    const endDate = this.formatter.format(this.toDate);
    // const result = `start_date:${fromDate}, end_date:${endDate}`;
    // console.log(result)
    this.activeModal.close({
      value: {
        [this.properties.startField]: fromDate,
        [this.properties.endField]: endDate
      },
      startDate: fromDate,
      endDate: endDate
    });
    // this.activeModal.close({
    //   startDate: this.formatter.format(this.fromDate),
    //   endDate: this.formatter.format(this.toDate)
    // });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  clean() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
  }
}
