import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent extends AbstractComponent {
  @Input() name: any;
  @Input() data: any = [];
  @Input() label: any;

  constructor() {
    super();
  }

  download(url) {
    const link = document.createElement('a');
    if (url.includes('http')) link.href = this.data;
    else {
      link.href = `data:application/pdf;base64,${Buffer.from(url, 'base64').toString()}`;
    }
    link.target = '_blank';
    link.download = this.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
