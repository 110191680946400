import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { IncidentsRequests } from '@api/incidents/incidents.requests';
import { HelpersService } from '@shared/services';

@Component({
  selector: 'close-incident-modal',
  templateUrl: './close-incident.component.html',
  styleUrls: ['./close-incident.component.scss']
})
export class CloseIncidentModalComponent extends AbstractComponent {
  search = '';
  selectedSolution: any;
  solutions: any[] = [];
  filteredSolutions: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private incidents: IncidentsRequests,
    private helpers: HelpersService
  ) {
    super();
  }

  ngOnInit() {
    this.listSolutions();
  }

  confirm() {
    this.activeModal.close({ solution: this.selectedSolution });
  }

  close() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  filterSolutions() {
    this.filteredSolutions = this.solutions.filter(el => 
      el.name.toLowerCase().includes(this.search.toLowerCase()) || this.search === '');  // Full list if no input
  }

  private async listSolutions() {
    const params = {
      page: 1,
      sort: 'name',
      status: 'active',
      limit: 100
    };
    try {
      const solutions = await this.incidents.getSolutions(params).toPromise();
      this.solutions = solutions['data'] || [];
      this.filteredSolutions = this.solutions;
    } catch (error) {
      this.helpers.errorMessage(error);
    }
  }

}
