import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CoreRequests } from '@api/core/core.requests';
import { ProductsRequests } from '@api/products/products.requests';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractModal } from '../../abstract-modal.component';


const UNAVAILABLE_UPSELLS = ['bundle', 'audio-book']
@Component({
  selector: 'app-add-upsell-modal',
  templateUrl: './add-upsell.component.html',
  styleUrls: ['../../general-modal.component.scss', './add-upsell.component.scss']
})
export class AddUpsellModal extends AbstractModal {
  upsells = [];
  books = [];
  languages = [];
  config = environment;

  constructor(
    private fb: FormBuilder,
    private core: CoreRequests,
    private api: ProductsRequests,
    public activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.getBoxes();
    this.getLanguages();
  }

  bootstrapForm(data: any = {}) {
    this.books = data.products.filter(x => x.product_type === 'book').map(x => ({ id: x.uid, name: `${x.uid} - ${x.title}`}));
    this.form = this.fb.group({
      related_to: [{ value: this.books[0].id, disabled: false }, [Validators.required]],
      language: [{ value: 'es', disabled: false }, [Validators.required, Validators.minLength(2)]],
    });
    this.subscriptions.push(this.form.get('language').valueChanges.subscribe(lang => this.getBoxes(lang)));
  }

  async getBoxes(language = 'es') {
    const currency = this.properties.currency;
    const queryParams = {
      language,
      currency,
      limit: 1000,
      allTypes: true,
    };
    const response = await this.api.upsells(queryParams).toPromise();
    this.upsells = (response.data || []).filter(product => !UNAVAILABLE_UPSELLS.includes(product.type));
  }

  select(box) {
    this.activeModal.close({
      currency: this.properties.currency,
      custom_variant: box.type,
      language: this.form.get('language').value,
      related_to: this.form.get('related_to').value,
      order_cart: true,
      quantity: 1,
      uid: box.uid,
      order_id: this.properties.data.uid
    });
  }


  private getLanguages() {
    const query = { limit: 100 };

    this.core.languages(query).toPromise()
      .then(res => {
        this.languages = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  getName() {
    return 'Upsell';
  }
}
