import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, HelpersService } from '@shared/services';
import { isEmpty } from 'lodash';

@Injectable()
export class  AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private helpers: HelpersService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const currentUser = this.auth.user;
    if (isEmpty(currentUser) || !this.auth.token)
      return this.notAuthorized();

    const stateCheck = new Observable<boolean>((obs) => {
      // custom validations
      obs.next(true);
    });

    return stateCheck;
  }

  /**
   * Trigger not authorized flow
   */
  notAuthorized() {
    this.helpers.warningMessage('Not authenticated', 'Unauthorized');
    this.router.navigate(['/auth/login']);
    return false;
  }
}
