import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class FilesRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * GET a matadata book
   * @param queryParams
   */
  uploadFile(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.files}/v1/files/test-files`;
    return this.http.file(url, payload);
  }

}
