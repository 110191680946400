import { BlankComponent, FullComponent } from './layouts';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StoriesComponent } from './stories/stories.component';
import { AbstractComponent } from './abstract/abstract.component';
import { ConfirmAlert } from './confirm-alert/confirm-alert.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PaginatorTypesComponent } from './paginator/paginator.component';
import { CustomAsideComponent } from './custom-aside/custom-aside.component';
import { NavigationComponent } from './header-navigation/navigation.component';
import { AdvanceSearchComponent, AdvanceSearchModals } from './advance-search';
import { EditOrderModal } from './edit-order-modal/edit-order-modal.component';
import { PaymentTypesComponent } from './payments-types/payments-types.component';
import { CreatePackModal } from './create-pack-modal/create-pack-modal.component';
import { CountriesFlagsComponent } from './countries-flags/countries-flags.component';
import { AsideLayoutComponent } from './custom-aside/aside-layout/aside-layout.component';
import { AsideSearchComponent } from './custom-aside/aside-search/aside-search.component';
import { StoriesStatusComponent } from './stories/stories-status/stories-status.component';
import { IncidentTypeModalComponent } from './incident-types-modal/incident-types.component';
import { CloseIncidentModalComponent } from './close-incident-modal/close-incident.component';
import { AsideTimelineComponent } from './custom-aside/aside-timeline/aside-timeline.component';
import { GenerateInvoiceModal } from './generate-invoice-modal/generate-invoice-modal.component';
import { CreateProductModal } from './create-product-modal/create-product-modal.component';
import { FormElementComponent } from './form-element/form-element.component';
import { FormElementGroupedComponent } from './form-element/form-element-grouped.component';
import { CreateProductVariantModal } from './create-product-variant-modal/create-product-variant-modal.component';
import { TableCountComponent } from './table-count/table-count.component';
import { GeneralModals } from './modals';
import { GeneralIncidentModal } from './incident-modals/general-modal.component';
import { AbstractModal } from './modals/abstract-modal.component';
import { AddressLines } from './address-lines/address-lines.component';
import { StatusButtonsComponent } from './status-buttons/status-buttons.component';
import { PictureComponent } from './picture/picture.component';
import { McTableComponent } from './mc-table/mc-table.component';
import { TagSearchComponent } from './tag-search/tag-search.component';
import { HelpInfoComponent } from './help-info/help-info.component';
import { BaseForm } from './form-element/base-form.component';
import { ProductPricesComponent } from '@modules/ecommerce/product/product-details/prices/prices.component';
import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { McHeaderComponent } from './mc-table/mc-header/mc-header.component';
import { FieldSearchComponent } from './field-search/field-search.component';
import { HtmlViewerComponent } from './html-viewer/html-viewer.component';
import { FileComponent } from './file/file.component';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { FormBuilderComponent } from './form-element/builder/form-builder.component';
import { FileDownloadComponent } from './file-download/file-download.component';
import { IncidentTypeModalV2Component } from './incident-types-modal-v2/incident-types.component';

// Declare all components that will going to be used as shared components
// this declaration could be found in the shared app module
export function SharedComponents() {
  return [
    AddressLines,
    ConfirmAlert,
    EditOrderModal,
    CreatePackModal,
    SidebarComponent,
    StoriesComponent,
    NavigationComponent,
    BreadcrumbComponent,
    CustomAsideComponent,
    AsideLayoutComponent,
    AsideSearchComponent,
    PaymentTypesComponent,
    AsideTimelineComponent,
    StoriesStatusComponent,
    AdvanceSearchComponent,
    CountriesFlagsComponent,
    PaginatorTypesComponent,
    IncidentTypeModalComponent,
    IncidentTypeModalV2Component,
    CloseIncidentModalComponent,
    GenerateInvoiceModal,
    CreateProductModal,
    GeneralIncidentModal,
    CreateProductVariantModal,
    FormBuilderComponent,
    FormElementComponent,
    FormElementGroupedComponent,
    TableCountComponent,
    StatusButtonsComponent,
    McTableComponent,
    McHeaderComponent,
    TagSearchComponent,
    PictureComponent,
    FileComponent,
    HelpInfoComponent,
    FieldSearchComponent,
    ProductPricesComponent,
    JsonViewerComponent,
    FileDownloadComponent,
    HtmlViewerComponent,
    CountrySelectorComponent,
    GeneralModals(),
    AdvanceSearchModals()
  ];
}

// Declare all components that will going to be used as global components
// this declaration could be foind in the main app module
export function GlobalComponents() {
  return [
    FullComponent,
    BlankComponent,
    SpinnerComponent,
    AbstractComponent,
    BaseForm,
    AbstractModal
  ];
}
