import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class ProductsRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * GET a list of books
   * @param queryParams
   */
  stories(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a list of books/bundle related upsells
   * @param queryParams
   */
  getRelatedItems(id) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products/related/${id}`;
    return this.http.get(url);
  }

  /**
   * PUT a list of books/bundle related upsells
   * @param queryParams
   */
  updateRelatedItems(id, related) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products/related/${id}`;
    return this.http.put(url, { related });
  }

  /**
   * GET a list of books/bundle related upsells
   * @param queryParams
   */
  getRelatedItemsInverse(id) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products/related-inverse/${id}`;
    return this.http.get(url);
  }

  /**
   * PUT a list of books/bundle related upsells
   * @param queryParams
   */
  updateRelatedItemsInverse(id, related) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products/related-inverse/${id}`;
    return this.http.put(url, { related });
  }

  /**
   * GET a list of books
   * @param queryParams
   */
  bookInfo(id, queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products/${id}`;
    return this.http.get(url, queryParams);
  }

  audioNames(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/available-character`;
    return this.http.get(url, queryParams);
  }

  createAudioName(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/available-character`;
    return this.http.post(url, queryParams);
  }

  updateAudioName(id, queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/available-character/${id}`;
    return this.http.put(url, queryParams);
  }

  products(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants`;
    return this.http.get(url, queryParams);
  }

  productSort(body?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants/sort`;
    return this.http.post(url, body);
  }

  productId(id, queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants/${id}`;
    return this.http.get(url, queryParams);
  }

  prices(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/prices`;
    return this.http.get(url, queryParams);
  }

  bulkPrices(body?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/prices/products/prices/bulk`;
    const formData = new FormData();
    formData.append('file', body.file);
    return this.http.file(url, body, true);
  }

  createProduct(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants`;
    return this.http.post(url, payload);
  }

  updateProduct(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants/${id}`;
    return this.http.put(url, payload);
  }

  baseProductId(id, queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products/${id}`;
    return this.http.get(url, queryParams);
  }

  upsells(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants/upsell`;
    return this.http.get(url, queryParams);
  }

  createBaseProduct(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products/`;
    return this.http.post(url, payload);
  }

  updateBaseProduct(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/products/${id}`;
    return this.http.put(url, payload);
  }

  getCategories(queryParams = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/categories`;
    return this.http.get(url, queryParams);
  }

  createCategory(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/categories/`;
    return this.http.post(url, payload);
  }

  updateCategory(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/categories/${id}`;
    return this.http.put(url, payload);
  }

  getSubcategories(queryParams = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/subcategories`;
    return this.http.get(url, queryParams);
  }

  createSubcategory(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/subcategories/`;
    return this.http.post(url, payload);
  }

  updateSubcategory(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/subcategories/${id}`;
    return this.http.put(url, payload);
  }

  duplicateSubcategory(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/subcategories/duplicate/${id}`;
    return this.http.post(url, payload);
  }

  // PRODUCT EXTRAS
  getExtra(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/extras`;
    return this.http.get(url, payload);
  }

  createExtra(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/extras`;
    return this.http.post(url, payload);
  }

  updateExtra(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/extras/${id}`;
    return this.http.put(url, payload);
  }

  getProductExtra(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-extras`;
    return this.http.get(url, payload);
  }

  createProductExtra(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-extras`;
    return this.http.post(url, payload);
  }

  bulkProductExtra(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-extras/bulk`;
    return this.http.post(url, payload);
  }

  updateProductExtra(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-extras/${id}`;
    return this.http.put(url, payload);
  }

  getProductAvailability(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-availability`;
    return this.http.get(url, payload);
  }

  bulkProductAvailability(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-availability/bulk`;
    return this.http.post(url, payload);
  }

  // PRODUCT GROUPS
  getGroups(queryParams = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-type-groups`;
    return this.http.get(url, queryParams);
  }

  createGroup(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-type-groups/`;
    return this.http.post(url, payload);
  }

  updateGroup(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-type-groups/${id}`;
    return this.http.put(url, payload);
  }

  getProductTypes(queryParams = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-types`;
    return this.http.get(url, queryParams);
  }

  createProductType(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-types/`;
    return this.http.post(url, payload);
  }

  updateProductType(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-types/${id}`;
    return this.http.put(url, payload);
  }

  // Subscriptions
  getSubscription(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/subscription/plan`;
    return this.http.get(url, payload);
  }

  createSubscription(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/subscription/plan`;
    return this.http.post(url, payload);
  }

  updateSubscription(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/subscription/plan/${id}`;
    return this.http.put(url, payload);
  }
  /**
   * GET a list of orders with subscriptions
   * @param queryParams
   */
  subscriptions(queryParams?) {
    delete queryParams.sort;
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/subscription`;
    return this.http.get(url, queryParams);
  }

  uploadProduct(id) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/products/upload/${id}`;
    return this.http.post(url, {});
  }

  uploadProductVariant(id) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/products/elements/upload/${id}`;
    return this.http.post(url, {});
  }

  duplicateProductVariant(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/products/elements/duplicate/${id}`;
    return this.http.post(url, payload);
  }

  /**
   * Product FAQ endpoints
   * @param queryParams
   */
  productFaqPages(id) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants-faq`;
    return this.http.get(url, { product_variant_id: id, all: true });
  }

  updateProductFaqPage(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants-faq/${id}`;
    return this.http.put(url, payload);
  }

  removeProductFaqPage(id) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants-faq/${id}`;
    return this.http.delete(url);
  }

  addProductFaqPage(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/product-variants-faq`;
    return this.http.post(url, payload);
  }

}
