import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-refund-modal',
  templateUrl: './refund.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class RefundModal extends AbstractModal {
  getName() {
    return 'Reembolso';
  }
  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      amount: [ { value: data.amount, disabled: false }, ]
    });
  }
}
