import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { FormArray, FormGroup } from '@angular/forms';
import { Component } from '@angular/core';
import { HelpersService, ImageService } from '@shared/services';
import { AppInjector } from 'src/app/app.component';
import { STATUSES } from '@shared/global.types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: ``,
  selector: 'app-abstract-modal'
})
export abstract class AbstractModal extends AbstractComponent {
  image = { url: '' };
  files: FileList;
  properties: any = {
    data: {},
    update: false,
    type: '',
  };
  form: FormGroup;
  helpers = AppInjector.get(HelpersService);
  translate = AppInjector.get(TranslateService);
  imageService = AppInjector.get(ImageService);

  statuses = STATUSES.map(val => {
    return {
      name: this.translate.instant(val),
      code: val,
    };
  });

  ngOnInit() {
    this.bootstrapForm(this.properties.data || {});
  }

  abstract bootstrapForm(data);
  abstract getName();

  /**
 * listen when file input change
 * @param event input file event
 */
  loadPicture = async (event: any) => {
    this.files = event.target.files;
    this.image = await this.imageService.getMediaAsBase64(this.files[0]);
  }

  /**
   * Add form row
   */
  addRow(formBuilder, formName) {
    const control = this.form.get(formName) as FormArray;
    control.push(this.initFormExtra(formBuilder));
  }

  /**
   * Delete form row by index
   * @param index row index
   */
  deleteRow(formName, index: number) {
    const control = this.form.get(formName) as FormArray;
    control.removeAt(index);
  }

  initFormExtra(formBuilder, key = '', value = '') {
    return formBuilder.group({key: [key], value: [value] });
  }

  parseToArray(formBuilder, attributes) {
    const formArray = [];
    if ( attributes ) {
      Object.keys(attributes).forEach( key => {
        formArray.push(this.initFormExtra(formBuilder, key, attributes[key]));
      });
    }
    return formArray;
  }

  getFormControl(control) {
    return this.form.get(control) as FormArray;
  }

  parseArray(attributes: any[]) {
    return attributes.reduce((accumulator, { key, value }) => {
      accumulator[key] = value;
      return accumulator;
    }, {});
  }

  async onSubmitPayload(payload) {
    return payload;
  }

  async onSubmit({ close, payload }: any) {
    if (this.properties.onSubmit) {
      try {
        payload = await this.onSubmitPayload(payload);
        await this.properties.onSubmit({ payload });
      } catch (error) {
        this.helpers.errorMessage(error);
        return;
      }
    }
    close({ payload });
  }
}
