import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string| null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: 0,
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null ? `${time.hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')}` : null;
  }
}
