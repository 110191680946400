import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class QueueRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * POST Send order to the queue
   * @param queryParams
   */
  sendToQueue(value, printer = undefined) {
    const url = `${this.config.baseUrl}/queue/v1/queue`;
    return this.http.post(url, {
        value,
        printer,
        service: 'pdfs',
        key: 'orderId'
    });
  }

  /**
   * POST Send order to the queue
   * @param queryParams
   */
  sendBookToQueue(value, printer = undefined) {
    const url = `${this.config.baseUrl}/queue/v1/queue/pdf`;
    return this.http.post(url, {
        value,
        printer,
    });
  }
}
