import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiRequestsService } from '@api/api.gateway';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-product-extras-modal',
  templateUrl: './product-extras.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class ProductExtrasModal extends AbstractModal {
  extras = [];

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiRequestsService,
  ) {
    super();
    this.getExtras();
  }

  bootstrapForm(data: any = {}) {
    data.cover = data.cover || {};
    const cover =  this.formBuilder.group({
      original: [
        { value: data.cover.original, disabled: false },
      ],
      thumbnail: [
        { value: data.cover.thumbnail, disabled: false },
      ],
      medium: [
        { value: data.cover.medium, disabled: false },
      ]
    });
    const attributes = this.formBuilder.array(this.parseToArray(this.formBuilder, data.attributes));
    this.form = this.formBuilder.group({
      cover,
      attributes,
      extra_id: [ { value: data.extra_id, disabled: false }, Validators.required],
      status: [ { value: data.status || 'inactive', disabled: false }, Validators.required],
    });
    this.image.url = data.cover.thumbnail;
  }

  async onSubmitPayload(payload) {
    payload.attributes = this.parseArray(payload.attributes);
    const randomName = `product-extra-${new Date().valueOf()}`;
    if (this.files) {
      const url = await this.imageService.uploadImage(
        this.files[0], payload.slug || this.properties.data.slug, randomName, 'extras'
      );
      payload.cover =  {
        thumbnail: url,
        medium: url,
        original: url,
      };
    }
    return payload;
  }

  private getExtras() {
    const query = { limit: 100 };

    this.api.products.getExtra(query).toPromise()
      .then(res => {
        this.extras = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  getName() {
    return 'Extra';
  }
}
