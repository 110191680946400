import { Injectable } from '@angular/core';
import { AuthRequests } from './auth/auth.requests';
import { UserRequests } from './user/user.requests';
import { CoreRequests } from './core/core.requests';
import { OrdersRequests } from './orders/orders.requests';
import { ProductsRequests } from './products/products.requests';
import { QuestionnairesRequests } from './questionnaires/questionnaires.requests';
import { PDFsRequests } from './pdfs/pdfs.requests';
import { FilesRequests } from './files/files.requests';
import { IncidentsRequests } from './incidents/incidents.requests';
import { PricesRequests } from './prices/prices.requests';
import { PromotionsRequests } from './promotions/promotions.request';
import { QueueRequests } from './queue/queue.requests';
import { ScraperRequests } from './scraper/scraper.requests';

@Injectable()
export class ApiRequestsService {

  constructor(
    public core: CoreRequests,
    public auth: AuthRequests,
    public user: UserRequests,
    public orders: OrdersRequests,
    public products: ProductsRequests,
    public questionnaires: QuestionnairesRequests,
    public pdfs: PDFsRequests,
    public files: FilesRequests,
    public incidents: IncidentsRequests,
    public prices: PricesRequests,
    public promotions: PromotionsRequests,
    public queue: QueueRequests,
    public scraper: ScraperRequests,
  ) { }

}

export function ApiDeclarations() {
  return [
    CoreRequests,
    AuthRequests,
    UserRequests,
    OrdersRequests,
    ProductsRequests,
    QuestionnairesRequests,
    PDFsRequests,
    FilesRequests,
    IncidentsRequests,
    PricesRequests,
    PromotionsRequests,
    QueueRequests,
    ScraperRequests,
  ];
}
