import { Component, OnInit } from '@angular/core';
import { AsideService } from '@shared/services/aside.service';
import * as _menuOptions from './aside-layout-options';
@Component({
  selector: 'app-custom-aside-layout',
  templateUrl: './aside-layout.component.html',
  styleUrls: ['./aside-layout.component.scss']
})
export class AsideLayoutComponent implements OnInit {

  options: any = {};
  layoutOptions = _menuOptions.layout;
  colorOptions = _menuOptions.colors;

  private subscriptions: any = {};

  constructor(
    private asideService: AsideService
  ) { }

  ngOnInit() {
    this.subscriptions.options = this.asideService.optionsUpdated
      .subscribe((options: any) => {
        this.options = options;;
      });
  }

  ngOnDestroy() {
    const keys = Object.keys(this.subscriptions);
    keys.forEach(key => this.subscriptions[key].unsubscribe());
  }

  setChange(key, val) {
    this.asideService.setLayoutOptions({ key, val });
  }

}
