export * from './stock-modals';
export * from './cms-modals';
export * from './general-modals';
export * from './categories-modals';
export * from './product-modals';
export * from './order-modals';
export * from './affiliates-modals';

// main declarations
export * from './index.declarations';
