import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiRequestsService } from '@api/api.gateway';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-product-availability-modal',
  templateUrl: './product-availability.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class ProductAvailabilityModal extends AbstractModal {
  countrylist = [];
  availability = [];

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiRequestsService,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.getAvailability();
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      countries: [ { value: data.countries, disabled: false }, ],
    });
  }

  /**
   * Get a list of countries
   */
  private getCountriesList() {
    const queryParams = {
      // fields: 'code,name,alpha2_code,label',
      sort: 'name',
      limit: 300
    };
    this.api.core.countries(queryParams).toPromise()
      .then(res => {
        this.countrylist = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  /**
   * Get a product availability
   */
  private getAvailability() {
    const queryParams = {
      model_type: this.properties.type,
      model_id: this.properties.data.id,
      limit: 200
    };
    this.api.products.getProductAvailability(queryParams).toPromise()
      .then(res => {
        this.availability = res['data'] || [];
        this.form.get('countries').setValue(this.availability.map(row => row.country_id));
        this.getCountriesList();
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  getName() {
    return 'Disponibilidad';
  }
}
