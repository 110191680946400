import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { CalendarModule } from 'angular-calendar';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
//import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2SmartTableModule } from 'ng2-smart-table';


export function ExternalModules() {
  return [
    PerfectScrollbarModule,
    DataTablesModule,
    NgMultiSelectDropDownModule,
    DragulaModule,
    QuillModule,
    NgxChartsModule,
    ChartistModule,
    ChartsModule,
    //FileUploadModule,
    NgxDatatableModule,
    Ng2SmartTableModule
  ];
}

export function ExternalForRootModules() {
  return [
    CalendarModule
  ];
}
