import { Directive, EventEmitter, ElementRef, HostListener, Output, Input, QueryList, ContentChildren } from '@angular/core';
import { DragDirective } from './drag.directive';

@Directive({
  selector: '[ngDroplist]'
})
export class DroplistDirective {
  @Output() sorted = new EventEmitter<any>();
  @ContentChildren(DragDirective) items!: QueryList<DragDirective>;
  @Input() data: any[];
  @Input() sortable = false;
  dragging = false;
  currentY = 0;
  currentIndex = 0;
  lastIndex = 0;

  constructor(
    private element: ElementRef,
  ) {}


  @HostListener('mousemove', ['$event'])
  mouseMove(event) {
    if (this.dragging && this.sortable) {
      const currentIndex = this.getIndex(event);
      if (currentIndex !== this.lastIndex) {
        // Move items
        this.items.forEach((item, index) => {
          if (index !== currentIndex) {
            if (index > this.currentIndex) {
              if (index <= currentIndex) item.element.nativeElement.style.transform = 'translate3d(0, -63px, 0)';
              else item.element.nativeElement.style.transform = 'translate3d(0, 0, 0)';
            } else if (index < this.currentIndex) {
              if (index >= currentIndex) item.element.nativeElement.style.transform = 'translate3d(0, 63px, 0)';
              else item.element.nativeElement.style.transform = 'translate3d(0, 0, 0)';
            }
          }
        });
      }
      this.lastIndex = currentIndex;
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    if (this.sortable) {
      this.dragging = true;
      this.currentIndex = this.getIndex(event);
      this.lastIndex = this.currentIndex;
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event?) {
    if (this.sortable) {
      this.dragging = false;
      this.items.forEach(item => item.element.nativeElement.style.transform = 'translate3d(0, 0, 0)');
      if (this.currentIndex !== this.lastIndex) this.moveItemInArray();
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event?) {
    if (this.dragging) this.onMouseUp();
  }

  private getIndex(event?) {
    this.currentY = event.clientY - this.element.nativeElement.getBoundingClientRect().y;
    const index = Math.floor(this.currentY * this.data.length / this.element.nativeElement.getBoundingClientRect().height);
    if (index < 0) return 0;
    if (index > this.data.length) return this.data.length;
    return index;
  }

  private moveItemInArray() {
    const current = this.data[this.currentIndex];
    const newArray = this.currentIndex < this.lastIndex
      ? [
          ...this.data.slice(0, this.currentIndex), ...this.data.slice(this.currentIndex + 1, this.lastIndex),
          current, ...this.data.slice(this.lastIndex)
        ]
      : [
          ...this.data.slice(0, this.lastIndex + 1), current,
          ...this.data.slice(this.lastIndex + 1, this.currentIndex), ...this.data.slice(this.currentIndex + 1)
        ];
    this.sorted.emit(newArray);
  }
}
