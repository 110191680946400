import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class ScraperRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  list(queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/scraper`;
    return this.http.get(url, queryParams);
  }

  listKeywords(queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/scraper/keywords`;
    return this.http.get(url, queryParams);
  }

  update(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/scraper/${id}`;
    return this.http.put(url, payload);
  }

  bulkUpdate(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/scraper/bulk`;
    return this.http.put(url, payload);
  }

  search(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/scraper`;
    return this.http.post(url, payload);
  }

  webScraper(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.products}/v1/scraper/web`;
    return this.http.post(url, payload);
  }

  sendEmails(queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/scraper/send-emails`;
    return this.http.post(url, queryParams);
  }
}
