import { Component } from '@angular/core';
import { IncidentsRequests } from '@api/incidents/incidents.requests';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';

@Component({
  selector: 'app-search-solution-modal',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss']
})
export class SolutionModalComponent extends AbstractComponent {

  properties: any = {};

  solutions: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private incidents: IncidentsRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listSolutions();
  }

  confirm() {
    const activeSolutions = this.solutions.map(method => method.active ? method.id : false).filter(e => e);
    this.activeModal.close({ value: activeSolutions.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.solutions.forEach(method => method.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(method) {
    const index = this.solutions.findIndex(el => el['id'] === method['id']);
    if (index === -1)
      return;

    this.solutions[index]['active'] = !this.solutions[index]['active'];
  }

  private async listSolutions() {
    try {
      const queryParams = {
        limit: 300
      };
      const solutions = await this.incidents.solutions(queryParams).toPromise();
      this.solutions = solutions['data'] || [];

      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(id => {
      const index = this.solutions.findIndex(i => i.id.toLowerCase() === id.toLowerCase());
      if (index !== -1)
        this.solutions[index]['active'] = true;
    });

  }
}
