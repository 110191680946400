import { DatepickerModalComponent } from './datepicker/datepicker.component';
import { TextareaModalComponent } from './textarea/textarea.component';
import { CountriesModalComponent } from './countries/countries.component';
import { CurrenciesModalComponent } from './currencies/currencies.component';
import { StatusesModalComponent } from './statuses/statuses.component';
import { StatusesIncidentsModalComponent } from './statuses-incidents/statuses.component';
import { StatusesAffiliatesModalComponent } from './statuses-affiliates/statuses.component';
import { DomainAffiliatesModalComponent } from './domain-affiliates/domain.component';
import { SourcesAffiliatesModalComponent } from './sources-affiliates/sources.component';
import { AdSenseAffiliatesModalComponent } from './adsense-affiliates/adsense.component';
import { StatusesPacksModalComponent } from './statuses-packs/statuses.component';
import { UsersModalComponent } from './users/users.component';
import { SolutionModalComponent } from './solution/solution.component';
import { PayMethodsModalComponent } from './pay-methods/pay-methods.component';
import { ReasonTypeModalComponent } from './reason-type/reason-type.component';
import { ReasonModalComponent} from './reason/reason.component';
import { BooksModalComponent} from './books/books.component';
import { LangsModalComponent  } from './langs/langs.component';
import { VersionsModalComponent  } from './versions/versions.component';
import { PrinterModalComponent } from './printers/printers.component';
import { CouriersModalComponent } from './delivery-couriers/delivery-couriers.component';
import { TypeSelectionComponent } from './type-selection/type-selection.component';
import { SelectModalComponent } from './selection/selection.component';
import { ShippingMethodComponent } from './shipping_method/shipping_method.component';
import { HasUpsellsComponent } from './has_upsells/has_upsells.component';
import { ContentTypeComponent } from './content_type/content_type.component';
import { PartnerSourceComponent } from './partner-source/partner-source.component';
import { ProductTypeComponent } from './product_type/product_type.component';


// Declare all modals that will going to be used as shared filters
// this declaration could be found in the shared app module
export function AdvanceSearchModals() {
  return [
    DatepickerModalComponent,
    TextareaModalComponent,
    CountriesModalComponent,
    CurrenciesModalComponent,
    StatusesModalComponent,
    StatusesIncidentsModalComponent,
    StatusesAffiliatesModalComponent,
    DomainAffiliatesModalComponent,
    SourcesAffiliatesModalComponent,
    AdSenseAffiliatesModalComponent,
    PayMethodsModalComponent,
    StatusesPacksModalComponent,
    UsersModalComponent,
    ReasonModalComponent,
    SolutionModalComponent,
    ReasonTypeModalComponent,
    BooksModalComponent,
    LangsModalComponent,
    VersionsModalComponent,
    PrinterModalComponent,
    CouriersModalComponent,
    TypeSelectionComponent,
    SelectModalComponent,
    ContentTypeComponent,
    ProductTypeComponent,
    ShippingMethodComponent,
    PartnerSourceComponent,
    HasUpsellsComponent,
  ];
}
