import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrdersRequests } from '@api/orders/orders.requests';
import { SelectModalComponent } from '../selection/selection.component';

@Component({
  selector: 'advance-search-couriers-modal',
  templateUrl: '../selection/selection.component.html',
  styleUrls: ['../selection/selection.component.scss']
})
export class CouriersModalComponent extends SelectModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private orders: OrdersRequests
  ) {
    super(activeModal);
  }

  /**
   * Sets the endpoint to check for the data
   * @param params Query params from the select component
   */
  async getData(params: any) {
    return this.orders.deliveryCouriers(params).toPromise();
  }
}
