import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';
import { ProductsRequests } from '@api/products/products.requests';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class CategoryModal extends AbstractModal {
  categories = [];

  constructor(
    private formBuilder: FormBuilder,
    private products: ProductsRequests,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.subscriptions.push( this.form.controls['name'].valueChanges.subscribe(value => {
      const slug = this.helpers.getSlug(value);
      this.form.patchValue({ slug });
      this.form.get('slug').markAsDirty();
    }));
    this.listcategories();
  }

  bootstrapForm(data: any = {}) {
    data.cover = data.cover || {};
    const cover =  this.formBuilder.group({
      original: [
        { value: data.cover.original, disabled: false },
      ],
      thumbnail: [
        { value: data.cover.thumbnail, disabled: false },
      ],
      medium: [
        { value: data.cover.medium, disabled: false },
      ]
    });
    this.form = this.formBuilder.group({
      cover,
      title: [ { value: data.title || '', disabled: false }, Validators.required],
      name: [ { value: data.name || '', disabled: false }, Validators.required],
      description: [ { value: data.description || '', disabled: false }, ],
      sort_order: [ { value: data.sort_order || 0, disabled: false }, ],
      slug: [ { value: data.slug || '', disabled: false }, Validators.required],
      parent_id: [ { value: data.parent_id, disabled: false }, ],
      status: [ { value: data.status || 'inactive', disabled: false }, Validators.required],
    });
    this.image.url = data.cover.thumbnail;
  }

  private async listcategories() {
    try {
      const queryParams = { limit: 500, all: true };
      const categories = await this.products.getCategories(queryParams).toPromise();
      this.categories = categories['data'] || [];
    } catch (error) {
      // TODO handle this errors
    }
  }

  async onSubmitPayload(payload) {
    if (this.files) payload.cover = await this.imageService.uploadPicture(
      payload.slug || this.properties.data.slug, this.files
    );
    return payload;
  }

  getName() {
    return 'Category';
  }
}
