import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';

export abstract class SelectModalComponent extends AbstractComponent {

  properties: any = {};

  elements: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit() {
    this.listStatuses();
  }

  confirm() {
    const activeStatuses = this.elements.map(method => method.active ? method.slug : false).filter(e => e);
    this.activeModal.close({ value: activeStatuses.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.elements.forEach(method => method.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(method) {
    const index = this.elements.findIndex(el => el['slug'] === method['slug']);
    if (index === -1)
      return;

    this.elements[index]['active'] = !this.elements[index]['active'];
  }

  abstract getData(params: any): Promise<any>;

  private async listStatuses() {
    try {
      const queryParams = {
        sort: 'name',
        limit: 200
      };
      const elements = await this.getData(queryParams);
      this.elements = elements['data'] || [];

      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(slug => {
      const index = this.elements.findIndex(i => i.slug.toLowerCase() === slug.toLowerCase());
      if (index !== -1)
        this.elements[index]['active'] = true;
    });

  }
}
