import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { OrdersRequests } from '@api/orders/orders.requests';

@Component({
  selector: 'advance-search-pay-methods-modal',
  templateUrl: './pay-methods.component.html',
  styleUrls: ['./pay-methods.component.scss']
})
export class PayMethodsModalComponent extends AbstractComponent {

  properties: any = {};

  payMethods: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private orders: OrdersRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listStatuses();
  }

  confirm() {
    const activeStatuses = this.payMethods.map(method => method.active ? method.slug : false).filter(e => e);
    this.activeModal.close({ value: activeStatuses.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.payMethods.forEach(method => method.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(method) {
    const index = this.payMethods.findIndex(el => el['slug'] === method['slug']);
    if (index === -1)
      return;

    this.payMethods[index]['active'] = !this.payMethods[index]['active'];
  }

  private async listStatuses() {
    try {
      const queryParams = {
        sort: 'name',
        limit: 200
      };
      const payMethods = await this.orders.paymentMethods(queryParams).toPromise();
      this.payMethods = payMethods['data'] || [];

      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(slug => {
      const index = this.payMethods.findIndex(i => i.slug.toLowerCase() === slug.toLowerCase());
      if (index !== -1)
        this.payMethods[index]['active'] = true;
    });

  }
}
