import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AsideService } from '@shared/services/aside.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

  config: PerfectScrollbarConfigInterface = {};
  tabStatus = 'justified';
  isCollapsed = false;
  innerWidth: any;
  defaultSidebar: any;
  showSettings = false;
  showMobileMenu = false;
  expandLogo = false;
  options: any = {};
  private subscriptions: any = {};

  constructor(
    public router: Router,
    private asideService: AsideService
  ) { }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/classic']);
    }

    this.subscriptions.options = this.asideService.optionsUpdated
      .subscribe((options: any) => {
        this.options = options;
        this.defaultSidebar = this.options.sidebartype;
      });

    this.handleSidebar();
  }

  ngOnDestroy() {
    const keys = Object.keys(this.subscriptions);
    keys.forEach(key => this.subscriptions[key].unsubscribe());
  }

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
}
