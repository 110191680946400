import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PRODUCT_TYPES, UPSELL_TYPES } from '@shared/global.types';
import { SelectModalComponent } from '../selection/selection.component';

@Component({
  selector: 'advance-search-shipping-modal',
  templateUrl: '../selection/selection.component.html',
  styleUrls: ['../selection/selection.component.scss']
})
export class ProductTypeComponent extends SelectModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {
    super(activeModal);
  }

  /**
   * Sets the endpoint to check for the data
   * @param params Query params from the select component
   */
  async getData(params: any) {
    return { data: [
      { name: this.translate.instant(`cms.${PRODUCT_TYPES.BOOK}`), slug: PRODUCT_TYPES.BOOK },
      { name: this.translate.instant(`cms.${PRODUCT_TYPES.AUDIO_BOOK}`), slug: PRODUCT_TYPES.AUDIO_BOOK },
      { name: this.translate.instant(`cms.${UPSELL_TYPES.GIFT_BOX}`), slug: UPSELL_TYPES.GIFT_BOX },
      { name: this.translate.instant(`cms.${UPSELL_TYPES.COLORING_BOOK}`), slug: UPSELL_TYPES.COLORING_BOOK },
      { name: this.translate.instant(`cms.${UPSELL_TYPES.MERCHANDISING}`), slug: UPSELL_TYPES.MERCHANDISING },
      { name: this.translate.instant(`cms.${UPSELL_TYPES.POSTCARD}`), slug: UPSELL_TYPES.POSTCARD },
      { name: this.translate.instant(`cms.${UPSELL_TYPES.MAGAZINE}`), slug: UPSELL_TYPES.MAGAZINE },
    ]};
  }
}
