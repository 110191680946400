import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { environment } from '@env/environment';
import { ApiRequestsService } from '@api/api.gateway';

@Component({
  selector: 'country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  viewProviders: [
      {
        provide: ControlContainer,
        useExisting: FormGroupDirective
      }
  ]
})
export class CountrySelectorComponent extends AbstractComponent {
  config = environment;
  search: any = {
    showList: false,
    filteredCountries: [],
  };
  countries = [];
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() placeholder = '';
  @Output() provinces = new EventEmitter<any>();

  constructor(private api: ApiRequestsService) {
    super();
  }

  get control() {
    return this.form.controls[this.name];
  }

  get value() {
    return this.form.get(this.name).value;
  }

  ngOnInit() {
    this.listCountries();
  }

  openCountrySelect() {
    this.search.showList = true;
  }

  closeCountrySelect() {
    this.search.showList = false;
  }

  filterCountries() {
    this.openCountrySelect();
    const searchValue: string = this.form.get('country').value;
    this.search.filteredCountries = this.countries.filter(el =>
      el.name.toLowerCase().includes(searchValue.toLowerCase()) || searchValue === '');  // Full list if no input
  }

  toggleCountry(country: any) {
    const index = this.countries.findIndex(i => i.code === country.code);
    if (index !== -1) {
      this.form.patchValue({ country: this.countries[index].code });
    }
    this.closeCountrySelect();
    this.listProvinces(country.code);
  }

  private async listCountries() {
    try {
      const queryParams = {
        fields: 'code,name,alpha2_code',
        enable_shipping: true,
        sort: 'name',
        limit: 300
      };
      const countries = await this.api.core.countries(queryParams).toPromise();
      this.countries = countries['data'] || [];
      this.search.filteredCountries = this.countries;
    } catch (error) {
      // TODO handle this errors
    }
  }

  private async listProvinces(country: string) {
    try {
      const queryParams = {
        country,
        currency: 'EUR',
        limit: 200
      };
      const provinces = await this.api.core.provinces(queryParams).toPromise();
      this.provinces.emit(provinces['data'])
    } catch (error) {
      // TODO handle this errors
    }
  }

  isInvalid() {
    return !this.form.get(this.name).valid && this.form.get(this.name).dirty;
  }

}
