import { Component } from '@angular/core';
import { CoreRequests } from '@api/core/core.requests';
import { ProductsRequests } from '@api/products/products.requests';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectModalComponent } from '../selection/selection.component';

@Component({
  selector: 'advance-search-shipping-modal',
  templateUrl: '../selection/selection.component.html',
  styleUrls: ['../selection/selection.component.scss']
})
export class ContentTypeComponent extends SelectModalComponent {
  extras = [];
  constructor(
    public activeModal: NgbActiveModal,
    private api: ProductsRequests,
  ) {
    super(activeModal);
  }

  /**
   * Sets the endpoint to check for the data
   * @param params Query params from the select component
   */
  async getData(params: any) {
    const queryParams = { fields: 'slug,name', limit: 300 };
    return this.api.getExtra(queryParams).toPromise();
  }
}
