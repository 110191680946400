import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CMS_ENTRY_TYPES, CMS_ENTRY_PAGES } from '@shared/global.types';
import { AbstractModal } from '../../abstract-modal.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-search-affiliates-modal',
  templateUrl: './search-affiliates.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class SearchAffiliatesModal extends AbstractModal {

  domains: any[] = [];
  times: any[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
    this.domains = [
      {
        name: 'google.com.co',
        code: 'google.com.co'
      },
      {
        name: 'google.cl',
        code: 'google.cl'
      },
      {
        name: 'google.com.mx',
        code: 'google.com.mx'
      },
      {
        name: 'google.es',
        code: 'google.es'
      },
      {
        name: 'google.fr',
        code: 'google.fr'
      },
      {
        name: 'google.it',
        code: 'google.it'
      },
      {
        name: 'google.com',
        code: 'google.com'
      },
    ]

    this.times = [
      {
        name: '1 año',
        code: 'y'
      },
      {
        name: '1 mes',
        code: 'm'
      },
      {
        name: '15 días',
        code: 'd15'
      },
    ]
  }


  ngAfterViewInit() {}

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      domain: [ { value: data.domain || '' }, Validators.required],
      time_period: [ data.time_period || ''],
      keyword: [ { value: data.keyword, disabled: false }, Validators.required],
    });
  }

  getName() {
    return 'Búsqueda';
  }
}
