import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stories-status',
  templateUrl: './stories-status.component.html',
  styleUrls: ['./stories-status.component.scss']
})
export class StoriesStatusComponent implements OnInit {

  @Input() count: String;
  @Input() status: String;
  @Input() description: String;
  @Input() active: Boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
