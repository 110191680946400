import { Component, OnInit } from '@angular/core';
import { ApiRequestsService } from '@api/api.gateway';
import { AuthService, UserService } from '@shared/services';
import { Observable } from 'rxjs/Observable';
import { ROUTES } from './menu-items';
import 'rxjs/add/observable/interval';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  user: any = {};
  showSubMenu = '';
  hasNews = false;
  newsWatcher;
  public sidebarnavItems: any[];

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu)
      this.showMenu = '0';
    else
      this.showMenu = element;
  }


  addActiveClass(element: any) {
    if (element === this.showSubMenu)
      this.showSubMenu = '0';
    else
      this.showSubMenu = element;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private api: ApiRequestsService,
  ) { }

  // End open close
  ngOnInit() {
    this.user = this.authService.user;
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    this.getNews();
    this.newsWatcher = Observable.interval(60000).subscribe(() => {
      this.getNews();
    });
  }

  ngOnDestroy() {
    this.newsWatcher.unsubscribe();
  }

  async getNews() {
    try {
      const news = await this.api.core.news({ limit: 1, sort: '-created_at' }).toPromise();
      if (localStorage.getItem('latest-news') !== news.data[0].id) this.hasNews = true;
    } catch (err) {
      console.log('Error getting news');
    }
  }

  isPartner() {
    return this.authService.hasPermission('GetPartner-Order');
  }

  async getPartnerUrl() {
    const commerceId = this.authService.partner.uid || 'PARTNER_ID';
    await this.userService.me();
    const url = this.getLangBaseUrl(this.authService.user.language);
    window.location.replace(`${url}/widget/documentation?commerceId=${commerceId}`);
  }

  getLangBaseUrl(lang) {
    const language = lang.replace("_", "-");
    let baseUrl;
    switch (lang) {
      case 'fr':
        baseUrl = 'https://monhistoire.com';
        break;
      case 'fr_ch':
        baseUrl = 'https://monhistoire.com/ch';
        break;
      case 'de':
        baseUrl = 'https://mystoryland.com/de';
        break;
      case 'en_au':
        baseUrl = 'https://mystoryland.com/au';
        break;
      case 'en':
        baseUrl = 'https://mystoryland.com/us';
        break;
      case 'en_gb':
        baseUrl = 'https://mystoryland.com/uk';
        break;
      default:
        baseUrl = `https://micuento.com${language !== 'es' ? `/${language}` : ''}`;
    }
    return baseUrl;
  }
}
