import { Component, Input  } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { pick } from 'lodash';

@Component({
  selector: 'app-field-search',
  templateUrl: './field-search.component.html',
  styleUrls: ['./field-search.component.scss']
})
export class FieldSearchComponent extends AbstractComponent {
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() options: any[] = [];
  @Input() values: string;
  tags = [];

  ngOnInit() {
    const savedFields = this.form.get(this.name).value;
    this.options = this.options.map(field => ({
      field,
      selected: savedFields.includes(field),
    }));
  }

  toggleTag(field) {
    field.selected = !!!field.selected;
    this.updateForm();
  }

  updateForm() {
    const options =  this.options.filter(x => x.selected);
    if (this.values) {
      const values = this.values.split(',');
      this.form.patchValue({ [this.name]: options.map((option) => pick(option, values))});
    } else {
      this.form.patchValue({ [this.name]: options.map((option) => option.field) });
    }
    this.form.get(this.name).markAsDirty();
  }

}
