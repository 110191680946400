import { ImgS3Directive } from './remote-image.directive';
import { HasPermissionDirective } from './hasPermission.directive';
import { DragDirective } from './drag.directive';
import { DroplistDirective } from './droplist.directive';
import { CopyClipboardDirective } from './copy-clipboard.directive';

// Declare all directives that will going to be used as shared directives
// this declaration could be foind in the shared app module
export function SharedDirectives() {
  return [
    ImgS3Directive,
    HasPermissionDirective,
    DragDirective,
    DroplistDirective,
    CopyClipboardDirective,
  ];
}
