import { Injectable } from '@angular/core';
import { User } from '@interfaces/user';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _token = localStorage.getItem('userToken') || '';
  private _user: User = JSON.parse(localStorage.getItem('user') || '{}') || {};
  private _partner: User = JSON.parse(localStorage.getItem('partner') || '{}') || {};

  constructor(
  ) { }

  set token(token) {
    localStorage.setItem('userToken', token);
    this._token = token;
  }

  get token() {
    return this._token;
  }

  set user(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this._user = user;
  }

  get user() {
    return this._user;
  }

  set partner(partner) {
    localStorage.setItem('partner', JSON.stringify(partner));
    this._partner = partner;
  }

  get partner() {
    return this._partner;
  }

  hasPermission(permission) {
    const token = localStorage.getItem('userToken');
    if (token) {
      const decode = jwt_decode(token);

      for (const checkPermission of decode.scopes) {
        if (checkPermission === '*' || permission.toUpperCase() === checkPermission.toUpperCase()) {
          return true;
        }
      }
    }
    return false;
  }

  isUser(token) {
    if (token) {
      const decode = jwt_decode(token);

      for (const checkPermission of decode.scopes) {
        if ('ISUSER' === checkPermission.toUpperCase()) {
          return true;
        }
      }
    }
    return false;
  }
}
