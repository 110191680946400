export const layout = [
  {
    label: 'Layout Settings',
    options: [
      {
        label: 'Dark Theme',
        id: 'theme-view',
        key: 'theme',
        selected: 'dark',
        option: 'light',
        type: 'checkbox',
      },
      {
        label: 'Fixed Sidebar',
        id: 'sidebar-position',
        key: 'sidebarpos',
        selected: 'fixed',
        option: 'absolute',
        type: 'checkbox'
      },
      {
        label: 'Fixed Header',
        id: 'header-position',
        key: 'headerpos',
        selected: 'fixed',
        option: 'absolute',
        type: 'checkbox',
      },
      {
        label: 'Boxed Layout',
        id: 'boxed-layout',
        key: 'boxed',
        selected: 'boxed',
        option: 'full',
        type: 'checkbox',
      },
      {
        label: 'RTL',
        id: 'rtl-layout',
        key: 'dir',
        selected: 'rtl',
        option: 'ltr',
        type: 'checkbox',
      },
    ]
  },
  {
    label: 'Sidebar Types',
    options: [
      {
        label: 'Mini Sidebar',
        id: 'sidebar',
        name: 'sidebar',
        key: 'sidebartype',
        selected: 'mini-sidebar',
        option: '',
        type: 'radio',
      },
      {
        label: 'Icon Sidebar',
        id: 'iconsidebar',
        name: 'sidebar',
        key: 'sidebartype',
        selected: 'iconbar',
        option: '',
        type: 'radio',
      },
      {
        label: 'Overlay Sidebar',
        id: 'overlaysidebar',
        name: 'sidebar',
        key: 'sidebartype',
        selected: 'overlay',
        option: '',
        type: 'radio',
      },
      {
        label: 'Full Sidebar',
        id: 'fullsidebar',
        name: 'sidebar',
        key: 'sidebartype',
        selected: 'full',
        option: '',
        type: 'radio',
      }
    ]
  }
];

export const colors = [
  {
    label: 'Logo Backgrounds',
    options: [
      {
        key: 'logobg',
        selected: 'skin1'
      },
      {
        key: 'logobg',
        selected: 'skin2'
      },
      {
        key: 'logobg',
        selected: 'skin3'
      },
      {
        key: 'logobg',
        selected: 'skin4'
      },
      {
        key: 'logobg',
        selected: 'skin5'
      },
      {
        key: 'logobg',
        selected: 'skin6'
      }
    ]
  },
  {
    label: 'Navbar Backgrounds',
    options: [
      {
        key: 'navbarbg',
        selected: 'skin1'
      },
      {
        key: 'navbarbg',
        selected: 'skin2'
      },
      {
        key: 'navbarbg',
        selected: 'skin3'
      },
      {
        key: 'navbarbg',
        selected: 'skin4'
      },
      {
        key: 'navbarbg',
        selected: 'skin5'
      },
      {
        key: 'navbarbg',
        selected: 'skin6'
      }
    ]
  },
  {
    label: 'Sidebar Backgrounds',
    options: [
      {
        key: 'sidebarbg',
        selected: 'skin1'
      },
      {
        key: 'sidebarbg',
        selected: 'skin2'
      },
      {
        key: 'sidebarbg',
        selected: 'skin3'
      },
      {
        key: 'sidebarbg',
        selected: 'skin4'
      },
      {
        key: 'sidebarbg',
        selected: 'skin5'
      },
      {
        key: 'sidebarbg',
        selected: 'skin6'
      }
    ]
  }
];

