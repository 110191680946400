import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CMS_ENTRY_TYPES, CMS_ENTRY_PAGES } from '@shared/global.types';
import { AbstractModal } from '../../abstract-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { AFFILIATE_REJECTED_REASONS, AFFILIATE_STATUSES } from '@modules/affiliates/affiliates.constants';
@Component({
  selector: 'app-edit-affiliates-modal',
  templateUrl: './edit-affiliates.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class EditAffiliatesModal extends AbstractModal {
  isRejected = false;
  rejectedOptions = AFFILIATE_REJECTED_REASONS;

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
    this.statuses = AFFILIATE_STATUSES

  }


  ngAfterViewInit() {}

  handleStatusChange(event) {
    this.isRejected = event === 'rejected';
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      id: [ { value: data.id || '', disabled: this.properties.update }, this.properties.bulk ? null : Validators.required],
      site_name: [ { value: data.site_name || '', disabled: false },  this.properties.bulk ? null : Validators.required],
      domain: [ { value: data.domain || '', disabled: this.properties.update },  this.properties.bulk ? null : Validators.required],
      email: [ { value: data.email || 1, disabled: false }],
      country: [ { value: data.country || '', disabled: false }],
      status: [ { value: data.status || '', disabled: false }, Validators.required],
      observations: [ { value: data.observations || '', disabled: false }],
      // status_reason_id: [ { value: data.status_reason_id || '', disabled: false }, Validators.required],
    });
    this.subscriptions['statuses'] = this.form.get('status').valueChanges.subscribe(e => this.handleStatusChange(e));
  }

  getName() {
    return 'Afiliado';
  }
}
