import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { HelpersService } from '@shared/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { INCIDENT_TYPES } from '@shared/global.types';

@Component({
  selector: 'general-incident-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralIncidentModal extends AbstractComponent {
  isReasonType;

  properties: any = {
    data: {},
    update: false,
    type: '',
  };
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private helpers: HelpersService,
  ) {
    super();
  }

  ngOnInit() {
    this.isReasonType = this.properties.type === INCIDENT_TYPES.TYPE;
    this.bootstrapForm(this.properties.data || {});
    if (this.isReasonType && !this.properties.update) {
      this.subscriptions.push( this.form.controls['name'].valueChanges.subscribe(value => {
        const slug = this.helpers.getSlug(value);
        this.form.patchValue({ slug });
        this.form.get('slug').markAsDirty();
      }) );
    }
  }

  /**
   * Handle form submission
   * @param formLogin form data
   */
  async onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      this.helpers.displayFormErrors(this.form);
      return;
    }
    let payload = value;
    this.helpers.setformAsValid(this.form);
    if (this.properties.update) {
      payload = this.getDirtyValues(this.form);
    }
    this.activeModal.close({ payload });
  }

  close() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  /**
   *
   * @param cg Base form hroup
   * @returns a dict with the modified values
   */
  private getDirtyValues(cg) {
    const dirtyValues = {};  // initialize empty object
    Object.keys(cg.controls).forEach((c) => {
       const currentControl = cg.get(c);

       if (currentControl.dirty) {
          if (currentControl.controls) {
            dirtyValues[c] = this.getDirtyValues(currentControl);  //recursion for nested controlGroups
          } else {
            dirtyValues[c] = currentControl.value;
          }
       }

      });
    return dirtyValues;
  }

  private bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      name: [ { value: data.name || '', disabled: false }, Validators.required],
      slug: [ { value: data.slug || '', disabled: !this.isReasonType || (this.isReasonType && this.properties.update)  }, Validators.required],
      description: [ { value: data.description || '', disabled: false  }, Validators.required],
      status: [ { value: data.status || '', disabled: false  }, Validators.required],
    });
  }

  getName() {
    let product = '';
    switch (this.properties.type) {
      case INCIDENT_TYPES.REASON:
        product = 'Incidencia';
        break;
      case INCIDENT_TYPES.TYPE:
        product = 'Tipo de incidencia';
        break;
      case INCIDENT_TYPES.SOLUTION:
        product = 'Solución';
        break;
    }
    return `${this.properties.update ? 'Editar' : 'Nuevo' } ${product}`;
  }

}
