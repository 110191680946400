// Http
import { HttpClientModule } from '@angular/common/http';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export function FormModules() {
  return [
    FormsModule,
    ReactiveFormsModule
  ];
}

export function HttpModules() {
  return [
    HttpClientModule
  ];
}
