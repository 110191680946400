import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectModalComponent } from '../selection/selection.component';

@Component({
  selector: 'advance-search-shipping-modal',
  templateUrl: '../selection/selection.component.html',
  styleUrls: ['../selection/selection.component.scss']
})
export class ShippingMethodComponent extends SelectModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  /**
   * Sets the endpoint to check for the data
   * @param params Query params from the select component
   */
  async getData(params: any) {
    return { data: [
      { name: 'Standard', slug: 'standard' },
      { name: 'Express', slug: 'express' },
      { name: 'Rush', slug: 'rush' }
    ]};
  }
}
