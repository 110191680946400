import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { CoreRequests } from '@api/core/core.requests';

@Component({
  selector: 'advance-search-langs-modal',
  templateUrl: './langs.component.html',
  styleUrls: ['./langs.component.scss']
})
export class LangsModalComponent extends AbstractComponent {

  properties: any = {};

  langs: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private core: CoreRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listlangs();
  }

  confirm() {
    const activelangs = this.langs.map(lang => lang.active ? lang.code : false).filter(e => e);
    this.activeModal.close({ value: activelangs.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.langs.forEach(lang => lang.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(lang) {
    const index = this.langs.findIndex(el => el['code'] === lang['code']);
    if (index === -1)
      return;

    this.langs[index]['active'] = !this.langs[index]['active'];
  }

  private async listlangs() {
    try {
      const queryParams = {
        fields: 'code,name,label',
        // sort: 'name',
        status: 'active',
        limit: 200
      };
      const langs = await this.core.languages(queryParams).toPromise();
      this.langs = langs['data'] || [];

      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(code => {
      const index = this.langs.findIndex(i => i.code.toLowerCase() === code.toLowerCase());
      if (index !== -1)
        this.langs[index]['active'] = true;
    });

  }
}
