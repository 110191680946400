import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { AsideService } from '@shared/services/aside.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, isObject } from 'lodash';
import { debounceTime, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrdersRequests } from '@api/orders/orders.requests';
import * as moment from 'moment';

@Component({
  selector: 'app-aside-search',
  templateUrl: './aside-search.component.html',
  styleUrls: ['./aside-search.component.scss']
})
export class AsideSearchComponent extends AbstractComponent implements OnInit {

  options: any = {};

  activeFilters: any[] = [];
  displaySearchProduct$: Observable<any> = this.asideService.displaySearchProduct;
  hideReport$: Observable<any> = this.asideService.hideReport;
  loadingReport = false;

  constructor(
    private asideService: AsideService,
    private modalService: NgbModal,
    private api: OrdersRequests
  ) {
    super();
  }

  get advanceSeachOptions() {
    const keys = Object.keys(this.options) || [];
    return keys;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.asideService.searchOptions
        .pipe(
          tap(() => {
            this.resetFilters();
            this.options = {};
          }),
          debounceTime(200)
        ).
        subscribe(opts => this.handleSearchOptions(opts))
    );
  }

  setFilters(selected) {
    const keys = Object.keys(this.options) || [];
    this.activeFilters = chain(keys)
      .map(key => {
        return this.options[key].activeFilters || [];
      })
      .flatten()
      .value();
  }

  diplayConfirmReset(template) {
    const modalRef = this.modalService.open(template, { centered: true, backdrop: 'static' });
    modalRef.result
      .then(res => {
        if (res)
          this.resetFilters();
      })
      .catch(e => { });
  }

  applyFilters() {
    this.asideService.searchValue.next(this.activeFilters);
  }

  private generateFileName(payload) {
    const start = payload['start_date'] ? payload['start_date'] : moment.utc().startOf('month').format('YYYY-MM-DD');
    const end = payload['end_date'] ? payload['end_date'] : moment.utc().endOf('month').format('YYYY-MM-DD');
    let country = payload['country'] || '';

    if (country) {
      const countryFields = country.split(',');
      country = countryFields.length > 3 ? 'otros' : countryFields.join('_');
    }
    const name = `report_${start}_${end}_${country}.xlsx`;

    return name;
  }

  async downloadReport() {
    const payload = this.createSearchPayload(this.activeFilters);
    this.loadingReport = true;
    const report: any = await this.api.downloadReport({
      ...payload,
      ...this.asideService.sorting,
    });
    this.loadingReport = false;
    const blob = window.URL.createObjectURL(new Blob([report.res], {
      type: report['type']
    }));

    const name = this.generateFileName(payload);

    // const blob = new Blob([report]);
    const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    link.href = blob;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private handleSearchOptions(options) {
    this.options = options;
  }

  private resetFilters() {
    this.activeFilters.length = 0;
    const keys = Object.keys(this.options) || [];
    keys.map(key => {

      if (this.options[key].activeFilters)
        this.options[key].activeFilters.length = 0;

      this.options[key].filters.forEach(element => {
        element.active = false;
      });
      return this.options[key];
    });

    this.asideService.searchValue.next([]);
  }

  private createSearchPayload(items) {
    const payload = {};
    items.forEach((item) => {

      if (item.value['value'] && isObject(item.value['value'])) {
        // payload[item.key] =
        const keys = Object.keys(item.value['value']);
        keys.forEach(key => {
          payload[key] = item.value['value'][key];
        });
      } else if (item.value['value'])
        payload[item.key] = item.value['value'];
      else
        payload[item.key] = item.value || '';

    });

    return payload;
  }
}
