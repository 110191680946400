import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorTypesComponent implements OnInit {

  @Input() disabled: Boolean = false;
  @Input() infinite: Boolean = false;
  @Input() totalLabel: String = 'Total';
  @Input() totalBooksLabel: String = 'Total Libros';
  @Input() totalGiftBoxesLabel: String = 'Total Cajas Regalo';
  @Input() paging: any = {
    limit: 10,
    page: 1,
    total_items: 0,
    total_pages: 1,
  };
  @Output() pageUpdated: Subject<any> = new Subject<any>();

  constructor() { }

  ngOnInit() {
  }

  pageChanged(page) {
    this.pageUpdated.next({ page });
  }

}
