import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class IncidentsRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  getIncidents(payload = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents`;
    return this.http.get(url, payload);
  }

  getSolutions(params = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/solutions`;
    return this.http.get(url, params);
  }

  getIncident(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/${id}`;
    return this.http.get(url);
  }

  updateIncident(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/${id}`;
    return this.http.put(url, payload);
  }

  removeIncident(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/${id}`;
    return this.http.delete(url);
  }

  getOverview() {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/overview`;
    return this.http.get(url);
  }

  createIncident(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents`;
    return this.http.post(url, payload);
  }

  createObservation(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/${id}/observations`;
    return this.http.post(url, payload);
  }

  closeIncident(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/${id}/close`;
    return this.http.post(url, payload);
  }

  getObservations(id, params = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/observations/incident/${id}`;
    return this.http.get(url, params);
  }

  /*** INCIDENTS MODULE */

  /**
   * GET a list of solutions
   * @param payload: Object<any>
   */
  solutions(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/solutions`;
    return this.http.get(url, queryParams);
  }

  getSolution(id, params = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/solutions/${id}`;
    return this.http.get(url, params);
  }

  createSolution(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/solutions/`;
    return this.http.post(url, payload);
  }

  updateSolution(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/solutions/${id}`;
    return this.http.put(url, payload);
  }

  /**
   * GET a list of reasons
   * @param payload: Object<any>
   */
  reasons(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/reasons`;
    return this.http.get(url, queryParams);
  }

  getReason(id, params = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/reasons/${id}`;
    return this.http.get(url, params);
  }

  createReason(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/reasons/`;
    return this.http.post(url, payload);
  }

  updateReason(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/reasons/${id}`;
    return this.http.put(url, payload);
  }

  /**
   * GET a list of reasons types
   * @param payload: Object<any>
   */
  listTypes(params = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/reasons/types`;
    return this.http.get(url, params);
  }

  getReasonType(id, params = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/reasons/types/${id}`;
    return this.http.get(url, params);
  }

  createReasonType(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/reasons/types/`;
    return this.http.post(url, payload);
  }

  updateReasonType(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/incidents/reasons/types/${id}`;
    return this.http.put(url, payload);
  }

  /*****/

  async downloadReport(queryParams = {}) {
    const qs = new URLSearchParams(queryParams);
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/reports/generate/incidents?${qs}`;

    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            const type = xhr.getResponseHeader('Content-Type');
            resolve({
              res: xhr.response,
              type,
            });
          }
          else {
            reject(JSON.parse(xhr.response));
          }
        }
      };

      xhr.open('GET', url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('userToken')}`);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }
}
