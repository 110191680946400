import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class UserRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * Return logged user profile
   */
  me() {
    const url = `${this.config.baseUrl}/api/v1/${this.config.services.users}/me`;
    return this.http.get(url);
  }
}
