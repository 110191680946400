import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { FormArray, FormGroup } from '@angular/forms';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  /**
 * Display toastr error
 * @param error Custom Error Object
 * @param toastrOptions Overwrite default toastr options
 */
  errorMessage(error: any, toastrOptions?: IndividualConfig) {
    const message = get(error, 'error.error.userMessage') ||
      get(error, 'userMessage') ||
      get(error, 'message') ||
      'An error has happend';

    this.toastr.error(message, 'Error', toastrOptions);
  }

  /**
  * Show warning toastr
  * @param message warning message
  * @param title  warning title
  * @param toastrOptions Overwrite default toastr options
  */
  warningMessage(message: string, title?: string, toastrOptions?: IndividualConfig) {
    return this.toastr.error(message, title ? title : '', toastrOptions);
  }

  /**
  * Show success toastr
  * @param message success message
  * @param title  success title
  * @param toastrOptions Overwrite default toastr options
  */
  successMessage(message: string, title?: string, toastrOptions?: IndividualConfig) {
    return this.toastr.success(message, title ? title : '', toastrOptions);
  }

  /**
   * Generate query string for HTTP get requests
   * @param payload options
   */
  queryOptions(payload: any) {
    let params = '';
    const keys = Object.keys(payload);
    // concat all parameters
    keys.forEach(key => {
      if (!params.length) {
        params = `?${key}=${payload[key]}`;
      } else {
        params += `&${key}=${payload[key]}`;
      }
    });

    return params;
  }

  /**
   * Mark form as valid
   * @param form FormGroup Instance
   */
  setformAsValid(form: FormGroup) {
    const keys = Object.keys(form.controls);
    keys.forEach(key => {
      if (form.controls[key]) {
        form.controls[key].markAsTouched();
        form.controls[key].setErrors(null);
      }
    });
  }

  /**
   * Mark input fields with error
   * @param form FormGroup Instance
   * @param valid Optional forced value
   */
  setFormError(form: FormGroup, valid?: Boolean) {
    const keys = Object.keys(form.controls);

    const childFormKey = keys.find(key => form.controls[key] instanceof FormGroup || form.controls[key] instanceof FormArray);

    keys.forEach(key => {
      if (form.controls[key]) {
        if (form.controls[key].errors) {
          console.log('FORM KEY INVALID - ', key, form.controls[key].errors);
          form.controls[key].markAsTouched();
          form.controls[key].setErrors({ valid: valid || false, submitted: true });
        }
      }
    });

    if (childFormKey && form.controls[childFormKey] instanceof FormGroup) {
      const childKeys = Object.keys(form.controls[childFormKey]['controls']);
      const childForm: any = form.controls[childFormKey];

      childKeys.forEach((key, i) => {
        if (childForm.controls[key] && !childForm.controls[key].valid) {
          console.log('CHILD FORM KEY INVALID - ', key, childForm.controls[key].errors);
          childForm.controls[key].markAsTouched();
          childForm.controls[key].setErrors({
            valid: valid || false,
            submitted: true,
          });
        }
      });
    }

    if (childFormKey && form.controls[childFormKey] instanceof FormArray) {
      const childKeys = Object.keys(form.controls[childFormKey]['controls']);
      const childForm: any = form.controls[childFormKey];

      const childArrayKeys = Object.keys(childForm.controls[0]['controls']);

      childForm.controls.forEach((element, i) => {
        const childForm: any = element;
        childArrayKeys.forEach((key, j) => {
          if (childForm.controls[key] && !childForm.controls[key].valid) {
            console.log('CHILD FORM KEY INVALID - ', key, childForm.controls[key].errors);
            childForm.controls[key].markAsTouched();
            childForm.controls[key].setErrors({
              valid: valid || false,
              submitted: true,
            });
          }
        });
      });
    }
  }

  /**
   * Mark form as invalid and added aditional message if have one
   * @param form FormGroup instance
   * @param messages Optional message data
   */
  displayFormErrors(form: FormGroup, messages: Array<any> = []) {
    const keys = Object.keys(form.controls);
    const childFormKey = keys.find(key => form.controls[key] instanceof FormGroup);

    keys.forEach((key, i) => {
      const message = messages[i];
      if (form.controls[key] && !form.controls[key].valid) {
        form.controls[key].markAsTouched();
        form.controls[key].setErrors({
          valid: false,
          submitted: true,
          message: message
        });
      }
    });

    if (childFormKey) {
      const childKeys = Object.keys(form.controls[childFormKey]['controls']);
      const childForm: any = form.controls[childFormKey];

      childKeys.forEach((key, i) => {
        if (childForm.controls[key] && !childForm.controls[key].valid) {
          const message = messages[i];
          childForm.controls[key].markAsTouched();
          childForm.controls[key].setErrors({
            valid: false,
            submitted: true,
            message: message
          });
        }
      });
    }
  }

  /**
 * Get a langs regex to validate browser lang
 */
  getLangRegex() {
    const langs = environment.langs ? environment.langs.join('|') : environment.defaults.lang;
    const regex = new RegExp(langs);
    return regex;
  }

  /**
   * get lang from browser
   */
  getLang() {
    return localStorage.getItem('micuento-panel-lang') || 'en';
  }

  /**
   * set lang from browser
   */
  setLang(lang?) {
    return localStorage.setItem('micuento-panel-lang', lang || 'en');
  }

  /**
  * Initialize translate service
  * by getting the language from browser settings
  */
  bootstrapLang() {
    // specify app languages
    this.translate.addLangs(environment.langs || []);
    // get browser languages
    let browserLang = this.translate.getBrowserLang();
    browserLang = browserLang.match(this.getLangRegex()) ? browserLang : environment.defaults.lang;

    // check in session storage for user lang if not exists use browserlang
    const userLang = localStorage.getItem('micuento-panel-lang') || browserLang;

    // if do not exists on session storage, set browser lang
    if (!localStorage.getItem('micuento-panel-lang')) localStorage.setItem('micuento-panel-lang', browserLang);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(environment.defaults.lang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(userLang);
  }

  /**
   * Converts a string into its slug form
   * @param str string to be slugified
   */
  getSlug(str: string) {
    let slug = str.toLowerCase();
    const from = 'àáäâèéëêìíïîòóöôùúüûñç';
    const to = 'aaaaeeeeiiiioooouuuunc';
    for (let i = 0; i < from.length; i++) {
      slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    slug = slug.replace(/[^a-z0-9 -]/g, '-') // replace invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return slug;
  }
}
