import { config } from './config';

const _env = {
  production: false,
  baseUrl: 'https://next-api.micuento.com',
  basePdfUrl: undefined,
  basePdfUrlPrefix: undefined,
  s3Bucket: 'https://micuento-web.s3.amazonaws.com',
};

export const environment = { ..._env, ...config() };
