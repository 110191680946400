import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent {
  @ContentChild('', { static: false }) template: TemplateRef<any>;
  @Input() change;
  @Input() name;
  fileChange = ($event) => this.change(this.name, $event);
}
