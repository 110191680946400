// Declare all modals that will going to be used as shared filters

import { StockItemModal } from "./stock-item/stock-item.component";
import { StockResourceModal } from "./stock-resource/stock-resource.component";
import { StockTransactionModal } from "./stock-transaction/stock-transaction.component";

// this declaration could be found in the shared app module
export function StockModals() {
  return [
    StockItemModal,
    StockResourceModal,
    StockTransactionModal
  ];
}
