// Declare all modals that will going to be used as shared filters

import { ProductModals } from './product-modals';
import { CategoryModals } from './categories-modals';
import { CMSModals } from './cms-modals';
import { AffiliatesModals } from './affiliates-modals';
import { GeneralModal } from './general-modal.component';
import { CommonModals } from './general-modals';
import { StockModals } from './stock-modals/index.declarations';
import { OrderModals } from './order-modals/index.declarations';

// this declaration could be found in the shared app module
export function GeneralModals() {
  return [
    GeneralModal,
    CommonModals(),
    StockModals(),
    CMSModals(),
    AffiliatesModals(),
    ProductModals(),
    CategoryModals(),
    OrderModals(),
  ];
}
