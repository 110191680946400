import { NgModule } from '@angular/core';
import { AuthGuard, AnonymousGuard } from './guards';
import { Routes, RouterModule } from '@angular/router';
import { PermissionsGuard } from '@guards/permissions.guard';
import { FullComponent, BlankComponent } from '@shared/components';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'tickets',
        loadChildren: './modules/tickets/tickets.module#TicketsModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'packs',
        loadChildren: './modules/packs/packs.module#PacksModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'orders/partners',
        loadChildren: '@modules/orders/partner-orders.module#PartnerOrdersModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'orders',
        loadChildren: '@modules/orders/orders.module#OrdersModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'examples',
        loadChildren: './modules/examples/examples.module#ExamplesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'extras',
        loadChildren: './modules/extras/extra.module#ExtraModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'ecom',
        loadChildren: './modules/ecommerce/ecom.module#EcomModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'stocks',
        loadChildren: './modules/stocks/stocks.module#StocksModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'reports',
        loadChildren: './modules/reports/reports.module#ReportsModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'cms',
        loadChildren: './modules/cms/cms.module#CMSModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'incidents',
        loadChildren: './modules/incidents/incidents.module#IncidentModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'notifications',
        loadChildren: './modules/notifications/notifications.module#NotificationsModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'promotions',
        loadChildren: './modules/promotions/promotions.module#PromotionsModule',
        // canActivate: [PermissionsGuard]
      },
      {
        path: 'settings',
        loadChildren: './modules/settings/settings.module#SettingsModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'affiliates',
        loadChildren: './modules/affiliates/affiliates.module#AffiliatesModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'users',
        loadChildren: './modules/users/users.module#UsersModule',
        canActivate: [PermissionsGuard]
      },
      {
        path: 'news',
        loadChildren: './modules/newsletter/newsletter.module#NewsletterModule',
        canActivate: [PermissionsGuard]
      }
    ]
  },
  {
    path: 'live-preview',
    loadChildren: './modules/live-preview/live-preview.module#LivePreviewModule',
    canActivate: [PermissionsGuard]
  },
  {
    path: '',
    component: BlankComponent,
    canActivate: [AnonymousGuard],
    children: [
      {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: './modules/authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/auth/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
