import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-join-order-modal',
  templateUrl: './join-order.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class JoinOrderModal extends AbstractModal {
  getName() {
    return 'Unir pedidos';
  }
  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      uid: [ { value: data.uid, disabled: false }, ]
    });
  }
}
