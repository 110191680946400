export const PRODUCT_TYPES = {
  UPSELLS: 'upsells',
  BOOK: 'book',
  AUDIO_BOOK: 'audio-book',
};
export const UPSELL_TYPES = {
  AUDIO_BOOK: 'audio-book',
  GIFT_BOX: 'gift-box',
  COLORING_BOOK: 'coloring-book',
  MERCHANDISING: 'merchandising',
  POSTCARD: 'postcard',
  MAGAZINE: 'magazine',
};

export const VARIANT_TYPES = {
  ORIGINAL: 'original',
  DYSLEXIA: 'dyslexia'
};

export const STOCK_TRANSACTION_TYPES = {
  RESTOCK: 'restock',
  PACK: 'pack'
};

export const INCIDENT_TYPES = {
  REASON: 'reason',
  TYPE: 'reason_type',
  SOLUTION: 'solution'
};

export const CMS_ELEMENT_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  LINK: 'link'
};

export const CMS_ENTRY_TYPES = [
  'carousel',
  'title',
  'banner',
  'upsell'
];

export const CMS_ENTRY_PAGES = [
  'home',
  'audiobooks',
  'our_books',
  'product',
  'preview',
  'shopping_cart',
  'checkout',
  'modal',
  'header',
];

export const CMS_ELEMENT_ATTRS = [
  'className',
  'tag',
  'alt',
  'description',
];

export const STATUSES = [
  'active',
  'inactive',
];

export const CMS_ELEMENT_STYLES = [
  'color',
  'backgroundColor',
  'border',
  'borderTop',
  'borderBottom',
  'borderRadius',
  'fontSize',
  'fontWeight',
  'fontFamily',
  'padding',
  'margin',
  'position',
  'top',
  'left',
  'right',
  'bottom',
  'display',
  'flexDirection',
  'justifyContent',
  'alignItems',
  'maxWidth',
  'textAlign',
  'width',
  'height',
];

export const CANARY_PROVINCES = ['las palmas', 'santa cruz de tenerife', 'santa cruz de tenerife, canarias', 'canarias'];

export const ORDER_ERRORS = {
  PAGES: 'Error en el número de páginas',
  PDF: 'Error en el PDF',
  AUDIO: 'Solo contiene audiolibros',
  ADDRESS_EMPTY: 'Sin dirección',
  PHONE_NUMBER: 'Error en el número de teléfono',
  ADDRESS: 'Error en la dirección',
  UPSELL: 'Nuevo upsell',
  PUZZLE: 'Puzzle sin PDF',
  ZIPCODE: 'Error en el código postal'
};

export const COVER_NAMES = {
  'ingram': 'Ingram, AG',
  'schaetzl': 'Schaetzl, CP',
  'moti': 'Moti',
  'hpc': 'Hpc',
  'base': 'Base',
  'base_soft': 'SOFT - Base',
  'centered': 'Centered',
  'centered_soft': 'SOFT - Centered',
  'barcoded_base': 'Base - Barcode',
  'barcoded_base_soft': 'SOFT - Base - Barcode',
  'barcoded_centered': 'Centered - Barcode',
  'barcoded_centered_soft': 'SOFT - Centered - Barcode',
};


export const ORDER_FIELDS = [
  'id',
  'uid',
  'user_uid',
  'status',
  'payment_method',
  'shipping_method',
  'amount',
  'subtotal',
  'tax_due',
  'first_name',
  'last_name',
  'email',
  'confirm_email',
  'phone_number',
  'country',
  'pack_id',
  'address_line',
  'address_line_2',
  'city',
  'state',
  'zip_code',
  'province',
  'currency',
  'currency_symbol',
  'language',
  'payment',
  'discount',
  'discount_value',
  'shipping',
  'terms',
  'subscription',
  'paid_at',
  'reseted',
  'reseted_products_count',
  'delivery_courier',
  'delivery_courier_tracking',
  'updated_at',
  'shipping_type',
  'delivery_courier_status',
  'location',
  'original_order_id',
  'recipient_full_name',
  'recipient',
  'reprinted',
  'extra_data',
  'payment_type',
  'source',
  'ip_address',
  'user_agent',
];

export const ORDERITEM_FIELDS = [
  'id',
  'uid',
  'order_id',
  'order_uid',
  'product_id',
  'product_uid',
  'product_type',
  'product_slug',
  'product',
  'quantity',
  'attributes',
  'language',
  'cover_url',
  'content_url',
  'sku',
  'status',
  'order_cart',
  'variant',
  'related_to',
  'price',
  'content_type',
  
];

export const PACK_FIELDS = [
  'id',
  'uid',
  'printing_order',
  'trp',
  'country',
  'printing_cost',
  'printer',
  'printer_id',
  'shipping_cost',
  'boxes_count',
  'orders_count',
  'products_count',
  'shipping_type',
  'printing_type',
  'delivery_address',
  'delivery_courier',
  'courier_link',
  'status',
  'courier_sent',
];
