import { JsonViewerModal } from './json-viewer/json-viewer.component';
import { HtmlViewerModal } from './html-viewer/html-viewer.component';
import { SimpleFormModal } from './simple-form/simple-form.component';
import { BatchCreateModal } from './batch-create/batch-create.component';


// this declaration could be found in the shared app module
export function CommonModals() {
  return [
    JsonViewerModal,
    SimpleFormModal,
    HtmlViewerModal,
    BatchCreateModal,
  ];
}
