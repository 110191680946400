import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiRequestsService } from '@api/api.gateway';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { HelpersService } from '@shared/services';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
})
export class FormBuilderComponent extends AbstractComponent {
  @Input() data = {};
  @Input() formData = [];
  form;

  constructor(
    private formBuilder: FormBuilder,
    private helpers: HelpersService,
    private api: ApiRequestsService,
  ) {
    super();
  }

  ngOnInit() {
    this.boostrapForm();
  }

  async boostrapForm() {
    const formPayload = {};
    for (const elem of (this.formData || [])) {
      formPayload[elem.name] = [{ value: this.data[elem.value] || elem.value || this.data[elem.name], disabled: false }, elem.required ? Validators.required : null];
      switch (elem.name) {
        case 'printer': 
          elem.options = await this.getPrinters();
          break;
      }
    }
    
    this.form = this.formBuilder.group(formPayload);

  }

  /**
   * GET list of printers
   * @param options
   */
  private async getPrinters(options = {}) {
    const queryParams = {
      page: 1,
      limit: 100,
      status: 'active',
      sort: 'sort_order',
      ...options,
    };

    try {
      const orders = await this.api.orders.printers(queryParams).toPromise();
      return orders['data'] || [];
    } catch (error) {
      this.helpers.errorMessage(error);
    }
  }
}
