import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { pick } from 'lodash';

@Component({
  selector: 'app-tag-search',
  templateUrl: './tag-search.component.html',
  styleUrls: ['./tag-search.component.scss']
})
export class TagSearchComponent extends AbstractComponent {
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() options: any[];
  @Input() placeholder: string;
  @Input() values: string;
  @Input() accessor;
  @Input() join = undefined;
  @ViewChild('tagRef', { static: false }) tagRef: ElementRef;
  searchValue = '';
  show = false;
  filtered = [];
  tags = [];

  @HostListener('document:click', ['$event'])
  onClickOutside( event ) {
    const clickedInside = this.tagRef.nativeElement.contains(event.target);
    if (!clickedInside) this.show = false;
  }

  ngOnChanges(changes) {
    this.filtered = [ ...this.options ];
    this.tags = [];
    const value = this.form.get(this.name).value;
    let key = 'id';
    if (this.values) {
      const values = this.values.split(',');
      if (values.length === 1) key = values[0];
    }

    for (const option of this.options) {
      if (value && value.includes(option[key])) this.tags.push(option);
    }
    this.filter('');
  }

  getName(option: any) {
    const accesors = (this.accessor || 'first_name').split(',');
    return accesors.map(accesor => option[accesor]).join(' - ');
  }

  filter(search) {
    search = search.toLowerCase();
    this.filtered = this.options.filter((option) => {
      const name = this.getName(option).toLowerCase();
      return name.includes(search)
        && !this.tags.map((tag) => tag.id).includes(option.id);
    });
  }

  onEnter() {
    if (this.filtered.length) {
      this.addTag(0);
      this.searchValue = '';
    }
  }

  onDelete(event) {
    if (!event.target.value) {
      this.tags.pop();
      this.updateForm();
    }
  }

  removeTag(index) {
    this.tags.splice(index, 1);
    this.updateForm();
  }

  addTag(index) {
    const element = this.filtered[index];
    this.filtered.splice(index, 1);
    this.tags.push(element);
    this.updateForm();
  }

  updateForm() {
    let patchValue;
    if (this.values) {
      const values = this.values.split(',');
      if (values.length > 1) {
        patchValue = this.tags.map((option) => pick(option, values));
      } else {
        patchValue = this.tags.map((option) => option[values[0]]);
      }
    } else {
      patchValue = this.tags.map((option) => option.id) ;
    }
    this.form.patchValue({ [this.name]: this.join !== undefined ? patchValue.join(this.join) : patchValue});
    this.form.get(this.name).markAsDirty();
  }

}
