import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '../../abstract/abstract.component';

@Component({
  selector: '[app-mc-header]',
  templateUrl: './mc-header.component.html',
  styleUrls: ['./mc-header.component.scss'],
})
export class McHeaderComponent extends AbstractComponent implements OnInit  {
  @Input() headers: string[];
  @Input() showStatus = true;
  @Output() sort = new EventEmitter<any>();

  private currentSorting: any = {};

  ngOnInit() {}

  onSort(header: string | { label: string, sort: string }) {
    if (header === 'status' || (typeof header !== 'string' && header.sort)) {
      const key = typeof header !== 'string' ? header.sort : header;
      const sorting = this.currentSorting[key];
      this.currentSorting = {};
      const method = this.toggleSorting(sorting);
      this.currentSorting[key] = method;
      this.sort.emit({ sort: `${method === 'desc' ? '-' : ''}${key}` });
    }
  }

  private toggleSorting(sort: 'asc' | 'desc' | undefined) {
    return sort === 'desc' ? 'asc' : 'desc';
  }
}
