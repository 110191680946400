import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class PromotionsRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) { }

  list(queryParams = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/promotions`;
    return this.http.get(url, queryParams);
  }

  getById(id, queryParams = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/promotions/${id}`;
    return this.http.get(url, queryParams);
  }

  create(payload = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/promotions`;
    return this.http.post(url, payload);
  }

  update(id, payload = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/promotions/${id}`;
    return this.http.put(url, payload);
  }

  removeVariant(id) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/promotions/variant/${id}`;
    return this.http.delete(url);
  }

  bulkCreate(id, body) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/promotions/${id}/bulk`;
    return this.http.post(url, body);
  }

  lookup(codes) {
    const url = `${this.config.baseUrl}/${this.config.services.core}/v1/promotions/lookup`;
    return this.http.post(url, { codes });
  }

}
