import { Component, Input } from '@angular/core';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';

@Component({
  selector: 'app-help-info',
  templateUrl: './help-info.component.html',
  styleUrls: ['./help-info.component.scss']
})
export class HelpInfoComponent extends AbstractComponent {
  @Input() title = '';
  @Input() text = '';

  ngOnInit() {
  }

}
