import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-audio-book-name-modal',
  templateUrl: './audio-book-name.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class AudioBookNameModal extends AbstractModal {
  names = [];
  genres = [{ code: 'boy', name: 'Niño'} , { code: 'girl', name: 'Niña'}];

  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      character: [ { value: data.character || '', disabled: false }, Validators.required],
      genre: [ { value: data.genre, disabled: false }, Validators.required],
      path: [ { value: data.path, disabled: false }, Validators.required],
      available: [ { value: data.available || false, disabled: false }, Validators.required],
      status: [ { value: data.status || 'inactive', disabled: false }, Validators.required],
    });
  }

  getName() {
    return 'Nombres Audio Libro';
  }
}
