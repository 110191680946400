import { Component } from '@angular/core';
import { ScraperRequests } from '@api/scraper/scraper.requests';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-searching-affiliate-modal',
  templateUrl: './search-affiliate.component.html',
  styleUrls: ['../../general-modal.component.scss', './search-affiliate.component.scss']
})
export class SearchingAffiliateModal extends AbstractModal {
  private data;
  total;
  keywords = [];
  processed = 0;
  errorResponses = [];

  getName() { return 'Keywords'; }

  constructor(
    private api: ScraperRequests,
  ) {
    super();
  }

  get progress() {
    return `${this.processed / this.total * 100}%`;
  }

  ngOnInit(): void {
    this.data = this.properties.data;
    this.keywords = this.data.keyword.split(',');
    this.total = this.keywords.length;
    this.start();
  }

  finish() {
    location.reload();
  }

  private async start() {
    for (const keyword of this.keywords) {
      try {
        await this.api.search({ ...this.data, keyword: keyword.trim() }).toPromise();
      } catch (err) {
        this.errorResponses.unshift(err);
      }
      this.processed += 1;
    }
  }

  bootstrapForm(data: any = {}) {}
}
