import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent {
  @ContentChild('', { static: false }) template: TemplateRef<any>;
  @Input() change;
  @Input() image;
  @Input() readOnly = false;
}
