import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-payments-types',
  templateUrl: './payments-types.component.html',
  styleUrls: ['./payments-types.component.scss']
})
export class PaymentTypesComponent implements OnInit {

  @Input() type: String = 'credit-card';

  constructor() { }

  ngOnInit() {
  }

}
