import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectModalComponent } from '../selection/selection.component';

const data = [
  { description: 'Caja Regalo', name: 'Caja Regalo', slug: 'gift-box' },
  { description: 'Audio Libros', name: 'Audio Libros', slug: 'audio-book' },
  { description: 'Libros', name: 'Libros', slug: 'book' },
  { description: 'Cartas', name: 'Cartas', slug: 'flyer' },
  { description: 'Stickers', name: 'Stickers', slug: 'sticker' },
  { description: 'Libretas', name: 'Libretas', slug: 'notebook' },
  { description: 'Para colorear', name: 'Para colorear', slug: 'coloring-book' },
  { description: 'Postales', name: 'Postales', slug: 'postcard' },
]

@Component({
  selector: 'advance-search-type-modal',
  templateUrl: '../selection/selection.component.html',
  styleUrls: ['../selection/selection.component.scss']
})
export class TypeSelectionComponent extends SelectModalComponent {
  constructor(
    public activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

  /**
   * Sets the endpoint to check for the data
   * @param params Query params from the select component
   */
  async getData(params: any) {
    return { data } as any;
  }
}
