import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CMS_ENTRY_TYPES, CMS_ENTRY_PAGES } from '@shared/global.types';
import { AbstractModal } from '../../abstract-modal.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-cms-entry-modal',
  templateUrl: './cms-entry.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class CMSEntryModal extends AbstractModal {

  types = CMS_ENTRY_TYPES.map(val => {
    return {
      name: this.translate.instant(`cms.${val}`),
      code: val,
    };
  });

  pages = CMS_ENTRY_PAGES.map(val => {
    return {
      name: this.translate.instant(`cms.${val}`),
      code: val,
    };
  });

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngAfterViewInit() {
    const section = this.form.get('section');
    this.subscriptions.push(
      section.valueChanges.subscribe(val => {
        const slug = this.helpers.getSlug(val);
        if (section.value !== slug) {
          section.setValue(slug);
        }
      })
    );
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      section: [ { value: data.section || '', disabled: this.properties.update }, Validators.required],
      type: [ { value: data.type || 1, disabled: false }, Validators.required],
      page: [ { value: data.page || '', disabled: false }, Validators.required],
      status: [ { value: data.status || '', disabled: false }, Validators.required],
    });
  }

  getName() {
    return 'Entrada';
  }
}
