import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { IncidentsRequests } from '@api/incidents/incidents.requests';

@Component({
  selector: 'app-search-reason-modal',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss']
})
export class ReasonModalComponent extends AbstractComponent {

  properties: any = {};

  reasons: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private incidents: IncidentsRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listStatuses();
  }

  confirm() {
    const activeReasons = this.reasons.map(method => method.active ? method.id : false).filter(e => e);
    this.activeModal.close({ value: activeReasons.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.reasons.forEach(method => method.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(method) {
    const index = this.reasons.findIndex(el => el['id'] === method['id']);
    if (index === -1)
      return;

    this.reasons[index]['active'] = !this.reasons[index]['active'];
  }

  private async listStatuses() {
    try {
      const queryParams = {
        limit: 300
      };
      const reasons = await this.incidents.reasons(queryParams).toPromise();
      this.reasons = reasons['data'] || [];

      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(id => {
      const index = this.reasons.findIndex(i => i.id.toLowerCase() === id.toLowerCase());
      if (index !== -1)
        this.reasons[index]['active'] = true;
    });

  }
}
