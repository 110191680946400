import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class AuthRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * Login in the application with given credentials
   * @param payload: Object<any>
   */
  login(payload) {
    const url = `${this.config.baseUrl}/api/v1/${this.config.services.users}/login`;
    return this.http.post(url, payload);
  }

  /**
   * Sign up in the application with given credentials
   * @param payload: Object<any>
   */
  signup(payload) {
    const url = `${this.config.baseUrl}/api/v1/${this.config.services.users}`;
    return this.http.post(url, payload);
  }

  /**
   * Recover password endpoint
   * @param payload Object<any>
   */
  forgotPassword(payload) {
    const url = `${this.config.baseUrl}/api/v1/${this.config.services.users}/forgot-password`;
    return this.http.post(url, payload);
  }
}
