import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';

@Injectable()
export class QuestionnairesRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * GET a questionnaire questions
   * @param queryParams
   */
  getQuestions(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.questionnaires}/v1/questions`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a questionnaire options
   * @param queryParams
   */
  getOptions(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.questionnaires}/v1/questions/options`;
    return this.http.get(url, queryParams);
  }

  /**
   * PUT update questionnaire
   * @param data
   */
  updateQuestions(data?) {
    const url = `${this.config.baseUrl}/${this.config.services.questionnaires}/v1/questions/update/bulk`;
    return this.http.put(url, data);
  }

  /**
   * GET a questionnaire answers
   * @param queryParams
   */
  getAnswers(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.questionnaires}/v1/questions/answers`;
    return this.http.get(url, queryParams);
  }

  /**
   * PUT update answers
   * @param data
   */
  updateAnswers(data?) {
    const url = `${this.config.baseUrl}/${this.config.services.questionnaires}/v1/options/update/bulk`;
    return this.http.put(url, data);
  }

}
