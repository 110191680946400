import { Component, OnInit, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { AsideService } from '@shared/services/aside.service';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '../abstract/abstract.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-aside',
  templateUrl: './custom-aside.component.html',
  styleUrls: ['./custom-aside.component.scss']
})
export class CustomAsideComponent extends AbstractComponent implements OnInit {

  @ViewChild('tabs', { static: true }) tabs: NgbTabset;

  tabStatus = 'justified';
  selected: String = '1';
  isCollapsed = false;
  innerWidth: any;
  defaultSidebar: any;
  showSettings = false;
  showMobileMenu = false;
  expandLogo = false;
  showSearch$: Observable<any> = this.asideService.searchDisplay;

  config = {};
  first = false;
  options = {
    sidebartype: 'full' // four possible values: full, iconbar, overlay, mini-sidebar
  };

  constructor(
    private asideService: AsideService
  ) {
    super();
  }

  ngOnInit() {
    this.defaultSidebar = this.options.sidebartype;
    this.subscriptions.push(this.asideService.panelClose.subscribe(() => {
      this.tabs.select('1');
      if (this.first)
        this.showSettings = false;
    }));
    this.subscriptions.push(this.asideService.panelOpen.subscribe(() => {
      this.tabs.select('1');
      this.first = true;
      this.showSettings = true;
    }));
  }

  ngAfterViewInit() {
    this.tabs.select('1');
  }

  /**
   * Listen click event when panel is opened
   * if user clicks / touch in the backdrop
   * this will automatically be closed
   * @param event DOM Event
   */
  @HostListener('click', ['$event'])
  onClick(event: any) {
    const inBackdrop = event.target.getAttribute('id');
    if (inBackdrop === 'panel-backdrop') {
      this.showSettings = false;
      this.asideService.closeSidePanel();
      setTimeout(() => {
        this.tabs.select('1');
      }, 500);
    }
    return;
  }

}
