export * from './textarea/textarea.component';
export * from './datepicker/datepicker.component';
export * from './countries/countries.component';
export * from './currencies/currencies.component';
export * from './statuses/statuses.component';
export * from './statuses-incidents/statuses.component';
export * from './statuses-affiliates/statuses.component';
export * from './sources-affiliates/sources.component';
export * from './adsense-affiliates/adsense.component';
export * from './statuses-packs/statuses.component';
export * from './pay-methods/pay-methods.component';
export * from './users/users.component';
export * from './solution/solution.component';
export * from './reason-type/reason-type.component';
export * from './reason/reason.component';
export * from './books/books.component';
export * from './langs/langs.component';
export * from './versions/versions.component';
export * from './selection/selection.component';
export * from './printers/printers.component';
export * from './delivery-couriers/delivery-couriers.component';
export * from './partner-source/partner-source.component';
export * from './type-selection/type-selection.component';
export * from './shipping_method/shipping_method.component';
export * from './content_type/content_type.component';
export * from './domain-affiliates/domain.component';

// main declarations
export * from './index.declarations';
