import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { HelpersService } from '@shared/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'generate-invoice-modal',
  templateUrl: './generate-invoice-modal.component.html',
  styleUrls: ['./generate-invoice-modal.component.scss']
})
export class GenerateInvoiceModal extends AbstractComponent {

  properties: any = {};
  orderForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private helpers: HelpersService
  ) {
    super();
  }

  ngOnInit() {
    this.bootstrapForm(this.properties.data || {});
  }

  /**
   * Handle form submission
   * @param formLogin form data
   */
  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      this.helpers.displayFormErrors(this.orderForm);
      return;
    }
    this.helpers.setformAsValid(this.orderForm);
    this.activeModal.close({ payload: value });

  }

  addRecipientName() {
    const recipient = this.properties.data.recipient;
    this.orderForm.patchValue({
      shipping_name: `${recipient.first_name} ${recipient.last_name || ''}`,
    });
  }

  close() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  private bootstrapForm(data: any = {}) {
    this.orderForm = this.formBuilder.group({
      shipping_name: [ `${data.first_name || ''} ${data.last_name || ''}` || '' ],
      email: [ data.email || '' ],
      city: [ data.city || '' ],
      address_line: [ data.address_line || '' ],
      address_line_2: [ data.address_line_2 || '' ],
      zip_code: [ data.zip_code || '' ],
      province: [ data.province || '' ],
      cif: [ '' ],
      rfc: [ '' ],
      nif: [ '' ],
    });
  }

}
