import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';

import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { isPlainObject, omit } from 'lodash';

import { environment } from '@env/environment';

import { HelpersService } from '@shared/services/helpers.service';
import { AuthService } from '@shared/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private config = environment;
  private queryRegex = new RegExp(/([^\?]+)(\?.*)?/i);

  constructor(private authService: AuthService) { }

  /**
   * Catch all request
   * @param req HttpRequest
   * @param next HttpHandler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.setHehaders(req);
    return next.handle(req);
  }

  /**
   * Set request headers
   * @param req HttpRequest
   */
  private setHehaders(req: HttpRequest<any>) {
    const url = req.url;
    const _currentLang = localStorage.getItem('lang') || this.config.defaults.lang;
    const _lang = _currentLang === 'es' ? 'es-Es' : _currentLang === 'en' ? 'en-En' : 'es-Es';
    let _contentType = 'application/json';

    // If request already have a defined content type header
    // overwrite the default one with the provided header
    if (req.headers.has('Content-Type')) {
      _contentType = req.headers.get('Content-Type');
    }

    const headers = {
      'Accept-Language': _lang,
      'Content-Type': _contentType,
    };

    // TODO enable token auth
    if (this.authService.token) {
      headers['Authorization'] = `Bearer ${this.authService.token}`;
    }


    req = req.clone({
      setHeaders: headers
    });

    return req;
  }
}

@Injectable()
export class AppHttpClient {

  constructor(
    private http: HttpClient,
    private helpers: HelpersService,
    private authService: AuthService,
    private router: Router,
  ) {

  }

  /**
  * GET Request
  * @param url request url
  */
  get(url, queryParams?) {
    return this.http.get(url, { params: queryParams, ...this.getOptions() })
      .pipe(
        map(this.extractData),
        catchError(error => this.handleError(error))
      );
  }

  /**
  * PATCH Request
  * @param url request url
  * @param payload payload object
  * @param [contentType] optional content type header
  */
  patch(url, payload, contentType?: string) {
    let customOptions = null;

    if (contentType) {
      const headers = new HttpHeaders({
        'Content-Type': contentType
      });
      customOptions = this.setCustomOptions(headers);
    }

    return this.http.patch(url, payload, this.getOptions(customOptions))
      .pipe(
        map(this.extractData),
        catchError(error => this.handleError(error))
      );
  }

  /**
  * POST Request
  * @param url request url
  * @param payload payload object
  * @param [contentType] optional content type header
  */
  post(url, payload, contentType?: string) {
    let customOptions = null;

    if (contentType) {
      const headers = new HttpHeaders({
        'Content-Type': contentType
      });
      customOptions = this.setCustomOptions(headers);
    }

    return this.http.post(url, payload, this.getOptions(customOptions))
      .pipe(
        map(this.extractData),
        catchError(error => this.handleError(error))
      );
  }

  /**

  /**
  * FILES Request
  * @param url request url
  * @param payload payload object
  * @param [contentType] optional content type header
  */
  file(url, payload, auth = false) {
    const formData: FormData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201)
            resolve(JSON.parse(xhr.response));
          else
            reject(JSON.parse(xhr.response));
        }
      };

      xhr.open('POST', url, true);
      if (auth) xhr.setRequestHeader('Authorization', `Bearer ${this.authService.token}`);
      // xhr.setRequestHeader('Accept-Language', `${lang}`);
      xhr.send(formData);
    });
  }

  /**
  * PUT Request
  * @param url request url
  * @param payload payload object
  * @param [contentType] optional content type header
  */
  put(url, payload, contentType?: string) {
    let customOptions = null;

    if (contentType) {
      const headers = new HttpHeaders({
        'Content-Type': contentType
      });
      customOptions = this.setCustomOptions(headers);
    }

    return this.http.put(url, payload, this.getOptions(customOptions))
      .pipe(
        map(this.extractData),
        catchError(error => this.handleError(error))
      );
  }

  /**
  * DELETE Request
  * @param url request url
  * @param payload payload object
  */
  delete(url, payload = {}) {

    const options = {
      ... this.getOptions(),
      body: payload
    };

    if (!Object.keys(payload).length)
      delete options.body;

    return this.http.delete(url, options)
      .pipe(
        map(this.extractData),
        catchError(error => this.handleError(error))
      );
  }

  /**
   * Request options
   */
  private getOptions(customOptions?: any) {
    const options: any = {
      withCredentials: false
    };

    if (customOptions)
      options.headers = customOptions.headers || '';

    return options;
  }

  /**
  * Extract response from each request
  * @param res Endpoint response
  */
  private extractData(res: any) {
    let body;
    // validate if response is not json
    try {
      body = res.json() || {};
    } catch (error) {
      body = res;
    }
    return body;
  }

  /**
 * Handle error if it's present on request
 * @param error Response error
 */
  private handleError(error: Response | any, from?: any) {
    const message = this.errorMessage(this.getError(error), error.status);
    return throwError(message);
  }

  /**
   * Get error from body
   * @param error error description
   */
  private getError(error: any) {
    let body = error;

    try {
      body = isPlainObject(body.error) ? body.error :
      isPlainObject(body.error.error) ? body.error.error : body;
    } catch (e) {
      error = omit(error, ['headers', 'toString', 'json', 'blob', 'arrayBuffer', 'text']);
      body = error;
    }
    return body;
  }

  /**
   * Create an object with custom options
   * @param headers http headers
   */
  private setCustomOptions(headers?: HttpHeaders) {
    return {
      headers: headers || ''
    };
  }

  /**
 * Get message from HTTP error
 * or generate a custome one if status match an option
 * @param error Response Error
 * @param status Http code
 */
  private errorMessage(error: any, status?: any) {
    let message: any = {};

    switch (status) {
      case 404:
        message = {
          message: 'Not Found',
          ...error
        };
        break;
      case 401:
        message = {
          message: 'Not Authorized',
          ...error
        };
        localStorage.clear();
        this.authService.user = {};
        this.authService.token = '';
        this.router.navigate(['/auth/login']);
        break;

      default:
        message = error;
        break;
    }
    return message;
  }

}
