import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestsService } from '@api/api.gateway';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'stock-item-modal',
  templateUrl: './stock-item.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class StockItemModal extends AbstractModal {
  couriers = [];
  boxes = [];
  box_variants = [];

  helperForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiRequestsService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.getCouriers();
    this.getBoxes();
    this.subscriptions.push( this.helperForm.controls['product_model_id'].valueChanges.subscribe(value => {
      this.getBoxesVariant(value);
    }) );
  }

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      name: [ { value: data.name || '', disabled: false }, Validators.required],
      books_per_box: [ { value: data.books_per_box || 1, disabled: false }, Validators.required],
      description: [ { value: data.description || '', disabled: false  }, Validators.required],
      active: [ { value: data.active, disabled: false  }, Validators.required],
      // Disabled on update
      courier_id: [ { value: data.courier_id, disabled: this.properties.update }, Validators.required],
      product_id: [ { value: data.product_id, disabled: this.properties.update },],
    });

    this.helperForm =  this.formBuilder.group({
      product_model_id: [ { value: data.product_model_id, disabled: this.properties.update },],
    });
  }

  getName() {
    return 'Stock'
  }

  private getCouriers() {
    this.api.orders.deliveryCouriers({
      limit: 200
    }).toPromise()
      .then(res => {
        this.couriers = res['data'] || [];
      })
      .catch(e => console.log(e));
  }

  private getBoxes() {
    this.api.products.stories({
      type: 'gift-box',
      limit: 200,
    }).toPromise()
      .then(res => {
        this.boxes = res['data'] || [];
      })
      .catch(e => console.log(e));
  }

  private getBoxesVariant(id) {
    this.api.products.products({
      model_id: id,
      variant_type: 'gift-box',
      price: false,
      all: true,
      limit: 200,
    }).toPromise()
      .then(res => {
        this.box_variants = res['data'] || [];
      })
      .catch(e => console.log(e));
  }

}
