import { Component, Input } from '@angular/core';
import { CANARY_PROVINCES } from '@shared/global.types';

@Component({
  selector: 'app-country-flags',
  templateUrl: './countries-flags.component.html',
  styleUrls: ['./countries-flags.component.scss']
})
export class CountriesFlagsComponent {

  @Input() countryCode: String = 'ESP';
  @Input() province: String;
  isCanarias = false;

  /**
   * Update the component when the inputs change
   */
  ngOnChanges() {
    this.isCanarias = this.isProvinceCanarias();
  }

  isProvinceCanarias() {
    const province = this.province;
    return province && province && CANARY_PROVINCES.includes(province.toLowerCase());
  }
}
