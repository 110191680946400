import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { CoreRequests } from '@api/core/core.requests';

@Component({
  selector: 'app-search-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersModalComponent extends AbstractComponent {

  properties: any = {};

  users: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private api: CoreRequests
  ) {
    super();
  }

  ngOnInit() {
    this.listUsers();
  }

  confirm() {
    const activeUsers = this.users.map(user => user.active ? user.identity : false).filter(e => e);
    this.activeModal.close({ value: activeUsers.toString() });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  clean() {
    this.users.forEach(user => user.active = false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  chooseItem(status) {
    const index = this.users.findIndex(el => el['identity'] === status['identity']);
    if (index === -1)
      return;

    this.users[index]['active'] = !this.users[index]['active'];
  }

  private async listUsers() {
    try {
      const queryParams = {
        // sort: 'name',
        role: 'staff',
        limit: 200
      };
      const users = await this.api.users(queryParams).toPromise();
      this.users = users['data'] || [];

      this.constructSelected();
    } catch (error) {
      // TODO handle this errors
    }
  }

  private constructSelected() {
    const selections = !this.properties.value ? [] : this.properties.value.split(',')
      .map(e => e.trim())
      .filter(e => e);

    if (!selections.length)
      return;

    selections.forEach(identity => {
      const index = this.users.findIndex(i => i.identity.toLowerCase() === identity.toLowerCase());
      if (index !== -1)
        this.users[index]['active'] = true;
    });

  }
}
