// Declare all modals that will going to be used as shared filters

import { EditAffiliatesModal } from "./edit-affiliates/edit-affiliates.component";
import { SearchingAffiliateModal } from "./search-affiliate/search-affiliate.component";
import { SearchAffiliatesModal } from "./search-affiliates/search-affiliates.component";
import { WebsAffiliatesModal } from "./webs-affiliates/webs-affiliates.component";


// this declaration could be found in the shared app module
export function AffiliatesModals() {
  return [
    EditAffiliatesModal,
    SearchAffiliatesModal,
    SearchingAffiliateModal,
    WebsAffiliatesModal,
  ];
}
