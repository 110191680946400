import { Component } from '@angular/core';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-json-viewer-modal',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class JsonViewerModal extends AbstractModal {
  getName() {
    return 'JSON';
  }

  bootstrapForm(data: any = {}) {}
}
