import { Component, Injector } from '@angular/core';
import { HelpersService } from '@shared/services/helpers.service';

import { Router, ActivatedRoute, ResolveStart } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AsideService } from '@shared/services/aside.service';


export let AppInjector: Injector;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  private subscriptions: any = {};

  constructor(
    private helpers: HelpersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private asideService: AsideService,
    private injector: Injector
  ) {
    AppInjector = this.injector;
    this.listenRouteChange();
    this.helpers.bootstrapLang();
  }

  ngOnInit() {}

  /**
  * Listen all route events and get title from router
  */
  private listenRouteChange() {
    const listener = this.router.events
      .pipe(
        filter(event => event instanceof ResolveStart),
        map(event => {
          let data = null;
          let route = event['state'].root;

          while (route) {
            data = route.data || data;
            route = route.firstChild;
          }

          return data;
        }),
      );

    listener.subscribe((event: any) => {
      this.asideService.searchDisplay.next(event.displaySearch || false);
      this.asideService.hideReport.next(event.hideReport || false);
      this.asideService.displaySearchProduct.next(event.displaySearchProduct || false);
      this.asideService.searchOptions.next(event.searchOptions || []);
    });
  }
}
