import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { IncidentsRequests } from '@api/incidents/incidents.requests';
import { HelpersService } from '@shared/services';

@Component({
  selector: 'incident-types-modal',
  templateUrl: './incident-types.component.html',
  styleUrls: ['./incident-types.component.scss']
})
export class IncidentTypeModalComponent extends AbstractComponent {

  properties: any = {};
  selection: any = {};
  observation: any = '';
  provider_notified: any = false;
  client_notified: any = false;
  types: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private incidents: IncidentsRequests,
    private helpers: HelpersService
  ) {
    super();
  }

  async ngOnInit() {
    await this.listTypes();
    this.searchSelected();
  }

  parentSelection(selection) {

    this.types.forEach(e => {
      e.active = false;
    });

    selection.active = !selection.active;
    this.properties  = {
      ...selection
    };
  }

  childSelection(selection, parent) {

    parent.forEach(e => {
      e.active = false;
    });

    selection.active = !selection.active;

    this.selection['sub_type_id'] = selection.id;
    this.selection['sub_type_name'] = selection.name;
  }

  searchSelected() {
    const { reason_id } = this.properties;
    if (reason_id) {
      for (const type of this.types) {
        if (type.reasons) {
          const reason = type.reasons.find((reason) => reason.id == reason_id);
          if (reason) {
            this.childSelection(reason, type.reasons);
            this.parentSelection(type);
            break;
          }
        }
      }
    }
  }

  clean() {
    this.types.forEach(e => {
      e.active = false;
    });
    this.properties = {};
    this.selection = {};
  }

  confirm() {
    this.activeModal.close({
      ...this.properties,
      ...this.selection,
      observation: this.observation,
      provider_notified: this.provider_notified,
      client_notified: this.client_notified,
    });
  }

  close() {
    this.clean();
    this.activeModal.close(false);
  }

  dismiss() {
    this.clean();
    this.activeModal.dismiss();
  }

  private async listTypes() {
    const params = {
      sort: 'name',
      page: 1,
      limit: 100
    };
    try {
      const types = await this.incidents.listTypes(params).toPromise();
      this.types = types['data'] || [];
    } catch (error) {
      this.helpers.errorMessage(error);
    }
  }

}
