import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

export function BootstrapModules() {
  return [
    NgbModule,
    NgbModalModule,
    NgbAlertModule,
    NgbCollapseModule
  ];
}
