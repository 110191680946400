import { Component } from '@angular/core';
import { OrdersRequests } from '@api/orders/orders.requests';
import { AbstractModal } from '../../abstract-modal.component';

const PACK_COUNT = 1;

@Component({
  selector: 'app-pack-create-modal',
  templateUrl: './pack-create.component.html',
  styleUrls: ['../../general-modal.component.scss', './pack-create.component.scss']
})
export class PackCreateModal extends AbstractModal {
  private data;
  totalPacks;
  processedPacks = 0;
  errorResponses = [];

  getName() { return 'Packs'; }

  constructor(
    private api: OrdersRequests,
  ) {
    super();
  }

  get progress() {
    return `${this.processedPacks / this.totalPacks * 100}%`;
  }

  ngOnInit(): void {
    this.data = this.properties.data;
    this.totalPacks = this.data.orders.length;
    this.createPacks();
  }

  finish() {
    location.reload();
  }

  private async createPacks() {
    for (let slices = 0; slices < Math.ceil(this.data.orders.length / PACK_COUNT); slices++ ) {
      const orders = this.data.orders.slice(slices * PACK_COUNT, (slices + 1) * PACK_COUNT);
      const responses = await this.api.createPack( { ...this.data, orders }).toPromise();
      for (const response of responses.data) {
        if (response.error) {
          this.errorResponses.unshift(response);
        }
        this.processedPacks += 1;
      }
    }
  }

  bootstrapForm(data: any = {}) {}
}
