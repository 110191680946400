import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-webs-affiliates-modal',
  templateUrl: './webs-affiliates.component.html',
  styleUrls: ['./webs-affiliates.component.scss']
})
export class WebsAffiliatesModal extends AbstractModal {

  domains: any[] = [];
  languages: any[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
    this.domains = [
      {
        name: 'similarweb.com',
        code: 'similarweb.com'
      },
      {
        name: 'micuento.com',
        code: 'micuento.com'
      },
      {
        name: 'google.com.mx',
        code: 'google.com.mx'
      },
      {
        name: 'google.es',
        code: 'google.es'
      },
      {
        name: 'google.com.co',
        code: 'google.com.co'
      },
      {
        name: 'google.cl',
        code: 'google.cl'
      },
      {
        name: 'google.fr',
        code: 'google.fr'
      },
      {
        name: 'google.it',
        code: 'google.it'
      },
      {
        name: 'google.com',
        code: 'google.com'
      },
    ]
    this.languages = [
      {
        name: 'ES',
        code: 'es'
      },
      {
        name: 'FR',
        code: 'fr'
      },
      {
        name: 'IT',
        code: 'it'
      }
    ]

  }


  ngAfterViewInit() {}

  bootstrapForm(data: any = {}) {
    this.form = this.formBuilder.group({
      domain: [ { value: data.domain || ''}, Validators.required],
      language: [ { value: data.language || ''}, Validators.required],
      keyword: [ { value: 'micuento', disabled: false }],
      urls: [ { value: data.urls || '', disabled: false }, Validators.required],
    });
  }

  getName() {
    return 'Páginas';
  }

}
