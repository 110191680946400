import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Shared
import { SharedModule } from '@shared/shared.module';
import { HttpModules, FormModules } from '@shared/shared-init-modules';
import { GlobalComponents } from '@shared/components';
import { GlobalServices } from '@shared/services';

// HTTP Api
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor, AppHttpClient } from '@api/api.interceptor';
import { ApiRequestsService, ApiDeclarations } from '@api/api.gateway';
import { HttpClient } from '@angular/common/http';

// Guards
import { AppGuards } from '@guards/index.declarations';

// external
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarkdownModule } from 'ngx-markdown';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const ToastrOptions: any = {
  timeOut: 5000,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  closeButton: true,
  autoDismiss: true,
  newestOnTop: true
};


@NgModule({
  declarations: [
    AppComponent,
    GlobalComponents(),
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModules(),
    FormModules(),
    SharedModule,
    ToastrModule.forRoot(ToastrOptions),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot({ loader: HttpClient }),
  ],
  providers: [
    AppGuards(),
    GlobalServices(),
    AppHttpClient,
    ApiDeclarations(),
    ApiRequestsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
