import { Directive, EventEmitter, ElementRef, HostListener, Output, Input } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[ngDrag]'
})
export class DragDirective {
  @Input() sortable = false;
  dragging = false;
  draggingTimer = 0;
  @Output() select = new EventEmitter<any>();
  initialX = 0;
  initialY = 0;
  mouseMove$: any;
  mouseMoveSub: Subscription;

  constructor(
    public element: ElementRef,
  ) {}

  ngOnInit() {
    if (this.sortable) {
      this.element.nativeElement.classList.add('draggable');
      this.mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    this.draggingTimer = Date.now();
    if (this.sortable) {
      this.dragging = true;
      this.initialX = event.clientX;
      this.initialY = event.clientY;
      this.element.nativeElement.classList.add('dragging');
      this.mouseMoveSub = this.mouseMove$.subscribe( ev => this.onMouseMove(ev) );
    }
  }

  onMouseMove(event) {
    if (this.sortable) {
      if (this.dragging) {
        const currentX = event.clientX - this.initialX;
        const currentY = event.clientY - this.initialY;
        this.element.nativeElement.style.transform = 'translate3d(' + currentX + 'px, ' + currentY + 'px, 0)';
        window.getSelection().removeAllRanges();
      } else {
        console.log('ERROR');
      }
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event?) {
    if (Date.now() - this.draggingTimer < 200) this.select.emit(event);
    if (this.sortable) {
      if (this.mouseMoveSub) this.mouseMoveSub.unsubscribe();
      this.dragging = false;
      this.element.nativeElement.style.transform = 'translate3d(0, 0, 0)';
      this.element.nativeElement.classList.remove('dragging');
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event?) {
    if (this.dragging) {
      this.onMouseUp();
    }
  }
}
