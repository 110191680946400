import { Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';

@Component({
  selector: 'app-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrls: ['./html-viewer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HtmlViewerComponent extends AbstractComponent {
  @Input() type = 'body';
  @Input() printer = 'schaetzl';
  @Input() currentPage: string;
  page: SafeHtml;

  constructor(
    private _sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPage) this.page = this._sanitizer.bypassSecurityTrustHtml(this.currentPage);
  }
}
