export * from './audio-book-name/audio-book-name.component';
export * from './edit-base-prices/edit-base-prices.component';
export * from './add-box/add-box.component';
export * from './add-upsell/add-upsell.component';
export * from './extras/extras.component';
export * from './product-extras/product-extras.component';
export * from './product-add-extras/product-add-extras.component';
export * from './product-availability/product-availability.component';
export * from './product-group/product-group.component';

// main declarations
export * from './index.declarations';
